/* eslint-disable */
import config from '../../config/britecore'
import settings from '../../config/settings'
import axios from 'axios'
import moment from 'moment'

export function isFailed(response) {
  if (response.data.errors) {
    console.warn('response.data.errors is has at least one error', response.data)
    return true
  }

  if (!response.data.success) {
    console.warn('response.data.success is false', response.data)
    return true
  }

  return false;
}

export function getCauseData (response) {
  let cause = response.data.cause
  if (cause) {
    if (typeof cause === 'string') {
      return {
        key: cause,
        params: null
      }
    } else return cause
  } else return {
    key: null,
    params: null
  }
}

export function validateNumberKey (e) {
  // borrowed and adapted from http://stackoverflow.com/a/2403024
  let key = e.charCode || e.keyCode || 0
  // allow backspace, tab, delete, enter, arrows, numbers and keypad numbers ONLY
  // home, end, period, and numpad decimal
  if (!(
    key === 8 ||
    key === 9 ||
    key === 13 ||
    key === 46 ||
    key === 110 ||
    key === 190 ||
    (key >= 35 && key <= 40) ||
    (key >= 48 && key <= 57) ||
    (key >= 96 && key <= 105))) {
    e.preventDefault()
  }
}

export function generateUrlManually(subUrl, isMobile) {
  if (subUrl.charAt(0) !== '/') {
    subUrl = '/' + subUrl
  }
  var wlp = window.location.protocol
  var wlh = window.location.host
  var wlpa = window.location.pathname
  var newUrl = wlp + '//' + wlh + wlpa + (isMobile ? '#!/' : '#') + subUrl
  return newUrl
}

export function redirectToRootByBrowserApi() {
  var wlp = window.location.protocol
  var wlh = window.location.host
  var wlpa = window.location.pathname
  var newUrl = wlp + '//' + wlh + wlpa
  window.location.href = newUrl
}

export function redirectToUrlByBrowserApiWeb(subUrl, replace, reload) {
  return redirectToUrlByBrowserApi(subUrl, replace, reload, false)
}
export function redirectToUrlByBrowserApiMobile(subUrl, replace, reload) {
  return redirectToUrlByBrowserApi(subUrl, replace, reload, true)
}



export function redirectToUrlByBrowserApi(subUrl, replace, reload, isMobile) {
  var newUrl = generateUrlManually(subUrl, isMobile)

  if (replace === true) {
    window.location.replace(newUrl)
  } else {
    window.location.href = newUrl
  }

  if (reload === true) {
    window.location.reload(true)
  }
}

export function urlForAttachment (fileId, resized = false) {
  let url = config.url + '/get_attachment_all_v2/?file_id=' + fileId +
    '&company_id=' + settings.company_id + '&token=' + localStorage.token +
    '&resized=' + resized

  return url
}

export function logInsuredAction (message, message_code, extra_vars) {
  let messageTimestamp = moment().format('YYYY-MM-DTHH:mm:ss')
  axios.post(config.log_insured_action, {
    message: message,
    message_code: message_code,
    extra_vars: extra_vars,
    message_timestamp: messageTimestamp,
    token:localStorage.token
  })
  .catch((err) => {
    console.log('Error occurred during sending action log', err)
  })
}

export function install (Vue) {
  Vue.prototype.logInsuredAction = logInsuredAction
}

export function setCookie(cname, cvalue, expirationSec) {
    let d = new Date();
    d.setTime(d.getTime() + (expirationSec*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function deleteCookie(cname) {
  document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export default install

