<template>
  <div v-loading="loading">
    <bc-navbar :title="t('policies.header_policy_number', {policy_number: policyNumber })" class="u-mt2 u-mb4" backLink />
    <div class="u-flex-horizontal-space-between u-mb3">
      <div>
        <el-button type="primary" @click="chooseFile()" class="u-mr3" :disabled="policy.is_canceled" v-if="!setting('policies.read_only_documents_section')">
          {{ t('documents.documents_popover_menu_upload_file') }}
        </el-button>
        <el-button type="info" @click="createFolder()" v-if="backLink !== null && backLink.type === 'insured_upload_folder' && !setting('policies.read_only_documents_section')" class="u-mr3">
          {{ t('documents.create_folder') }}
        </el-button>
      </div>
      <!--Sort option for items-->
      <el-dropdown @command="changeSorting">
        <span  :title="t('documents.documents_sorting_menu_title')">
          {{ sortData.sortTitle }}
          <fa-icon :icon="['far', 'arrow-left']" :class="sortData.ascending ? `fa-rotate-90` : `fa-rotate-270`" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in sortFieldsData" :key="item.sortTitle"
                            :command="{sortByField: item.sortByField, ascending: item.ascending, sortTitle: item.sortTitle}">
              {{ item.sortTitle }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!--End Sort option for items-->
    </div>
    <!--Current folder place-->
    <div class="folders-links u-mb3" v-if="folderName && folderName.length > 1" @click="$router.back()" >
      <div class="media">
        <i class="material-icons u-mr2">arrow_back</i>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item v-for="el in folderName" :key="el.title">
           {{ el.title }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!--End Current folder place-->
    <!--List of folders and files-->
    <div v-if="documentsFileList">
      <div v-for="document in sorted_records" :key="document.id" class="item-input-field">
          <div class="media folders-list">
            <!--Folders list-->
            <div class="media__image" @click="clickOnFile(document)">
              <fa-icon v-if="document.isdir" :icon="['far', 'folder']" class="folders-list__icon"/>
              <fa-icon v-else :icon="['far', 'file']" class="folders-list__icon"/>
            </div>
            <div v-if="document.isdir" @click="clickOnFile(document)" class="media__body media__body--trim-text">
                {{document.title}}
            </div>
            <!--End folders list-->
            <!--Dropdown options Download/Open/Move/Delete-->
            <div v-else class="media__body media__body--trim-text">
              <el-dropdown trigger="click" @command="onFileCommand" class="u-width100">
              <span class="el-dropdown-link u-flex-horizontal-space-between">
                <div class="u-pr2">{{document.title}}</div>
                <div>
                  <el-button type="info" class="u-pt1 u-pb1 u-pl3 u-pr3 el-button--transparent">
                   <fa-icon :icon="['far', 'ellipsis-v']" class="fa-rotate-90"/>
                  </el-button>
                </div>
              </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="{command:'download', file:document}">
                    {{ t('documents.documents_popover_menu_download') }}
                  </el-dropdown-item>
                  <el-dropdown-item v-if="document.mimeType === 'application/pdf'"
                                    :command="{command:'open', file:document}">
                    {{ t('documents.documents_popover_menu_open') }}
                  </el-dropdown-item>
                  <el-dropdown-item v-if="documentsFileList.insured_upload_folder && !setting('policies.read_only_documents_section')"
                                    :command="{command:'move', file:document}">
                      {{ t('documents.documents_popover_menu_move') }}
                  </el-dropdown-item>
                  <el-dropdown-item v-if="documentsFileList.insured_upload_folder && !setting('policies.read_only_documents_section')"
                                    :command="{command:'delete', file:document}">
                    {{ t('documents.documents_popover_menu_delete') }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="u-mr2" v-if="document.isdir && backLink !== null && documentsFileList.insured_upload_folder">
              <el-button @click.stop="renameFolder(document)"
                         :icon="['fas', 'pen']"
                         type="info"
                         class="u-pt1 u-pb1 u-pl2 u-pr2 el-button--transparent">
              </el-button>
            </div>
            <div v-if="document.isdir && backLink !== null && documentsFileList.insured_upload_folder">
              <el-button @click.stop="removeFolder(document)"
                         :icon="['fas', 'trash']"
                         type="info"
                         class="u-pt1 u-pb1 u-pl2 u-pr2 el-button--transparent">
              </el-button>
            </div>
          </div>
      </div>
    </div>
    <!--End List of folders and files-->

    <!--Modal window for moving files-->
    <el-dialog v-if="foldersNavigationFileList" :title="t('documents.select_destination_folder')"
               :visible.sync="dialogTableVisible" class="modal-move" >
        <div v-if="foldersNavigationFileList && foldersNavigationFileList.folders.length" class="modal-move__header">
            <el-button @click="moveFile">
               {{ t('documents.move_to_folder', { folder: foldersNavigationFileList.folderName })}}
            </el-button>
        </div>
        <!--List of folders for choosing-->
        <div class="modal-move__body">
            <div v-if="foldersNavigationFileList">
                <div v-if="foldersNavigationFileList.folders.length"
                     @click="loadPickerData(folderNavigationBackLink.id)">
                    <div class="media">
                        <div class="media__image">
                          <el-popover
                            placement="top-start"
                            title=""
                            width="150"
                            trigger="hover"
                            :content="t('documents.back')">
                           <el-button slot="reference" size="mini" class="popover-btn u-no-border el-button--transparent u-p0">
                             <i class="material-icons">reply</i>
                           </el-button>
                          </el-popover>
                        </div>
                        <div class="media__body media__body--trim-text">
                          {{ foldersNavigationFileList.folderName }}
                        </div>
                    </div>
                </div>
                <div v-if="document.isdir && !document.readOnly && foldersNavigationFileList" :key="document.id"
                     v-for="document in foldersNavigationFileList.records" @click="loadPickerData(document.id)">
                    <div v-if="foldersNavigationFileList.folders.length" class="media">
                      <i class="material-icons u-pr3">folder</i>
                      <div class="media__body media__body--trim-text">
                         {{ document.title }}
                      </div>
                    </div>
                    <div v-else class="media">
                      <i class="material-icons u-pr3">reply</i>
                      <div class="media__body media__body--trim-text">
                        {{ t('documents.you_can_not_move_file_here') }}
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End List of folders for choosing-->
    </el-dialog>
    <!--End Modal window for moving files-->
    <input type="file" id="uploadFileInput" :accept="availableFileTypes" style="visibility:hidden" @change="onFileSelected"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {b64toBlob} from '@shared_src/utils/misc.util'

export default {
  name: 'bc-documents-list',
  mounted () {
    if (this.policy) {
      this.showDocumentsData()
    }
  },
  data () {
    return {
      policyId: this.$route.params.policyId,
      sortData: {
        sortByField: 'date_added',
        ascending: false,
        sortTitle: this.t('documents.documents_sorting_date_desc')
      },
      currentFolderId: null,
      currentNavigationFolderId: null,
      loading: false,
      dialogTableVisible: false,
      fileSelectedToMove: ''
    }
  },
  computed: {
    ...mapGetters('documents', ['documentsFileList', 'foldersNavigationFileList']),
    ...mapGetters('policies', ['policyById']),
    sortFieldsData () {
      return [
        {sortByField: 'date_added', ascending: false, sortTitle: this.t('documents.documents_sorting_date_desc')},
        {sortByField: 'date_added', ascending: true, sortTitle: this.t('documents.documents_sorting_date_asc')},
        {sortByField: 'title', ascending: true, sortTitle: this.t('documents.documents_sorting_title_asc')},
        {sortByField: 'title', ascending: false, sortTitle: this.t('documents.documents_sorting_title_desc')}
      ]
    },
    availableFileTypes () {
      return this.setting('global.allowed_files_to_upload')
    },
    policy () {
      return this.policyById(this.policyId)
    },
    folderName () {
      let docTitle
      let folderRoute
      if (this.documentsFileList) {
        folderRoute = this.documentsFileList.folders.map(el => { return { title: el.title } })
        docTitle = [{ title: this.t('documents.documents_label') }, ...folderRoute]
      }
      return this.documentsFileList ? docTitle || ('Policy ' + this.policy.policy_number) : null
    },
    backLink () {
      return this.computeBackLink(this.documentsFileList)
    },
    folderNavigationBackLink () {
      return this.computeBackLink(this.foldersNavigationFileList)
    },
    sorted_records () {
      let dirs = this.documentsFileList.records.filter((rec) => { return rec.isdir })
      let docs = this.documentsFileList.records.filter((rec) => { return !rec.isdir })
      return dirs.concat(docs)
    },
    policyNumber () {
      return this.policy ? this.policy.policy_number : ''
    }
  },
  methods: {
    computeBackLink (docList) {
      if (!docList) {
        return null
      }
      if (docList.folders.length) {
        let parentFolderObj = docList.folders[docList.folders.length-1]
        let parentFolderId = parentFolderObj.folder_id
        let parentFolderName =
          docList.folders.length === 1 ? 'Root Folder': docList.folders[docList.folders.length-2].title
        return {
          title: parentFolderName,
          id: 'policy_' + this.policy.id + (parentFolderId ? '_' + parentFolderId : ''),
          isdir: true,
          type: docList.type
        }
      } else {
        return null
      }
    },
    changeSorting (param) {
      const {sortByField, ascending, sortTitle} = param
      if (this.sortData.sortByField === sortByField && this.sortData.ascending === ascending) {
        return
      }
      this.sortData.sortByField = sortByField
      this.sortData.ascending = ascending
      this.sortData.sortTitle = sortTitle
      this.loadDirectoryData(this.currentFolderId)
    },
    onFileCommand (param) {
      if (param.command === 'download') {
        this.downloadFile(param.file)
      } else if (param.command === 'open') {
        this.openFile(param.file)
      } else if (param.command === 'move') {
        this.openModalMoveFile(param.file)
      } else if (param.command === 'delete') {
        this.removeFile(param.file)
      }
    },
    chooseFile () {
      document.getElementById('uploadFileInput').click()
    },
    onFileSelected () {
      let filesVar = document.getElementById('uploadFileInput').files
      if (filesVar.length === 0) {
        return
      }
      let fileToUpload = filesVar[0]
      this.loading = true
      let reader = new FileReader()
      let uploadFolderId
      // File will be uploaded from any folder to the 'Your Uploads' folder
      if (this.documentsFileList.type && this.documentsFileList.type === 'insured_upload_folder') {
        uploadFolderId = this.currentFolderId
      } else {
        uploadFolderId = 'policy_' + this.policy.id
      }
      reader.onload = (theFile) => {
        let fileData = theFile.target.result
        this.$store.dispatch(
          'documents/uploadUserAttachment',
          [fileToUpload.name, btoa(fileData), fileToUpload.type, uploadFolderId, this.policyNumber])
        .then(
          (response) => {
            document.getElementById('uploadFileInput').value = '' // reset input value - otherwise if we try to upload same file - we will have weird app behavior
            this.loading = false
            let uploadedFolderId = 'policy_' + this.policy.id + '_' + response.data.folder_id

            let dismissAction = () => {
              if (this.currentFolderId !== uploadedFolderId) {
                this.$elAlert(
                  this.t('documents.document_navigate_folder_alert_message'),
                  this.t('documents.document_navigate_folder_alert_title'),
                  () => { this.loadDirectoryData(uploadedFolderId) }
                )
              } else {
                this.loadDirectoryData(uploadedFolderId)
              }
            }
            this.$elAlert(
              this.t('documents.document_upload_complete_alert_message', {filename: fileToUpload.name}),
              this.t('documents.document_upload_complete_alert_title'),
              dismissAction
            )
          }
        ).catch(() => {
          this.loading = false
          this.$elAlert(this.t('documents.failed_to_upload_file') + ' ' + fileToUpload.name,
            this.t('documents.document_download_failed_alert_title'))
        })
      }
      reader.readAsBinaryString(fileToUpload)
    },
    clickOnFile (file) {
      if (file.isdir) {
        this.$router.push({
          params: {
            policyId: this.policyId,
            folderId: file.id
          }
        })
        this.loadDirectoryData(file.id)
      } else {
        console.warn('This shouldn\'t happen!')
      }
    },
    normalizeFileName (fileTitle, contenType) {
      // process only PDF files
      if (contenType !== 'application/pdf') return fileTitle
      let lcTitle = fileTitle.toLowerCase()
      let pdfExtension = '.pdf'
      // no changes if file ends with pdf extension
      if (lcTitle.endsWith(pdfExtension)) return fileTitle
      return `${fileTitle}${pdfExtension}`
    },
    downloadFile (file) {
      this.logInsuredAction(
        'Clicked "Download File in documents section"',
        'custom_download_file_documents',
        {folderName: file.folderName, fileName: file.title, fileId: file.id})
      this.loading = true
      this.$store.dispatch('documents/getAttachmentFileAesGcm', [file.id, false])
        .then(
        (data) => {
          let blob = b64toBlob(data.data, data.contentType)
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, file.title)
          } else {
            let elem = window.document.createElement('a')
            elem.href = window.URL.createObjectURL(blob)
            elem.onclick = function (event) {
              event.stopPropagation()
            }
            elem.download = this.normalizeFileName(file.title, data.contentType)
            document.body.appendChild(elem)
            elem.click()
            document.body.removeChild(elem)
            this.loading = false
          }
        },
        () => {
          this.loading = false
          this.$elAlert(
            this.t('documents.document_download_failed_alert_message'),
            this.t('documents.document_download_failed_alert_title')
          )
          console.log('Failure during data download')
        }
      )
    },
    removeFile (file) {
      this.$store.dispatch('documents/removeUserAttachments', [file.id])
        .then(() => {
          this.loadDirectoryData(this.currentFolderId)
        })
    },
    removeFolder (folder) {
      this.$elConfirm(this.t('documents.this_will_also_delete_all_content'), this.t('documents.delete_folder'), {
        type: 'warning',
        okCallback: () => {
          this.$store.dispatch('documents/removeUserAttachments', [folder.id.split('_')[2]]).then(() => {
            this.loadDirectoryData(this.currentFolderId)
          })
        }
      })
    },
    renameFolder (folder) {
      this.$elPrompt(this.t('documents.new_folder_name'), this.t('documents.documents_label'), {
        inputPattern: /\w+/,
        defaultText: folder.title,
        inputErrorMessage: 'Invalid folder name',
        okCallback: (value) => {
          this.$store.dispatch('documents/renameFolder', [folder.id.split('_')[2], value.value]).then(() => {
            this.loadDirectoryData(this.currentFolderId)
          }, reason => {
            console.log('Error ' + reason)
          })
        }
      })
    },
    openFile (file) {
      if (file.mimeType === 'application/pdf') {
        this.$router.push({
          path: '/document-view/' + file.id,
          query: {
            fileName: file.title,
            folderName: file.folderName,
            policyId: this.policy.id,
            folders: this.folderName
          }
        })
      } else {
        console.warn('Cannot open', file)
      }
    },
    async loadDirectoryData (folderId) {
      try {
        this.loading = true
        await this.$store.dispatch('documents/loadDocuments',
          [folderId, 1, this.sortData.sortByField, this.sortData.ascending, false]
        )
        this.currentFolderId = folderId
        return this.currentFolderId
      } catch (e) {
        console.log('error: ' + e)
      } finally {
        this.loading = false
      }
    },
    loadPickerData (folderId) {
      const makeUpdate = async () => {
        folderId = folderId.startsWith('policy_') ? folderId : 'policy_' + this.policy.id + '_' + folderId
        try {
          await this.$store.dispatch('documents/loadDocuments',
            [folderId, 1, this.sortData.sortByField, this.sortData.ascending, true]
          )
        } catch (err) {
          console.log('error: ' + err)
        }
        this.currentNavigationFolderId = folderId
        return this.currentNavigationFolderId
      }
      return makeUpdate()
    },
    openModalMoveFile (file) {
      this.dialogTableVisible = true
      this.fileSelectedToMove = file.id
      this.loadPickerData(file.folder_id)
    },
    moveFile () {
      const moveFileToFolder = async () => {
        try {
          await this.$store.dispatch('documents/moveFileToFolder',
            [this.fileSelectedToMove, this.currentNavigationFolderId])
          this.loadDirectoryData(this.currentFolderId)
        } catch (err) {
          this.dialogTableVisible = false
          console.log('error: ' + err)
        } finally {
          this.dialogTableVisible = false
          this.fileSelectedToMove = ''
        }
      }
      return moveFileToFolder()
    },
    showDocumentsData () {
      this.logInsuredAction('Switched to Documents Tab', 'custom_document_tab_open', {policy_id: this.policy.id})
      let folderId = ''
      if (this.$route.params.folderId !== 'root') {
        folderId = this.$route.params.folderId
      } else if (this.$route.params.folderId === 'root' && !this.currentFolderId) {
        folderId = ('policy_' + this.policy.id + '_root')
      }
      this.loadDirectoryData(folderId)
    },
    createFolder () {
      this.$elPrompt(this.t('documents.input_folder_name'), this.t('documents.documents_label'), {
        inputPattern: /\w+/,
        inputErrorMessage: 'Invalid folder name',
        okCallback: (value) => {
          this.$store.dispatch('documents/createFolder', [this.currentFolderId, value.value])
          .then(() => {
            this.loadDirectoryData(this.currentFolderId)
          }, reason => {
            console.log('Error ' + reason)
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
  @import '../../sass/_variables.scss';

  // Gets document links centered up.
  .item-inner {
      > .media {
          height: 100% !important;
      }
  }

  .no-wrap {
    flex-wrap: nowrap;
  }

  .el-dropdown {
    cursor: pointer;
    white-space: nowrap;
  }

  // Inner router for folders

  .folders-links {
    color: #606266;
    cursor: pointer;
    transition: opacity .5s ease;
    &:hover {
      opacity: .6;
      transition: opacity .5s ease;
    }
    .el-breadcrumb__inner {
      cursor: pointer !important;
    }
  }
  .folders-list {
    cursor: pointer;
    padding: 10px;
    border: 2px solid $color-border;
    box-sizing: border-box;
    border-radius: 4px;
    &__icon {
      font-size: 25px;
      color: $icon-color;
    }
  }

  // Modal Window for Moving Files
  .modal-move {
    display: flex;
    flex-direction: column;
    &__body {
      margin-top: 15px;
      padding: 8px 20px;
      border: 1px solid #dcdfe6;
      
      .popover-btn {
        color: #606266 !important;
        &:hover {
          opacity: .8;
          color: #606266 !important;
        }
      }
    }
  }
  .el-dialog__title {
    font-size: 24px;
    font-weight: 600;
  }
  .media {
    cursor: pointer;
  }

</style>
