<template>
  <div>
    <div v-if="loading" class="u-mt4 u-mb0 u-text-center" v-loading="true"><h2>{{t('global.loading')}}</h2></div>
    <div v-else>
      <!--non-grouped vehicles-->
      <div class="u-mt4 u-mb0" v-if="!groupVehiclesByType">
        <h2 class="secondary-header">{{ t('auto.vehicle_details') }} ({{ vehiclesPreparedData.length }})</h2>

        <el-row :gutter="30">
          <el-col v-for="(vehicleData, index) in vehiclesPreparedData" :key="index" :xs="24" :span="itemsPerRow('vehicle') * 2">
            <el-card shadow="never">
              <div class="u-mb2">
                <ba-risk-icon class="u-mr2 u-color-secondary"
                              :risk-component="{type:'vehicle'}"/>
                {{vehicleData.name}}
              </div>
              <ba-risk-repeated-fields
                  v-if="vehicleData.fields"
                  :fields="vehicleData.fields"
                  :riskType="vehicleData.dataType"/>
            </el-card>
          </el-col>
        </el-row>
      </div>

      <!--grouped vehicles-->
      <div v-if="groupVehiclesByType">
        <div class="u-mt4 u-mb0" v-for="group, groupIndex in groupedVehiclesPreparedData" :key="groupIndex">
          <h2 class="secondary-header">{{ group.label }} ({{ group.items.length }})</h2>

          <el-row :gutter="30">
            <el-col v-for="(vehicleData, index) in group.items" :key="index" :xs="24" :span="itemsPerRow('vehicle') * 2">
              <el-card shadow="never">
                <div class="u-mb2">
                  <ba-risk-icon class="u-mr2 u-color-secondary"
                                :risk-component="{type:'vehicle'}"/>
                  {{vehicleData.name}}
                </div>
                <ba-risk-repeated-fields
                    v-if="vehicleData.fields"
                    :fields="vehicleData.fields"
                    :riskType="vehicleData.dataType"/>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>

      <!--drivers-->
      <div class="u-mt4 u-mb0">
        <h2 class="secondary-header">{{ t('auto.drivers') }} ({{ driversPreparedData.length }})</h2>

        <el-row :gutter="30">
          <el-col v-for="(driverData, index) in driversPreparedData" :key="index" :xs="24" :span="itemsPerRow('driver') * 2">
            <el-card shadow="never">
              <div class="u-mb2">
                <ba-risk-icon class="u-mr2 u-color-secondary"
                              :risk-component="{type:'driver'}"/>
                {{driverData.name}}
              </div>
              <ba-risk-repeated-fields
                  v-if="driverData.fields"
                  :fields="driverData.fields"
                  :riskType="driverData.dataType"/>
            </el-card>
          </el-col>
        </el-row>
      </div>

      <!--non-drivers-->
      <div class="u-mt4 u-mb0" v-if="nonDriversPreparedData.length">
        <h2 class="secondary-header">{{ t('auto.non_drivers') }} ({{ nonDriversPreparedData.length }})</h2>

        <el-row :gutter="30">
          <el-col v-for="(nonDriverData, index) in nonDriversPreparedData" :key="index" :xs="24" :span="itemsPerRow('driver') * 2">
            <el-card shadow="never">
              <div class="u-mb2" >
                <ba-risk-icon class="u-mr2 u-color-secondary"
                              :risk-component="{type:'driver'}"/>
                {{nonDriverData.name}}
              </div>
              <ba-risk-repeated-fields
                  v-if="nonDriverData.fields"
                  :fields="nonDriverData.fields"
                  :riskType="nonDriverData.dataType"/>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import BaRiskRepeatedFields from './dynamic/repeated-fields.component'
  import BaRiskIcon from '@shared_src/policies/risk-icon.component'
  import {formCleanAddress} from '@shared_src/utils/misc.util'

  export default {
    name: 'RiskDetails',
    components: { BaRiskIcon, BaRiskRepeatedFields },
    props: ['policyId'],
    data () {
      return {
        drivers: [],
        vehicles: [],
        loading: false
      }
    },
    watch: {
      revisionId: {
        async handler (value) {
          if (!this.revisionId) return
          this.loadRisks()
        },
        immediate: true
      },
    },
    computed: {
      ...mapGetters('policies', ['policyById']),
      groupVehiclesByType () {
        return this.setting('policies.group_vehicles_by_type')
      },
      policy () {
        return this.policyById(this.policyId)
      },
      revisionId () {
        if (!this.policy) return
        return this.policy.revision.id
      },
      driversPreparedData () {
        if (!this.drivers) return []
        let res = []
        this.drivers.forEach(driver => {
          if (!driver.included_in_policy) return
          let data = {
            name: driver.full_name,
            dataType: 'driver',
            fields: [
              {
                label: 'auto.license_number',
                show_as: 'primitive',
                value: driver.license_number
              }
            ],
          }
          res.push(data)
        })
        return res
      },
      nonDriversPreparedData () {
        if (!this.drivers) return []
        let res = []
        this.drivers.forEach(driver => {
          if (driver.included_in_policy) return
          let data = {
            name: driver.full_name,
            dataType: 'driver',
          }
          if (driver.date_of_birth) {
            data.fields = [
              {
                label: 'enrollment.date_of_birth',
                show_as: 'primitive',
                value: driver.date_of_birth
              }
            ]
          }
          res.push(data)
        })
        return res
      },
      driversDict () {
        if (!this.drivers) return
        let res = {}
        this.drivers.forEach(driver => { res[driver.id] = driver })
        return res
      },
      groupedVehiclesPreparedData () {
        if (!this.vehicles) return []
        let vehicleTypes = {}
        this.vehicles.forEach(vehicle => {
          let _type = vehicle.vehicle_type || this.t('auto.vehicle_details')
          if (!vehicleTypes[_type]) vehicleTypes[_type] = []
          vehicleTypes[_type].push(this.makePreparedVehicle(vehicle))
        })

        // sort keys
        let sortedKeys = Object.keys(vehicleTypes).sort()
        // move 'Private Passenger' at top if exists
        let pP = 'Private Passenger'
        let indexPP = sortedKeys.indexOf(pP)
        if (indexPP) {
          sortedKeys.splice(indexPP, 1)
          sortedKeys.splice(0, 0, pP)
        }
        // compile result array
        let result = []
        sortedKeys.forEach((key) => {
          result.push({
            label: key,
            items: vehicleTypes[key]
          })
        })

        return result
      },
      vehiclesPreparedData () {
        if (!this.vehicles) return []
        let res = []
        this.vehicles.forEach(vehicle => {
          res.push(this.makePreparedVehicle(vehicle))
        })
        return res
      }
    },
    methods: {
      ...mapActions('policies', ['getDriversGen2AutoWithCache', 'getVehiclesGen2AutoWithCache']),
      makePreparedVehicle (vehicle) {
        let addr = formCleanAddress(vehicle) || 'N/A'
        let data = {
          name: `${vehicle.vehicle_year} ${vehicle.vehicle_make} ${vehicle.vehicle_model}`,
          dataType: 'vehicle',
          fields: [
            {
              label: 'auto.VIN',
              value: vehicle.vehicle_vin,
              show_as: 'primitive'
            },
            {
              label: 'auto.garaging_location',
              value: addr,
              show_as: 'primitive'
            }
          ]
        }
        if (this.driversDict) { // drivers dict loaded, let's populate data
          let drivers = []
          let primaryDriverId = vehicle.primary_driver_id
          if (primaryDriverId && this.driversDict[primaryDriverId]) {
            drivers.push({
              icon: 'check-circle',
              value: this.driversDict[primaryDriverId].full_name
            })
          }
          if (drivers.length) {
            data.fields.push({
              label: 'auto.drivers',
              show_as: 'array',
              value: drivers
            })
          }
        }
        return data
      },
      async loadRisks () {
        this.loading = true
        this.drivers = await this.getDriversGen2AutoWithCache(this.revisionId)
        this.vehicles = await this.getVehiclesGen2AutoWithCache(this.revisionId)
        this.loading = false
      },
      itemsPerRow (riskType) {
        switch (riskType) {
        case 'driver':
          return 12 / 3
        case 'vehicle':
          return 12
        }

        return 12
      },
    },
  }
</script>
