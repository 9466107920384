<template>
  <div>
    <div class="u-mt4 u-mb4">
      <h2 class="secondary-header" v-html="t('account.delete_my_account_title')"></h2>
    </div>
    <div>
      <span v-html="t('account.delete_my_account_page_line1')"></span>
      <span class="u-warning" v-html="t('account.delete_my_account_page_line2')"></span>

      <el-button
           class="u-mt2 u-mb4 el-button--wide u-text-uppercase"
           type="danger"
           @click="proceedToAccountDeletionClicked()"
           v-html="t('account.delete_my_account_button_proceed_to_account_removal')">
      </el-button>

    </div>

    <el-dialog :visible="showDeletionDialog"
                 :show-close="true"
                 :close-on-click-modal="false"
                 :close-on-press-escape="false"
               @close="showDeletionDialog=false"
         >
      <div slot="title"
           class="el-dialog__title_border"
           v-html="t('account.delete_my_account_confirm_account_removal')">
      </div>

      <div v-loading="loading">
        <div class="u-warning selection-forbidden"
             v-html="t('account.delete_my_account_please_type_confirmation_code_label', {confirmation_code: accountRemovalConfirmationCode})">
        </div>
        <el-input autofocus type="text"
                  class="item-input-field pin-item__input u-mt3 u-mb3"
                  required
                  v-model="typeInConfirmationCode">
        </el-input>

        <el-button :disabled="!typeInConfirmationCode || (typeInConfirmationCode !== accountRemovalConfirmationCode)"
                   class="el-button--small"
                   type="danger"
                   @click="proceedWithRemoval()"
                   v-html="t('account.delete_my_account_confirm_account_removal')">
        </el-button>

      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {mapActions, mapMutations} from 'vuex'
  import {deleteCookie, redirectToUrlByBrowserApiWeb} from '@shared_src/utils/api.utils'

  export default {
    name: 'delete-my-account',
    data () {
      return {
        loading: false,
        showDeletionDialog: false,
        accountRemovalConfirmationCode: null,
        typeInConfirmationCode: null,
      }
    },
    computed: {},
    methods: {
      ...mapActions('account', ['getConfirmationCodeForAccountRemoval', 'performAccountRemoval']),
      ...mapMutations('login', ['removeTokenAndLoginTime']),
      async proceedToAccountDeletionClicked () {
        // reset init values
        this.typeInConfirmationCode = null
        this.accountRemovalConfirmationCode = null
        // show dialog
        this.showDeletionDialog = true
        // request confirmation code
        try {
          this.loading = true
          this.accountRemovalConfirmationCode = (await this.getConfirmationCodeForAccountRemoval())
            .data.removal_confirmation_code
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }

        if (!this.accountRemovalConfirmationCode) {
          this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.error'))
          this.showDeletionDialog = false
        }
      },
      async proceedWithRemoval () {
        // send removal request with confirmation code
        try {
          this.loading = true
          await this.performAccountRemoval(this.typeInConfirmationCode)
        } catch (e) {
          this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.error'))
          return
        } finally {
          this.loading = false
        }
        this.$elAlert(
          this.t('account.delete_my_account_account_was_removed_message'),
          this.t('global.notice'),
          this.logOut
        )
      },
      logOut () {
        this.removeTokenAndLoginTime()
        localStorage.removeItem('last_location')
        deleteCookie('user_online')
        redirectToUrlByBrowserApiWeb('/login', true, true)
      },
    },
  }
</script>

<style scoped>
  .selection-forbidden {
    user-select: none
  }
</style>
