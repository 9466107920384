module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "js-vue-forced"
  }, [(_vm.columns) ? _c('el-row', {
    attrs: {
      "gutter": 30
    }
  }, _vm._l((_vm.columns), function(col, index) {
    return _c('el-col', {
      key: index,
      attrs: {
        "span": 24 / _vm.columnsInRiskDisplay,
        "xs": 24
      }
    }, [_vm._l((col), function(field, index) {
      return _c('div', {
        key: index
      }, [_c(_vm.decideOnRenderComponent(field), {
        tag: "component",
        attrs: {
          "field": field
        }
      })], 1)
    }), _vm._v(" "), (col.fields) ? _c('ba-risk-repeated-fields', {
      attrs: {
        "fields": col.fields
      }
    }) : _vm._e()], 2)
  }), 1) : _vm._e()], 1)
},staticRenderFns: []}