<template>
  <div>
    <div v-if="field.label != null" class="u-color-secondary u-mt1 u-text-trim">{{ t(field.label) }}</div>
    <div class="u-mt2 u-text-multiline-trim">{{field.value}}</div>
  </div>
</template>
<script>
  export default {
    name: 'ba-primitive-field',
    props: ['field'],
  }
</script>
