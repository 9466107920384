<template>
  <div class="pages">
    <img class="init-screen__logo-image" src="~@/assets/maintenance.jpg" />

      <el-button :disabled="maintenance" type="info" class="centered" @click="onReturnToSiteClick">
        Return to site
      </el-button>
    <span v-if="maintenance" class="centered-text">Return to site available only when maintenance is over</span>
  </div>
</template>

<script>

  import {mapActions, mapMutations} from 'vuex'
  import {deleteCookie} from '@shared_src/utils/api.utils'

  export default {
    name: 'Maintenance',
    data () {
      return {
        maintenance: true,
        intervalRef: null,
      }
    },
    mounted () {
      if (this.intervalRef) {
        clearInterval(this.intervalRef)
      }
      this.intervalRef = setInterval(async () => {
        let res = await this.up()
        this.maintenance = !res.britecore_status
      }, 30*1000)
    },
    destroyed () {
      if (this.intervalRef) {
        clearInterval(this.intervalRef)
      }
    },
    methods: {
      ...mapActions('common', ['up']),
      ...mapMutations('login', ['removeTokenAndLoginTime']),
      onReturnToSiteClick () {
        this.removeTokenAndLoginTime()
        localStorage.removeItem('last_location')
        deleteCookie('user_online')
        this.$router.replace('/login')
      }
    }
  }
</script>

<style scoped>
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 50px;
    color: black
  }
</style>
