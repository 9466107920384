// @flow
/* eslint-disable */

import axios from 'axios'
import config from '../../config/britecore'
import {ActionContext} from 'vuex'
import settings from '../../config/settings'

export class CustomPage {
  slug: string
  title: string
  name: string
  is_draft: boolean
  is_public: boolean

  is_standalone_link: boolean

}



export class CustomPageStoreState {
  allPages : Array<CustomPage> = []
}

const customPagesModule = {
  namespaced: true,
  state: new CustomPageStoreState(),
  mutations: {
    didLoadCustomPages (state: CustomPageStoreState, updatedArray: Array<CustomPage>) {
      state.allPages.push(...updatedArray)
    },
  },
  actions: {
    async loadPages(context : ActionContext, payload: any) {
      let response = await axios.get(`${config.url}/v2/company/${settings.company_id}/custom-pages/`)
      context.commit('didLoadCustomPages', response.data)
    },
  },
  getters: {
    publishedPages: (state : CustomPageStoreState) : Array<any>  => {
      return state.allPages.filter((_ : CustomPage) => !_.is_draft)
    },
    publicPages: (state : CustomPageStoreState) : Array<any>  => {
      return state.allPages.filter((_ : CustomPage) => !_.is_draft && _.is_public)
    },
    pageBySlug: (state : CustomPageStoreState)  => (slug: string) => {
      let foundPage = state.allPages.find(_  => _.slug === slug)
      if (foundPage) {
        return foundPage
      }
      // this is to show something as a title while html content is
      return null
    },
  }
}

export default customPagesModule
