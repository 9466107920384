<template>
  <div id="app">

    <bc-side-navigation
      v-if="navigationVisible"
      :isOpen="isOpen"
      :isToggled="isToggled"
      :hasAtLeastOnePolicy="hasAtLeastOnePolicy"
      :linkForPayments="linkForPayments"
      :linkForPolicies="getLinkData('/policies/')"
      :linkForDocuments="getDocumentsLink"
      :linkForClaims="linkForClaims"
      :claimsFeatureEnabled="claimsFeatureEnabled"
      :alwaysShowClaimsMenuLink="alwaysShowClaimsMenuLink"
      :openClaims="openClaims"
      :linkForAgent="linkForAgent"
      @hideMobile="hideMobile"
      @logOut="logOutClicked"
    />
    
    <el-container class="c-page" :class="isLoginPage ? 'bg-login' : ''">
    
      <bc-top-navigation
        v-if="navigationVisible"
        :badge="badge"
        :isFeatureEnabled="isFeatureEnabled('message-center')"
        :isToggled="isToggled"
        @hideMobile="hideMobile"
        @toggleMobMenu="toggleMobileMenu"
        @logOut="logOutClicked"
      />
      
      <main :class="isLoginPage ? 'u-h100 c-page__main' : ''">
        <router-view>
        </router-view>
      </main>

      <bc-footer :style="loginStyle"></bc-footer>
    </el-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
import settings from '../../config/settings'
import Footer from './footer.vue'
import {deleteCookie, redirectToUrlByBrowserApiWeb} from '@shared_src/utils/api.utils'
import TopNavigation from '../components/top-navigation'
import SideNavigation from '../components/side-navigation'

export default {
  components: {
    'bc-footer': Footer,
    'bc-top-navigation': TopNavigation,
    'bc-side-navigation': SideNavigation,
  },
  metaInfo () {
    // DO NOT Move this inside arrow function, as reactive updates will not work
    let title = this.$route.meta.title
    let browserTitle = this.t('global.browser_title')
    return {
      titleTemplate: () => {
        if (browserTitle) {
          return title ? `${title} | ${browserTitle}` : browserTitle
        } else {
          return title ? `${title} | ${this.companyName}` : this.companyName
        }
      },
    }
  },
  computed: {
    ...mapState('login', ['isAuthorized']),
    ...mapState('policies', ['policies']),
    ...mapGetters('policies', ['hasAtLeastOnePolicy', 'policiesListByType']),
    ...mapGetters('claims', ['openClaims', 'closedClaims']),
    ...mapGetters('messageCenter', ['badge']),
    ...mapGetters('common', ['isFeatureEnabled']),
    navigationVisible () {
      return this.isAuthorized && !this.isLoginPage && !this.isMaintenancePage && !this.isAccountBlockedPage
    },
    getDocumentsLink () {
      return `/documents/${this.computePolicyLink}/folders/root`
    },
    loginStyle () {
      return {
        display: this.isLoginPage ? 'none' : 'block'
      }
    },
    isLoginPage () {
      const routesList = ['/login', '/enrollment', 'password-reset/search-account', 'password-reset/code-delivery',
        'password-reset/new-password', '/password-update-required']
      return routesList.some(el => this.$route.path.includes(el))
    },
    isMaintenancePage () {
      const routesList = ['/maintenance']
      return routesList.some(el => this.$route.path.includes(el))
    },
    isAccountBlockedPage () {
      const routesList = ['/account-blocked']
      return routesList.some(el => this.$route.path.includes(el))
    },
    claimsFeatureEnabled () {
      return this.setting('claims.claims_enabled')
    },
    alwaysShowClaimsMenuLink () {
      return this.setting('claims.always_show_claims_menu_link')
    },

    linkForAgent () {
      return '/agents/list'
    },
    linkForClaims () {
      let claimsList = []
      let getClaimsByOrder = (claims) => _.orderBy(claims, ['date_added'], ['desc'])
      let calculateLink = (orderedList) => `/claims/${orderedList[0].id}`
      if (this.openClaims && this.openClaims.length > 0) {
        claimsList = getClaimsByOrder(this.openClaims)
        return calculateLink(claimsList)
      }
      if (this.closedClaims && this.closedClaims.length > 0) {
        claimsList = getClaimsByOrder(this.closedClaims)
        return calculateLink(claimsList)
      }
      return `/claims/new/undefined`
    },
    computePolicyLink () {
      if (!this.policies || this.policies.policies.length === 0) {
        return ''
      } if (this.$route.params.policyId && this.$route.params.policyId !== 'undefined') {
        return this.$route.params.policyId
      } if (this.policiesListByType && this.policiesListByType.active.length > 0) {
        return this.policiesListByType.active[0].id
      } else {
        return this.policies.policies[0].id
      }
    },
    linkForPayments () {
      return '/payments'
    },
  },
  methods: {
    ...mapMutations('login', ['removeTokenAndLoginTime']),
    logOutClicked () {
      this.removeTokenAndLoginTime()
      localStorage.removeItem('last_location')
      deleteCookie('user_online')
      redirectToUrlByBrowserApiWeb('/login', true, true)
    },
    getLinkData (type) {
      return this.computePolicyLink.length > 0 ? type + this.computePolicyLink : ''
    },
    navigateTo (path) {
      this.$router.push(path)
    },
    getWindowWidth () {
      let screenWidth = window.innerWidth
      screenWidth <= 1024 ? this.isOpen = false : this.isOpen = true
    },
    toggleMobileMenu () {
      let bodyClassList = document.body.classList
      this.isToggled = !this.isToggled
      this.isToggled ? bodyClassList.add('overflow-hidden'): bodyClassList.remove('overflow-hidden')
    },
    hideMobile () {
      let bodyClassList = document.body.classList
      let screenWidth = window.innerWidth
      if (screenWidth <= 1024) {
        this.isToggled = false
        this.isToggled ? bodyClassList.add('overflow-hidden'): bodyClassList.remove('overflow-hidden')
      }
    },
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      this.getWindowWidth()
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  data () {
    return {
      companyId: settings.company_id || null,
      companyName: settings.global.carrier_name || null,
      isOpen: this.getWindowWidth,
      isToggled: false
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../sass/_variables.scss';

  #app {
    display: flex;
    
    @media only screen and (max-width: $l-screen) {
      display: block;
    }
  }
  
  .c-page {
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (max-width: $s-screen) {
      height: auto;
    }

    &__main {
      @media only screen and (max-width: $s-screen) {
        height: 100vh;
      }
    }
  }
  .nav-hide {
    display: none;
  }

  .go-click {
    display: none;
    @media only screen and (max-width: $m-screen) {
      display: block;
    }
  }
  .go-menu {
    display: block;
    @media only screen and (max-width: $m-screen) {
      display: none;
    }
  }
</style>
