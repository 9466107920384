<template>
  <div class="back-account-details">
    <div form class="inputs-list no-hairlines">
      <div >
        <span class="subheader u-block">{{ t('payments.bank_name') }} *</span>
        <el-input class="item-input-field" type="text" v-model="ach.bank" required />
      </div>
      <div v-if="validateInput.bank" class="u-warning">
        <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInput.bank }}
      </div>

      <div class="u-mt3">
        <span class="subheader u-block">{{ t('payments.routing_number') }} *</span>
        <el-input class="item-input-field" v-model="ach.routing"
                  type="text" placeholder="000000000" required />
      </div>
      <div v-if="validateInput.routing" class="u-warning">
        <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInput.routing }}
      </div>

      <div class="u-mt3">
        <span class="subheader u-block">{{ t('payments.account_number') }} *</span>
        <el-input class="item-input-field" v-model="ach.account" style="border-left:0"
                   type="text" placeholder="000000000" required />
      </div>
      <div v-if="validateInput.accountNumber" class="u-warning">
        <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInput.accountNumber }}
      </div>

      <div class="u-mt3">
        <span class="subheader u-block">{{ t('payments.name_on_account') }} *</span>
        <el-input class="item-input-field" v-model="ach.name_on" type="text" required />
      </div>
      <div v-if="validateInput.nameAccount" class="u-warning">
        <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInput.nameAccount }}
      </div>

      <div class="u-mt3">
        <span class="subheader u-block">{{ t('payments.account_type') }} *</span>
        <el-select class="item-input-field" type="select" v-model="ach.type" required>
          <el-option value="0" disabled hidden :label="t('payments.account_type')"></el-option>
          <el-option value="Personal Checking" :label="t('payments.personal_checking')"></el-option>
          <el-option value="Personal Savings" :label="t('payments.personal_savings')"></el-option>
          <el-option value="Personal Loan" :label="t('payments.personal_loan')"></el-option>
          <el-option value="Business Savings" :label="t('payments.business_savings')"></el-option>
          <el-option value="Business Checking" :label="t('payments.business_checking')"></el-option>
          <el-option value="Business Loan" :label="t('payments.business_loan')"></el-option>
        </el-select>
      </div>
      <div v-if="validateInput.accountType" class="u-warning">
        <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInput.accountType }}
      </div>
    </div>
  </div>

</template>

<script>
import {deepClone} from '@shared_src/utils/misc.util'

export default {
  name: 'bc-bank-account-details',
  props: {},
  data () {
    let ach = {
      type: '0',
      name_on: '',
      bank: '',
      account: '',
      routing: '',
    }

    return {
      ach,
      validateInput: {
        bank: null,
        routing: null,
        nameAccount: null,
        accountNumber: null,
        accountType: null,
      }
    }
  },
  computed: {
    isValidInput () {
      return {
        bank: (!this.ach.bank.trim()) ? this.t('payments.bank_name_is_required') : null,
        routing: (!this.ach.routing.trim()) ? this.t('payments.routing_number_is_required') : null,
        nameAccount: (!this.ach.name_on.trim()) ? this.t('payments.name_on_account_is_required') : null,
        accountNumber: (!this.ach.account.trim()) ? this.t('payments.account_number_is_required') : null,
        accountType: (this.ach.type === '0') ? this.t('payments.account_type_is_required') : null,
      }
    },
    validateInputValues () {
      return Object.keys(this.isValidInput).every(key => !this.isValidInput[key])
    },
  },
  methods: {
    getPaymentMethodData () {
      this.validateInput = deepClone(this.isValidInput)
      if (!this.validateInputValues) {
        return
      }
      return this.ach
    },
    clearFields () {
      this.ach = {
        type: '0',
        name_on: '',
        bank: '',
        account: '',
        routing: '',
      }

      this.validateInput = {
        bank: null,
        routing: null,
        nameAccount: null,
        accountNumber: null,
        accountType: null,
      }
    }
  },
}
</script>
