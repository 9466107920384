<template>
  <div>
    <drag-n-drop-photo v-if="!setting('policies.read_only_property_photos')"
                     v-loading="loading"
                     @onPhotoUpload="onPhotoUpload"></drag-n-drop-photo>
    <div v-else class="u-flex-vertical-center upload-forbidden">
      <div class="u-text-center">{{ t('policies.property_photo_upload_is_not_allowed') }}</div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import config from '@/../config/britecore'
  import dragnDropPhotoComponent from '@/components/drag-n-drop-photo.component.vue'
  import {axiosWrapper} from '@shared_src/utils/misc.util'

  export default {
    name: 'upload-image',
    props: {
      propertyId: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        loading: false,
      }
    },
    components: {
      'drag-n-drop-photo': dragnDropPhotoComponent,
    },
    computed: {
      ...mapGetters('policies', ['policyFromPropertyId']),
    },
    methods: {
      async addPropertyPhoto (photoDict) {
        let policy = this.policyFromPropertyId(this.propertyId)
        return await axiosWrapper(
          axios.post(config.add_property_photo_url, {
            token: localStorage.token,
            file_id: '',
            caption: photoDict.fileName,
            set_as_preferred: false,
            photo: photoDict.image,
            property_id: this.propertyId,
            filename: photoDict.fileName,
            mimetype: photoDict.mimeType,
            policy_number: policy ? policy.policy_number : null
          })
        )
      },
      async onPhotoUpload (photoDict) {
        this.loading = true
        try {
          await this.addPropertyPhoto(photoDict)
          this.$root.$emit('reloadPropertyPhotos', this.propertyId)
        } catch (err) {
          this.$elAlert(this.t('policies.error_uploading_property_photo'))
        } finally {
          this.loading = false
        }
      },
    }
  }
</script>


<style lang="scss" scoped>
  .upload-forbidden {
    min-height: 150px;
  }
</style>
