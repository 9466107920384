<template>
  <div>
    <bc-navbar :title="t('payments.payments_label')"></bc-navbar>
    <div class="submenu">
      <div class="submenu__menu-outer">
        <div class="submenu__nested-menu u-mt4">
          <div class="submenu__block-links u-mb2">
            <router-link to="/payments" class="submenu__link">
              <div class="submenu__link-line-1 u-p1">
                <span class="submenu__link_bold">{{ t('payments.make_payments') }}</span>
              </div>
            </router-link>
            <router-link to="/payments/payment-methods" class="submenu__link">
              <div class="submenu__link-line-1 u-p1">
                <span class="submenu__link_bold">{{ t('payments.payment_methods') }}</span>
              </div>
            </router-link>
            <router-link v-if="displayPaymentsPlans" to="/payments/payment-plans" class="submenu__link">
              <div class="submenu__link-line-1 u-p1">
                <span class="submenu__link_bold">{{ t('payments.payment_plans') }}</span>
              </div>
            </router-link>
            <router-link :to="getFirstPolicyLink" :class="[isActivePayHistoryLink, 'submenu__link']">
              <div class="submenu__link-line-1 u-p1">
                <span class="submenu__link_bold">{{ t('payments.payment_history') }}</span>
              </div>
            </router-link>
            <router-link v-if="setting('payments.allow_payment_reminders')" to="/payments/reminders"  class="submenu__link">
              <div class="submenu__link-line-1 u-p1">
                <span class="submenu__link_bold" v-html="t('payments.reminders_side_menu_link_title')"></span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="submenu__view">
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'all-payments.page',
    computed: {
      ...mapState('policies', ['policies']),
      getFirstPolicyLink () {
        return this.policies ? '/payments/payment-history/' + this.policies.policies[0].id : ''
      },
      isActivePayHistoryLink () {
        let boundLinks = ['/payment-history', '/invoice-view']
        let isActiveLink = boundLinks.some(link => {
          return this.$route.fullPath.includes(link)
        })
        return isActiveLink ? 'router-link-exact-active' : ''
      },
      displayPaymentsPlans () {
        return this.setting('payments.show_payment_plan') || this.setting('payments.allow_auto_pay')
      },
    },
    mounted () {
      // don't let user enter payment screens by link if  payments.use_external_payment_site  enabled
      if (this.setting('payments.use_external_payment_site')) this.$router.replace('/dashboard')
    }
  }
</script>
