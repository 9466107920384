module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('div', {
    staticClass: "u-mt4 u-mb4"
  }, [_c('h2', {
    staticClass: "secondary-header",
    domProps: {
      "innerHTML": _vm._s(_vm.t('account.delete_my_account_title'))
    }
  })]), _vm._v(" "), _c('div', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.t('account.delete_my_account_page_line1'))
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "u-warning",
    domProps: {
      "innerHTML": _vm._s(_vm.t('account.delete_my_account_page_line2'))
    }
  }), _vm._v(" "), _c('el-button', {
    staticClass: "u-mt2 u-mb4 el-button--wide u-text-uppercase",
    attrs: {
      "type": "danger"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.t('account.delete_my_account_button_proceed_to_account_removal'))
    },
    on: {
      "click": function($event) {
        return _vm.proceedToAccountDeletionClicked()
      }
    }
  })], 1), _vm._v(" "), _c('el-dialog', {
    attrs: {
      "visible": _vm.showDeletionDialog,
      "show-close": true,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "close": function($event) {
        _vm.showDeletionDialog = false
      }
    }
  }, [_c('div', {
    staticClass: "el-dialog__title_border",
    attrs: {
      "slot": "title"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.t('account.delete_my_account_confirm_account_removal'))
    },
    slot: "title"
  }), _vm._v(" "), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }]
  }, [_c('div', {
    staticClass: "u-warning selection-forbidden",
    domProps: {
      "innerHTML": _vm._s(_vm.t('account.delete_my_account_please_type_confirmation_code_label', {
        confirmation_code: _vm.accountRemovalConfirmationCode
      }))
    }
  }), _vm._v(" "), _c('el-input', {
    staticClass: "item-input-field pin-item__input u-mt3 u-mb3",
    attrs: {
      "autofocus": "",
      "type": "text",
      "required": ""
    },
    model: {
      value: (_vm.typeInConfirmationCode),
      callback: function($$v) {
        _vm.typeInConfirmationCode = $$v
      },
      expression: "typeInConfirmationCode"
    }
  }), _vm._v(" "), _c('el-button', {
    staticClass: "el-button--small",
    attrs: {
      "disabled": !_vm.typeInConfirmationCode || (_vm.typeInConfirmationCode !== _vm.accountRemovalConfirmationCode),
      "type": "danger"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.t('account.delete_my_account_confirm_account_removal'))
    },
    on: {
      "click": function($event) {
        return _vm.proceedWithRemoval()
      }
    }
  })], 1)])], 1)
},staticRenderFns: []}