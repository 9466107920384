module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "pages"
  }, [_c('span', {
    staticClass: "centered-text"
  }, [_vm._v(_vm._s(_vm.t('account.account_is_blocked_page_text')))]), _vm._v(" "), _c('el-button', {
    staticClass: "centered",
    attrs: {
      "type": _vm.info
    },
    on: {
      "click": _vm.onReturnToLoginPageClick
    }
  }, [_vm._v("Return to login page")])], 1)
},staticRenderFns: []}