module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [(_vm.loading) ? _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (true),
      expression: "true"
    }],
    staticClass: "u-mt4 u-mb0 u-text-center"
  }, [_c('h2', [_vm._v(_vm._s(_vm.t('global.loading')))])]) : _c('div', [(!_vm.groupVehiclesByType) ? _c('div', {
    staticClass: "u-mt4 u-mb0"
  }, [_c('h2', {
    staticClass: "secondary-header"
  }, [_vm._v(_vm._s(_vm.t('auto.vehicle_details')) + " (" + _vm._s(_vm.vehiclesPreparedData.length) + ")")]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 30
    }
  }, _vm._l((_vm.vehiclesPreparedData), function(vehicleData, index) {
    return _c('el-col', {
      key: index,
      attrs: {
        "xs": 24,
        "span": _vm.itemsPerRow('vehicle') * 2
      }
    }, [_c('el-card', {
      attrs: {
        "shadow": "never"
      }
    }, [_c('div', {
      staticClass: "u-mb2"
    }, [_c('ba-risk-icon', {
      staticClass: "u-mr2 u-color-secondary",
      attrs: {
        "risk-component": {
          type: 'vehicle'
        }
      }
    }), _vm._v("\n              " + _vm._s(vehicleData.name) + "\n            ")], 1), _vm._v(" "), (vehicleData.fields) ? _c('ba-risk-repeated-fields', {
      attrs: {
        "fields": vehicleData.fields,
        "riskType": vehicleData.dataType
      }
    }) : _vm._e()], 1)], 1)
  }), 1)], 1) : _vm._e(), _vm._v(" "), (_vm.groupVehiclesByType) ? _c('div', _vm._l((_vm.groupedVehiclesPreparedData), function(group, groupIndex) {
    return _c('div', {
      key: groupIndex,
      staticClass: "u-mt4 u-mb0"
    }, [_c('h2', {
      staticClass: "secondary-header"
    }, [_vm._v(_vm._s(group.label) + " (" + _vm._s(group.items.length) + ")")]), _vm._v(" "), _c('el-row', {
      attrs: {
        "gutter": 30
      }
    }, _vm._l((group.items), function(vehicleData, index) {
      return _c('el-col', {
        key: index,
        attrs: {
          "xs": 24,
          "span": _vm.itemsPerRow('vehicle') * 2
        }
      }, [_c('el-card', {
        attrs: {
          "shadow": "never"
        }
      }, [_c('div', {
        staticClass: "u-mb2"
      }, [_c('ba-risk-icon', {
        staticClass: "u-mr2 u-color-secondary",
        attrs: {
          "risk-component": {
            type: 'vehicle'
          }
        }
      }), _vm._v("\n                " + _vm._s(vehicleData.name) + "\n              ")], 1), _vm._v(" "), (vehicleData.fields) ? _c('ba-risk-repeated-fields', {
        attrs: {
          "fields": vehicleData.fields,
          "riskType": vehicleData.dataType
        }
      }) : _vm._e()], 1)], 1)
    }), 1)], 1)
  }), 0) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "u-mt4 u-mb0"
  }, [_c('h2', {
    staticClass: "secondary-header"
  }, [_vm._v(_vm._s(_vm.t('auto.drivers')) + " (" + _vm._s(_vm.driversPreparedData.length) + ")")]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 30
    }
  }, _vm._l((_vm.driversPreparedData), function(driverData, index) {
    return _c('el-col', {
      key: index,
      attrs: {
        "xs": 24,
        "span": _vm.itemsPerRow('driver') * 2
      }
    }, [_c('el-card', {
      attrs: {
        "shadow": "never"
      }
    }, [_c('div', {
      staticClass: "u-mb2"
    }, [_c('ba-risk-icon', {
      staticClass: "u-mr2 u-color-secondary",
      attrs: {
        "risk-component": {
          type: 'driver'
        }
      }
    }), _vm._v("\n              " + _vm._s(driverData.name) + "\n            ")], 1), _vm._v(" "), (driverData.fields) ? _c('ba-risk-repeated-fields', {
      attrs: {
        "fields": driverData.fields,
        "riskType": driverData.dataType
      }
    }) : _vm._e()], 1)], 1)
  }), 1)], 1), _vm._v(" "), (_vm.nonDriversPreparedData.length) ? _c('div', {
    staticClass: "u-mt4 u-mb0"
  }, [_c('h2', {
    staticClass: "secondary-header"
  }, [_vm._v(_vm._s(_vm.t('auto.non_drivers')) + " (" + _vm._s(_vm.nonDriversPreparedData.length) + ")")]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 30
    }
  }, _vm._l((_vm.nonDriversPreparedData), function(nonDriverData, index) {
    return _c('el-col', {
      key: index,
      attrs: {
        "xs": 24,
        "span": _vm.itemsPerRow('driver') * 2
      }
    }, [_c('el-card', {
      attrs: {
        "shadow": "never"
      }
    }, [_c('div', {
      staticClass: "u-mb2"
    }, [_c('ba-risk-icon', {
      staticClass: "u-mr2 u-color-secondary",
      attrs: {
        "risk-component": {
          type: 'driver'
        }
      }
    }), _vm._v("\n              " + _vm._s(nonDriverData.name) + "\n            ")], 1), _vm._v(" "), (nonDriverData.fields) ? _c('ba-risk-repeated-fields', {
      attrs: {
        "fields": nonDriverData.fields,
        "riskType": nonDriverData.dataType
      }
    }) : _vm._e()], 1)], 1)
  }), 1)], 1) : _vm._e()])])
},staticRenderFns: []}