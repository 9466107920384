import axios from 'axios'
import moment from 'moment'
import config from '@/../config/britecore'
import {isFailed} from '@shared_src/utils/api.utils'
import {axiosWrapper, usDateToMomentJsDate, getEarliestDate} from '@shared_src/utils/misc.util'
import {defineSinglePaymentOptions, asCurrency} from '@shared_src/utils/payments.utils'
import {paymentMethodToDict} from '@shared_src/store/common.module'
import { isPaymentAllowed } from '../utils/payments.utils'
import {localize as t} from '@/utils/i18n'
import eventBus from '@shared_src/eventHub'

const paymentsModule = {
  namespaced: true,
  state: {
    acctHistory: [],
    policiesForPay: null,
    ownedPaymentMethods: [],
  },
  mutations: {
    setAccountHistory: function (state, payload) {
      let policyId = payload.policyId
      let newAcctHistory = payload.history

      let cached = state.acctHistory.find(p => p.policyId === policyId)
      if (!cached) {
        state.acctHistory.push({policyId: policyId, acctHistory: newAcctHistory})
      } else {
        cached.acctHistory = newAcctHistory
      }
    },
    setCheckedPolicies (state, payload) {
      // TODO method/variable need to be removed in future since it's redundant step
      let checkedPolicies = []
      for (let index in payload) {
        let policy = payload[index]
        if (isPaymentAllowed(policy)) {
          checkedPolicies.push(policy)
        }
      }
      state.policiesForPay = checkedPolicies
    },
    setOwnedPaymentMethods (state, payload) {
      state.ownedPaymentMethods = payload.map(paymentMethod => paymentMethodToDict(paymentMethod))
    },
  },
  actions: {
    makePayment: function (context, payload) {
      return new Promise((resolve, reject) => {
        let token = localStorage.token
        let requestData = {
          token,
          ...payload
        }
        axios.post(config.url + '/ajax-make-payment/', requestData)
          .then((response) => {
            if (isFailed(response)) {
              console.error('ajax-make-payment failed', response)
              reject()
            }
            resolve(response.data)
          })
          .catch((e) => {
            console.error('ajax-make-payment failed', e)
            reject()
          })
      })
    },
    getPolicyBillingInformation: function (context, payload) {
      return new Promise((resolve, reject) => {
        let token = localStorage.token
        let requestData = {
          token,
          ...payload
        }
        axios.post(config.get_policy_billing_information_url, requestData)
          .then((response) => {
            if (isFailed(response)) {
              console.error('getPolicyBillingInformation failed', response)
              reject()
            }
            resolve(response.data)
          })
          .catch((e) => {
            console.error('getPolicyBillingInformation failed', e)
            reject()
          })
      })
    },
    async retrieveBillingScheduleOptions (context, payload) {
      return axiosWrapper(
        axios.post(config.retrieve_billing_schedule_options, {token: localStorage.token, ...payload})
      )
    },
    async getOwnedPaymentMethods (context, policyId) {
      let result = await axiosWrapper(
        axios.get(config.get_owned_payment_methods_url, {headers: {Authorization: localStorage.token}}))
      context.commit('setOwnedPaymentMethods', result.data)
    },
    async deletePaymentMethod (context, paymentMethodId) {
      return axiosWrapper(
        axios.post(config.remove_payment_method_url, {token: localStorage.token, payment_method_id: paymentMethodId})
      )
    },
    addPaymentMethod: function (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(config.ajax_add_payment_method_url, {
          token: localStorage.token,
          ...payload,
        }).then(
          (result) => {
            if (result.data.success && result.data.action_result === 'created') {
              // payment method was CREATED successfully
              context.dispatch('getOwnedPaymentMethods').then(
                (result) => {
                  resolve(result)
                },
                (error) => {
                  console.warn('Server error while retrieve payment methods...', error)
                  reject(error)
                }
              )
            } else { // call was successful BUT action_result != 'created' as expected
              console.warn('create payment method did not succeed completely...', result.data)
              reject(result)
            }
          },
          (error) => {
            console.warn('Server error while creating create payment...', error)
            reject(error)
          }
        )
      })
    },
    async changeBillingSchedule (context, payload) {
      return axiosWrapper(
        axios.post(
          config.change_billing_schedule,
          {
            ...payload,
            token: localStorage.token,
          }
        )
      )
    },
    async changeAutopaySingle (context, payload) {
      return axiosWrapper(
        axios.post(
          config.get_change_autopay_single_url,
          {
            ...payload,
            token: localStorage.token,
          }
        )
      )
    },
    loadAccountHistory: function (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(config.get_account_history_url, {
          token: localStorage.token,
          account_params: {
            policy_id: payload.policyId,
            current_term_id: payload.currentTermId,
            next_term_id: payload.nextTermId,
            current_page: 1
          },
        })
          .then(response => {
            if (!isFailed(response)) {
              let history = response.data.data.records
              context.commit('setAccountHistory', { policyId: payload.policyId, history })
              resolve()
            } else {
              reject()
            }
          }, err => {
            let data = 'Error during loading account history '
            console.warn(data, err)
            reject(data)
          })
      })
    },
    async getPaymentReminders (context) {
      return await axiosWrapper(
        axios.get(
          config.payment_reminders_url,
          {
            headers: {Authorization: localStorage.token}
          }
        )
      )
    },
    async updatePaymentReminders (context, data) {
      if (data.enabled) {
        // we need to fix setup if invalid. This will prevent blind enabling without any reminders set
        if (!data.schedule.length) {
          data.schedule = [7]
        }
        if (!(data.push || data.email || data.text)) {
          data.email = true
        }
      }

      let result = await axiosWrapper(
        axios.put(
          config.payment_reminders_url,
          { ...data,
            token: localStorage.token
          }
        )
      )
      eventBus.$emit('payment-reminders-updated')
      return result
    },
  },
  getters: {
    acctHistoryByPolicyId: (state, getters) => (policyId) => {
      if (!state.acctHistory) {
        return null
      }
      let cached = state.acctHistory.find(i => i.policyId === policyId)
      if (cached) {
        return cached.acctHistory
      } else {
        return null
      }
    },
    allAcctHistory (state) {
      if (!state.acctHistory) {
        return null
      }
      return state.acctHistory
    },
    payOptions (state, getters) {
      const result = {
        currentDue: [],
        entireTerm: [],
        nextTerm: []
      }
      if (!state.policiesForPay.length) return result
      state.policiesForPay.forEach(policy => {
        if (policy.is_canceled) return
        let address = policy.properties && policy.properties.length ? policy.properties[0].address_line1 : ''
        // Entire Term
        if (policy.payoff_amount > 0) {
          let options = defineSinglePaymentOptions('full', policy, policy.payoff_amount, '', address)
          result.entireTerm.push(options)
        }
        // Current Due and Overdue
        if (policy.current_due > 0) {
          let options = defineSinglePaymentOptions(
            'min', policy, policy.current_due, getters.policyDueWeb(policy.id).cssClass, address)
          result.currentDue.push(options)
        }
        // Next Term
        if (!policy.payoff_amount && policy.next_due > 0) {
          let options = defineSinglePaymentOptions('next_term', policy, policy.next_due, '', address)
          result.nextTerm.push(options)
        }
      })
      return result
    },
    policyDueWeb: (state, getters, rootState, rootGetters) => (policyId) => {
      // this is simple-due for single policy. No need to sum up dues etc
      // because it will be used on make-payment screen where these sums will deceive insured
      // again - IT WILL NOT SUM CURRENT AND NEXT DUES!
      // used for Web page "Make Payment" and for payOptions getter (partially)
      let values = getters.policyPaymentState(policyId)

      let res = {
        message: t('global.nothing_due'),
        dateTxt: '',
        amount: '$0.00',
        cssClass: '',
      }
      if (!values) return res

      if (values.dueValue > 0) {
        res.dateTxt = moment(values.dueDate).format('MM/DD/YYYY')
        res.amount = asCurrency(values.dueValue)
        if (values.overDue) {
          res.message = t('payments.overdue')
          res.cssClass = 'u-danger'
        } else {
          res.message = t('payments.currently_due')
          res.cssClass = ''
        }
        return res
      } else if (values.dueValue <= 0 && values.nextDueValue > 0) {
        // weird patch of code. Looks like it never was executed
        // res.dateTxt = t('payments.due_date')
        res.dateTxt = moment(values.nextDueDate).format('YYYY-MM-DD')
        res.message = moment(values.nextDueDate).format('YYYY-MM-DD')
        return res
      }
      return res
    },
    policyPaymentMethods: (state, getters, rootState, rootGetters) => (policyId) => {
      let policy = rootGetters['policies/policyById'](policyId)
      let revision = policy.revision
      let namedInsureds = revision.named_insureds
      let policyContactIds = namedInsureds.map(namedInsured => namedInsured.contact_id)

      let insureds = rootState.account.accountData.insureds
      let myContactIds = []
      for (let insuredId in insureds) {
        if (!insureds.hasOwnProperty(insuredId)) continue
        let contactId = insureds[insuredId].contact_id
        myContactIds.push(contactId)
      }

      let myPolicyContactIds = myContactIds.filter(contactId => policyContactIds.includes(contactId))

      return state.ownedPaymentMethods.filter(
          paymentMethod => myPolicyContactIds.includes(paymentMethod.contact_id))
    },
    policyPaymentState: (state, getters, rootState, rootGetters) => (policyId) => {
      // normalize and determine payment-related values
      function sortByBillDate (a, b) {
        return moment(usDateToMomentJsDate(b.data.details.billDate)) -
          moment(usDateToMomentJsDate(a.data.details.billDate))
      }
      let policy = rootGetters['policies/policyById'](policyId)
      let currentDate = moment()
      let overDue = false
      let dueValue = policy.current_due > 0 ? policy.current_due : 0  // not interested in negative values
      let dueDate = null
      if (dueValue) {
        if (policy.due_date === '--') {
          // when BC didn't provided due date - that means we have corner case scenario when user have debt
          // less than policycycle.bill_less_than_amount. In this case BC says "paid in full" for invoices, however
          // insured still owes us these money
          // TODO thinks a lot in future about changing/adding BC endpoint to provide exact paid-in-full information
          let policyAccountHistory = getters.acctHistoryByPolicyId(policy.id)
          if (!policyAccountHistory) return null // don't return anything if we have not enough data ATM
          let allCurrentInvoices = policyAccountHistory.filter(
            item => item.term_type === 'current' && item.type === 'Invoice')
          let allPaidInFullCurrentTermInvoices = allCurrentInvoices.filter(
            item => item.data.details.paidInFull === true)
          let allNonPaidInFullCurrentTermInvoices = allCurrentInvoices.filter(
            item => item.data.details.paidInFull === false)

          let sortedPaidInFullInvoices = allPaidInFullCurrentTermInvoices.sort(sortByBillDate)
          let sortedNonPaidInFullInvoices = allNonPaidInFullCurrentTermInvoices.sort(sortByBillDate)
          if (!sortedNonPaidInFullInvoices.length) {
            // we don't have non-paid off invoices.
            // this is scenario when all invoice are "paid off", but we have small debt
            // in this case we must show due date of last paid-off invoice
            // reason - BriteApps has 3 pay options - current due/payoff current term/pay next term due
            // we cannot pay next term due until we pay all debts for current term. This is why it's better to show
            // current due separately with date of last paid-off invoice
            if (sortedPaidInFullInvoices.length) {
              let newestPaidOffInvoice = sortedPaidInFullInvoices[0]
              dueDate = moment(usDateToMomentJsDate(newestPaidOffInvoice.data.details.dueDate))
              overDue = currentDate.isAfter(dueDate)
            }
          } else {
            // we have unpaid invoices. Take first unpaid invoice date
            let oldestUnpaidInvoice = sortedNonPaidInFullInvoices[sortedNonPaidInFullInvoices.length-1]
            dueDate = moment(usDateToMomentJsDate(oldestUnpaidInvoice.data.details.dueDate))
            overDue = currentDate.isAfter(dueDate)
          }
        } else {
          dueDate = moment(usDateToMomentJsDate(policy.due_date))
          overDue = currentDate.isAfter(dueDate)
        }
      }
      let payOffAmount = policy.payoff_amount > 0 ? policy.payoff_amount : 0
      let nextDueValue = policy.next_due > 0 ? policy.next_due : 0
      let nextDueDate = null
      let nextOverDue = false
      let nextDue = false
      if (nextDueValue) {
        let policyAccountHistory = getters.acctHistoryByPolicyId(policy.id)
        if (!policyAccountHistory) return null // don't return anything if we have not enough data ATM
        if (policyAccountHistory) {
          let nextTermInvoices = policyAccountHistory.filter(
            item => item.term_type === 'next' && item.type === 'Invoice' && item.data.details.paidInFull === false)
          if (nextTermInvoices.length) {
            let firstInvoice = nextTermInvoices[nextTermInvoices.length - 1]
            let nextBillDate = firstInvoice.data.details.billDate
            nextBillDate = moment(usDateToMomentJsDate(nextBillDate))
            nextDueDate = firstInvoice.data.details.dueDate
            nextDueDate = moment(usDateToMomentJsDate(nextDueDate))
            nextOverDue = currentDate.isAfter(nextDueDate)
            nextDue = currentDate.isAfter(nextBillDate) && !nextOverDue
          }
        }
      }
      return {
        dueValue,
        dueDate,
        overDue,
        payOffAmount,
        nextDueValue,
        nextDueDate,
        nextDue,
        nextOverDue
      }
    },
    extendedPolicyPaymentState: (state, getters) => (policyId) => {
      let payState = getters.policyPaymentState(policyId)
      if (!payState) return null
      let newSimpleValues = {
        // Idea is: each policy could have 2 states together if policy has current and next term.
        // F.e. you have overdue on current term (forgot to pay $10) and
        // due about $1000+ for next term (you need to pay to start new term)
        // for such scenarios it's better to show both values
        // attention! IT WILL SUM CURRENT AND NEXT DUES!
        dueValue: null,
        dueDate: null,
        hasDue: false,
        overDueValue: null,
        overDueDate: null,
        hasOverDue: false,
      }
      let simpleDueValue = 0
      let simpleDueDate = null
      let simpleOverDue = false
      if (payState.dueValue) {
        simpleDueValue = payState.dueValue
        simpleDueDate = payState.dueDate
        simpleOverDue = payState.overDue
        // fill data for new dataset
        if (payState.overDue) {
          newSimpleValues.overDueValue = payState.dueValue
          newSimpleValues.overDueDate = payState.dueDate
          newSimpleValues.hasOverDue = true
        } else {
          newSimpleValues.dueValue = payState.dueValue
          newSimpleValues.dueDate = payState.dueDate
          newSimpleValues.hasDue = true
        }
      }
      if (payState.nextDueValue) {
        if (payState.nextDue && !simpleOverDue) {
          simpleDueDate = getEarliestDate(simpleDueDate, payState.nextDueDate)
          simpleDueValue += payState.nextDueValue
        } else if (payState.nextOverDue && simpleOverDue) {
          simpleDueDate = getEarliestDate(simpleDueDate, payState.nextDueDate)
          simpleDueValue += payState.nextDueValue
        } else if (payState.nextOverDue && !simpleOverDue) {
          simpleDueDate = payState.nextDueDate
          simpleDueValue = payState.nextDueValue
          simpleOverDue = true
        }

        if (payState.nextDue) {
          if (newSimpleValues.hasDue) {
            newSimpleValues.dueValue += payState.nextDueValue
            newSimpleValues.dueDate = getEarliestDate(payState.nextDueDate, newSimpleValues.dueDate)
          } else {
            newSimpleValues.dueValue = payState.nextDueValue
            newSimpleValues.dueDate = payState.nextDueDate
          }
          newSimpleValues.hasDue = true
        } else if (payState.nextOverDue) {
          if (newSimpleValues.hasOverDue) {
            newSimpleValues.overDueValue += payState.nextDueValue
            newSimpleValues.overDueDate = getEarliestDate(payState.nextDueDate, newSimpleValues.overDueDate)
          } else {
            newSimpleValues.overDueValue = payState.nextDueValue
            newSimpleValues.overDueDate = payState.nextDueDate
          }
          newSimpleValues.hasOverDue = true
        }
      }
      return {
        ...payState,
        simpleDueValue,
        simpleDueDate,
        simpleOverDue,
        newSimpleValues,
      }
    }
  }
}

export default paymentsModule
