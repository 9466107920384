module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    staticClass: "init-screen"
  }, [_c('init-sidebar'), _vm._v(" "), _c('div', {
    staticClass: "init-screen__info centered-block"
  }, [_c('div', {
    staticClass: "init-block"
  }, [_c('div', {
    staticClass: "navigation"
  }, [_c('div', {
    staticClass: "init-block__back",
    on: {
      "click": function($event) {
        return _vm.goBack()
      }
    }
  }, [_c('ba-icon-link', {
    attrs: {
      "icon_type": "far",
      "icon_name": "arrow-left",
      "icon_class": "u-pl2",
      "label": _vm.t('documents.back')
    }
  })], 1)]), _vm._v(" "), _c('div', [_c('bc-navbar', {
    attrs: {
      "title": _vm.t('enrollment.setup_account_step_headline')
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "subheader"
  }, [_vm._v(_vm._s(_vm.t('enrollment.enter_the_details_below_to_create_your_login_credentials')))]), _vm._v(" "), _c('el-form', [(!_vm.usersAreIdentifiedByEmailAddress) ? _c('el-form-item', {
    attrs: {
      "label": _vm.t('enrollment.username')
    }
  }, [_c('el-input', {
    class: {
      'u-input-error': _vm.validateUserNameMessage
    },
    attrs: {
      "data-cy": "username",
      "type": "text",
      "data-test-id": "username"
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.verifyAndRegister.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.authUsername),
      callback: function($$v) {
        _vm.authUsername = $$v
      },
      expression: "authUsername"
    }
  }), _vm._v(" "), (_vm.validateUserNameMessage) ? _c('div', {
    staticClass: "u-input-validation u-danger u-pt2 u-pb1"
  }, [_vm._v("\n              " + _vm._s(_vm.validateUserNameMessage) + "\n            ")]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": _vm.t('enrollment.email_address_input_label') + ' *'
    }
  }, [_c('el-input', {
    class: {
      'u-input-error': _vm.validateEmailByRegexMessage
    },
    attrs: {
      "data-cy": "email",
      "type": "email",
      "data-test-id": "email",
      "disabled": !!_vm.emailFromExtra
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.verifyAndRegister.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.authEmail),
      callback: function($$v) {
        _vm.authEmail = (typeof $$v === 'string' ? $$v.trim() : $$v)
      },
      expression: "authEmail"
    }
  }), _vm._v(" "), (_vm.validateEmailByRegexMessage) ? _c('div', {
    staticClass: "u-input-validation u-danger u-pt2 u-pb1"
  }, [_vm._v("\n              " + _vm._s(_vm.validateEmailByRegexMessage) + "\n            ")]) : _vm._e()], 1), _vm._v(" "), _c('ba-new-password', {
    ref: "newPasswordField",
    staticClass: "u-mb3",
    attrs: {
      "showSubmitButton": false
    },
    on: {
      "newPasswordValue": _vm.onNewPasswordValue
    }
  }), _vm._v(" "), (!_vm.hidePin) ? _c('el-form-item', {
    staticClass: "u-mb0",
    attrs: {
      "label": "PIN"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "password",
      "autocomplete": "off",
      "maxlength": 4,
      "data-test-id": "pin-number"
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.verifyAndRegister.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.authPin),
      callback: function($$v) {
        _vm.authPin = (typeof $$v === 'string' ? $$v.trim() : $$v)
      },
      expression: "authPin"
    }
  }), _vm._v(" "), (_vm.validatePinByRegexMessage) ? _c('div', {
    staticClass: "u-warning u-mt1"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validatePinByRegexMessage) + "\n            ")]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), (!_vm.hidePin) ? _c('el-form-item', {
    staticClass: "subheader u-mt1"
  }, [_vm._v("\n            " + _vm._s(_vm.t('enrollment.pin_help_text')) + "\n          ")]) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "u-mt3 u-mb3"
  }, [_c('p', {
    staticClass: "secondary-header"
  }, [_vm._v(_vm._s(_vm.t('account.context_to_text_choice')) + "\n            "), _c('span', {
    staticClass: "u-ml2 portal-code-info"
  }, [_c('el-popover', {
    attrs: {
      "placement": "top-start",
      "title": "",
      "width": "325",
      "trigger": "hover"
    }
  }, [_c('span', {
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_c('fa-icon', {
    attrs: {
      "icon": "exclamation-circle"
    }
  })], 1), _vm._v(" "), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.t('account.consent_to_text_help'))
    }
  })])], 1)]), _vm._v(" "), _c('ba-consent-to-text', {
    ref: "consentRef",
    attrs: {
      "account": null,
      "notify-backend": false
    }
  })], 1), _vm._v(" "), (_vm.isEnabledElectronicDeliveryOption) ? _c('div', [_c('div', {
    staticClass: "document-delivery secondary-header"
  }, [_vm._v(_vm._s(_vm.t('enrollment.choose_document_delivery_method')))]), _vm._v(" "), _c('div', [_c('div', [_c('el-radio', {
    staticClass: "u-mt2 secondary-header__radio",
    attrs: {
      "data-cy": "go-paperless",
      "label": true
    },
    on: {
      "change": function($event) {
        return _vm.onDocDeliveryUpdate(true)
      }
    },
    model: {
      value: (_vm.deliveryByEmail),
      callback: function($$v) {
        _vm.deliveryByEmail = $$v
      },
      expression: "deliveryByEmail"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.t('enrollment.go_paperless')) + "*\n              ")])], 1), _vm._v(" "), _c('div', [_c('el-radio', {
    staticClass: "u-mt2 receive-paper-docs",
    attrs: {
      "data-cy": "receive-paper-docs",
      "label": false
    },
    on: {
      "change": function($event) {
        return _vm.onDocDeliveryUpdate(false)
      }
    },
    model: {
      value: (_vm.deliveryByEmail),
      callback: function($$v) {
        _vm.deliveryByEmail = $$v
      },
      expression: "deliveryByEmail"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.t('enrollment.receive_paper_documents')) + "\n              ")])], 1)]), _vm._v(" "), (_vm.validateDocDeliveryMessage) ? _c('div', {
    staticClass: "u-input-validation u-danger u-pt2 u-pb2"
  }, [_vm._v("\n            " + _vm._s(_vm.validateDocDeliveryMessage) + "\n          ")]) : _vm._e()]) : _vm._e(), _vm._v(" "), (_vm.tos) ? _c('div', {
    staticClass: "enrollment-tos"
  }, [_c('el-checkbox', {
    attrs: {
      "data-cy": "accept-checkbox"
    },
    on: {
      "change": function($event) {
        return _vm.confirmTos(_vm.acceptedTOS)
      }
    },
    model: {
      value: (_vm.acceptedTOS),
      callback: function($$v) {
        _vm.acceptedTOS = $$v
      },
      expression: "acceptedTOS"
    }
  }, [_vm._v(_vm._s(_vm.t('enrollment.i_agree_to')))]), _vm._v(" "), _c('a', {
    staticClass: "enrollment-tos__highlighted",
    on: {
      "click": function($event) {
        _vm.dialogTermsOfService = true
      }
    }
  }, [_vm._v(_vm._s(_vm.t('enrollment.terms_of_service')))])], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "u-mt3",
    attrs: {
      "data-f7": "block"
    }
  }, [_c('el-button', {
    staticClass: "el-button--wide u-text-uppercase",
    attrs: {
      "type": "primary",
      "data-cy": "proceed",
      "data-test-id": "save-account-information",
      "disabled": _vm.tos && !_vm.acceptedTOS
    },
    on: {
      "click": _vm.verifyAndRegister
    }
  }, [_vm._v("\n            " + _vm._s(_vm.t('enrollment.save_account_information_button_caption')) + "\n          ")])], 1), _vm._v(" "), _c('el-dialog', {
    attrs: {
      "title": _vm.t('enrollment.terms_of_service'),
      "visible": _vm.dialogTermsOfService,
      "width": "50%",
      "close-on-press-escape": false,
      "close-on-click-modal": false,
      "custom-class": "tos-dialog"
    },
    on: {
      "update:visible": function($event) {
        _vm.dialogTermsOfService = $event
      }
    }
  }, [_c('div', [_c('terms-of-service', {
    ref: "terms",
    attrs: {
      "isEnrollmentTOS": true
    },
    on: {
      "handleEnrollmentDialogTOS": _vm.handleDialog
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    staticClass: "el-button--wide u-text-uppercase",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.agreeToTOS
    }
  }, [_vm._v(_vm._s(_vm.t('enrollment.agree_to_terms')))])], 1)]), _vm._v(" "), _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    attrs: {
      "title": _vm.t('account.user_action_required'),
      "visible": _vm.confirmationCodeDialogVisible,
      "before-close": _vm.hideConfirmationCodeDialog,
      "custom-class": "confirmation-code-dialog"
    },
    on: {
      "update:visible": function($event) {
        _vm.confirmationCodeDialogVisible = $event
      }
    }
  }, [_c('div', {
    staticClass: "el-message-box__message"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.t('enrollment.email_confirmation_sent', {
        new_email: _vm.authEmail
      }))
    }
  }), _vm._v(" "), _c('el-input', {
    staticClass: "u-pt3",
    attrs: {
      "type": "tel"
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.registerAndLoginInternal.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.emailConfirmationCode),
      callback: function($$v) {
        _vm.emailConfirmationCode = $$v
      },
      expression: "emailConfirmationCode"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "u-mt3"
  }, [(!_vm.canBypass) ? _c('a', {
    staticClass: "text-underline",
    class: _vm.resendAvailableIn > 0 ? 'non-interactive' : '',
    on: {
      "click": _vm.verifyAndRegister
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.t('enrollment.resend_code')) + "\n                  "), (_vm.resendAvailableIn > 0) ? _c('span', [_vm._v(_vm._s(_vm.t('enrollment.action_available_in', {
    due: _vm.resendAvailableIn
  })))]) : _vm._e()]) : _vm._e(), _vm._v(" "), (_vm.canBypass) ? _c('a', {
    staticClass: "text-underline",
    class: _vm.resendAvailableIn > 0 ? 'non-interactive' : '',
    on: {
      "click": _vm.continueWithoutConfirmation
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.t('enrollment.continue_without_confirmation')) + "\n                  "), (_vm.resendAvailableIn > 0) ? _c('span', [_vm._v(_vm._s(_vm.t('enrollment.action_available_in', {
    due: _vm.resendAvailableIn
  })))]) : _vm._e()]) : _vm._e()])], 1), _vm._v(" "), _c('div', {
    staticClass: "confirmation-code-dialog__footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "info",
      "size": "small"
    },
    on: {
      "click": _vm.hideConfirmationCodeDialog
    }
  }, [_vm._v(_vm._s(_vm.t('global.cancel')))]), _vm._v(" "), _c('el-button', {
    staticClass: "u-ml1",
    attrs: {
      "disabled": _vm.emailConfirmationCode.length === 0,
      "type": "primary",
      "size": "small"
    },
    on: {
      "click": _vm.registerAndLoginInternal
    }
  }, [_vm._v(_vm._s(_vm.t('global.confirm')))])], 1)]), _vm._v(" "), _c('el-dialog', {
    attrs: {
      "title": _vm.t('account.user_action_required'),
      "visible": _vm.dialogVisible,
      "width": "50%",
      "show-close": false,
      "close-on-press-escape": false,
      "close-on-click-modal": false,
      "custom-class": "change-email-dialog"
    },
    on: {
      "update:visible": function($event) {
        _vm.dialogVisible = $event
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.t('account.user_action_required_email_text')))]), _vm._v(" "), _c('span', {
    staticClass: "dialog-footer emails-list-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_vm._l((_vm.dialogButtons), function(dialogButton) {
    return _c('div', {
      key: dialogButton.text,
      staticClass: "u-mt2"
    }, [_c('el-button', {
      attrs: {
        "type": "info"
      },
      on: {
        "click": dialogButton.onClick
      }
    }, [_vm._v(_vm._s(dialogButton.text))])], 1)
  }), _vm._v(" "), _c('div', {
    staticClass: "dialog-footer__cancel u-mt3"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function($event) {
        _vm.dialogVisible = false
      }
    }
  }, [_vm._v(_vm._s(_vm.t('documents.documents_popover_menu_cancel')))])], 1)], 2)]), _vm._v(" "), _c('dialog-info', {
    attrs: {
      "visibleDialogInfo": _vm.visibleDialogInfo,
      "isVisibleCloseIcon": false,
      "title": _vm.t('e_delivery.acknowledge_info_title'),
      "description": _vm.t('e_delivery.acknowledge_info')
    },
    on: {
      "userApproved": _vm.userApprovedDeliveryType,
      "handleCloseDialog": _vm.handleCloseDialog
    }
  })], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "init-screen__logo-language"
  }, [_c('bc-footer', {
    attrs: {
      "bgClass": "bg-login"
    }
  }), _vm._v(" "), (_vm.displayLanguageLink) ? _c('div', {
    staticClass: "init-screen__bc-language"
  }, [_c('ba-language-selector')], 1) : _vm._e()], 1)], 1)
},staticRenderFns: []}