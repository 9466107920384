import config from '../../config/britecore'
import axios from 'axios'
import _ from 'lodash'
import {axiosWrapper} from '@shared_src/utils/misc.util'

const accountModule = {
  namespaced: true,
  state: {
    accountData: null
  },
  mutations: {
    setAccountData (state, payload) {
      state.accountData = payload
    },
  },
  actions: {
    loadAccount: function getAccountDataPromise (context) {
      return new Promise((resolve, reject) => {
        axios.post(config.get_account_information, {token: localStorage.token})
        .then(response => {
          context.commit('setAccountData', response.data.data)
          context.commit('enrollment/setTos', response.data.data.tos, { root: true })
          resolve()
        })
        .catch(error => {
          console.error('get_account_information failed', error)
          reject()
        })
      })
    },
    updateDeliveryType: function (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(config.update_delivery_type, {
          edelivery: payload,
          token: localStorage.token
        })
        .then(response => {
          let responseData = response.data
          if (responseData.success) {
            resolve(responseData.data)
          }
          reject(response)
        })
        .catch(error => {
          reject(error)
        })
      })
    },
    async validateUsername (context, payload) {
      let dataToSend = {
        username: payload.username
      }
      if (payload.companyId) {
        dataToSend.company_id = payload.companyId
      } else {
        dataToSend.token = localStorage.token
      }
      return await axiosWrapper(
        axios.post(config.validate_username, dataToSend)
      )
    },
    async updateUsername (context, username) {
      return await axiosWrapper(
        axios.post(
          config.change_username,
          {
            token: localStorage.token,
            username
          }
        )
      )
    },
    async updatePin (context, pin) {
      let response = await axios.post(config.update_pin, {
        new_pin: pin,
        token: localStorage.token
      })
      if (response && response.data.success) {
        return response.data
      }
      return Promise.reject(response.data)
    },
    async updateConsentToText (context, consent) {
      let response = await axios.post(config.update_consent, {
        consent_to_text: consent,
        token: localStorage.token
      })
      if (response && response.data.success) {
        return response.data
      }
      return Promise.reject(response.data)
    },
    async getConfirmationCodeForAccountRemoval (context) {
      return await axiosWrapper(
        axios.get(
          config.get_confirmation_code_for_account_removal_url,
          {headers: {Authorization: localStorage.token}}
        )
      )
    },
    async performAccountRemoval (context, confirmationCode) {
      return await axiosWrapper(
        axios.post(
          config.perform_account_removal_url,
          {
            confirmation_code: confirmationCode
          },
          {
            headers: {Authorization: localStorage.token},
          }
        )
      )
    },
  },
  getters: {
    username: state => {
      if (_.isEmpty(state.accountData)) {
        return ''
      }
      return state.accountData.username
    },
    tos: state => {
      if (_.isEmpty(state.accountData)) {
        return ''
      }
      return state.accountData.tos
    },
    account: state => {
      return state.accountData
    },
    insuredIds: state => {
      if (!state.accountData) {
        return []
      }
      let result = []
      let insureds = state.accountData.insureds
      for (let insuredId in insureds) {
        if (!insureds.hasOwnProperty(insuredId)) {
          continue
        }
        result.push(insuredId)
      }
      return result
    },
  }
}

export default accountModule
