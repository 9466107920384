<template>
  <div class="submenu">
    <div class="submenu__menu-outer">
      <ba-add-missing-policy v-if="screenType === 'policies'" class="u-mt1"/>
      <div v-if="policiesListByType" class="submenu__nested-menu u-mt4">
        <div v-for="(type, index) in getPoliciesTypes" :key="index">
          <h2 v-if="policyTitle(policiesListByType, type).length" class="u-mb3 submenu__link-type-title">
            {{ policyTitle(policiesListByType, type) }}
          </h2>
          <div class="submenu__block-links u-mb2">
            <router-link v-for="policy in policiesListByType[type]" :key="policy.id" :to="generateMenuLink(screenType, policy.id)"
                         :class="[linkActiveClass(policy.id), 'submenu__link u-block']">
              <div class="u-p1">
                <el-popover
                      placement="top-start"
                      title=""
                      width="200"
                      :open-delay="200"
                      trigger="hover"
                      :content="policyShortHeadline(policy)">
                  <span slot="reference">
                    <!--popover shows only policyLine1Heading (for gen2 it's address line). This helps if policyLine1Heading is long-->
                    <div class="submenu__link-line-1 u-text-trim">
                      <fa-icon :icon="determinePolicyIcon(policy)" :class="'link-icon-medium'" />
                      <div class="submenu__link_bold u-pl1 u-inline">{{ policyShortHeadline(policy) }}</div>
                    </div>
                  </span>
                </el-popover>
                <div>{{  policy.policy_number }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="submenu__view">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>
import BaAddMissingPolicy from '../pages/add-missing-policy/add-missing-policy.modal'

import {mapGetters} from 'vuex'

export default {
  components: {
    BaAddMissingPolicy,
  },
  name: 'policy-list',
  props: {
    policiesListByType: {
      type: Object,
      required: true,
    },
    screenType: {
      type: String,
      required: true,
    }
  },
  computed: {
    ...mapGetters('policies', ['determinePolicyIcon', 'policyShortHeadline']),
    getPoliciesTypes () {
      return Object.keys(this.policiesListByType)
    },
  },
  methods: {
    generateMenuLink (screenType, policyId) {
      let link = `/${screenType}/${policyId}`
      return (screenType === 'documents') ? link + '/folders/root' : link
    },
    linkActiveClass (policyId) {
      const boundRouteList = [ '/document-view', '/documents' ]
      const url = decodeURIComponent(this.$route.fullPath)
      let res = ''
      boundRouteList.reduce((prev, item) => {
        if (url.includes(item) && url.includes(policyId)) {
          res = 'router-link-exact-active'
        }
      }, '')
      return res
    },
    policyTitle (data, type) {
      if (type === 'active' && data[type].length > 0) {
        return this.t('policies.active')
      }
      if (type === 'canceled' && data[type].length > 0) {
        return this.t('policies.canceled')
      }
      return ''
    }
  }
}
</script>

<style>

</style>
