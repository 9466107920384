<template>
  <div class="back-account-details">
    <div form class="inputs-list no-hairlines">

      <span class="submenu__link_bold u-mb2 u-block">{{ t('payments.payment_method_billing_address_header') }}</span>

      <div v-if="selectorAddresses.length">
        <span class="subheader u-block">{{ t('payments.available_addresses_label') }}</span>
        <el-select class="item-input-field" type="select" v-model="preselectedAddressId" @change="onPreselectedAddressChange">
          <el-option v-for="(address, index) in selectorAddresses" :value="address.id" :key="address.id" :label="getAddressLabel(address)"></el-option>
        </el-select>
      </div>
      </div>

      <div class="u-mt3">
        <span class="subheader u-block">{{ t('global.address_line_1') }} *</span>
        <el-input class="item-input-field" type="text" v-model="address_line1" required />
      </div>
      <div v-if="validateInput.address_line1" class="u-warning">
        <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInput.address_line1 }}
      </div>

      <div class="u-mt3">
        <span class="subheader u-block">{{ t('global.city') }} *</span>
        <el-input class="item-input-field" v-model="city"/>
      </div>
      <div v-if="validateInput.city" class="u-warning">
        <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInput.city }}
      </div>

      <div class="u-mt3">
        <span class="subheader u-block">{{ t('global.state') }} *</span>
        <el-select class="item-input-field" type="select" v-model="state">
          <el-option v-for="(_, index) in usStates" :value="_" :key="index"></el-option>
        </el-select>
      </div>
      <div v-if="validateInput.state" class="u-warning">
        <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInput.state }}
      </div>

      <div class="u-mt3">
        <span class="subheader u-block">{{ t('global.zip_code') }} *</span>
        <el-input class="item-input-field" v-model="zip_code"/>
      </div>
      <div v-if="validateInput.zip_code" class="u-warning">
        <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateInput.zip_code }}
      </div>
    </div>
  </div>

</template>

<script>

import {deepClone} from '@shared_src/utils/misc.util'
import UsaStates from 'usa-states'

export default {
  name: 'bc-billing-address',
  props: ['addresses'],
  data () {
    return {
      address_line1: '',
      city: '',
      state: '',
      zip_code: '',
      preselectedAddressId: '',
      validateInput: {
        address_line1: null,
        city: null,
        state: null,
        zip_code: null,
      }
    }
  },
  computed: {
    usStates () {
      try {
        let usStates = new UsaStates.UsaStates()
        return usStates.states.map(state => state.abbreviation)
      } catch (err) {
        console.error(err)
      }
      return []
    },
    isValidInput () {
      return {
        address_line1: (!this.address_line1.trim()) ? this.t('enrollment.address_not_defined_notification') : null,
        city: (!this.city.trim()) ? this.t('enrollment.city_not_defined_notification') : null,
        state: (!this.state.trim()) ? this.t('enrollment.state_not_defined_notification') : null,
        zip_code: (!this.zip_code.trim()) ? this.t('enrollment.zip_not_defined_notification') : null,
      }
    },
    validateInputValues () {
      return Object.keys(this.isValidInput).every(key => !this.isValidInput[key])
    },
    selectorAddresses () {
      if (!(this.addresses && this.addresses.length)) return []
      let addresses = [{
        id: '',
        address_line1: '',
        address_city: '',
        address_state: '',
        address_zip: '',
      }]
      addresses = addresses.concat(this.addresses)
      return addresses
    }
  },
  methods: {
    onPreselectedAddressChange () {
      let selectedAddress = this.selectorAddresses.find(address => address.id === this.preselectedAddressId)
      this.address_line1 = selectedAddress.address_line1
      this.city = selectedAddress.address_city
      this.state = selectedAddress.address_state
      this.zip_code = selectedAddress.address_zip
    },
    getBillingAddressData () {
      this.validateInput = deepClone(this.isValidInput)
      if (!this.validateInputValues) {
        return
      }
      return {
        address_line1: this.address_line1,
        address_city: this.city,
        address_state: this.state,
        address_zip: this.zip_code,
      }
    },
    getAddressLabel (addr) {
      if (addr.id === '') return this.t('payments.billing_new_address_option')

      let res = `${addr.type}: ${addr.address_line1}`
      if (addr.address_line2) res += ` ${addr.address_line2}`
      res += ` - ${addr.address_city}, ${addr.address_state} ${addr.address_zip}`
      return res
    },
    clearFields () {
      this.address_line1 = ''
      this.city = ''
      this.state = ''
      this.zip_code = ''
      this.preselectedAddressId = ''
      this.validateInput = {
        address_line1: null,
        city: null,
        state: null,
        zip_code: null,
      }
    },
  },
}
</script>
