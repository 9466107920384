module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return (_vm.policiesListByType) ? _c('div', [(!_vm.useExternalPaymentSite) ? _c('div', {
    staticClass: "payments-card dashboard__outer-card_info"
  }, [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (!_vm.computePoliciesByType),
      expression: "!computePoliciesByType"
    }],
    staticClass: "el-card__header"
  }, [_vm._v(_vm._s(_vm.t('dashboard.payments')))]), _vm._v(" "), (!_vm.useExternalPaymentSite && _vm.computePoliciesByType) ? _c('h2', {
    staticClass: "u-flex-auto u-mb5"
  }, [_c('div', {
    staticClass: "u-flex-align-items",
    class: _vm.paymentCard.headlineClass
  }, [(_vm.paymentCard.headlineClass === 'u-danger') ? _c('fa-icon', {
    staticClass: "image-icon",
    attrs: {
      "icon": ['fal', 'exclamation-circle']
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "u-text-trim"
  }, [_vm._v(_vm._s(_vm.paymentCard.headline))])], 1), _vm._v(" "), (_vm.paymentCard.headline2) ? _c('div', {
    staticClass: "u-flex-align-items headline2"
  }, [_c('div', {
    staticClass: "u-text-trim"
  }, [_vm._v(_vm._s(_vm.paymentCard.headline2))])]) : _vm._e(), _vm._v(" "), (_vm.paymentCard.description) ? _c('router-link', {
    staticClass: "el-card__sub-link subheader u-mt2",
    attrs: {
      "to": "/payments"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.paymentCard.description) + "\n      ")]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), (!_vm.useExternalPaymentSite && _vm.computePoliciesByType && _vm.numPoliciesThatAllowPayments > 0) ? _c('div', {
    staticClass: "payments-card__links payment-card-links u-mt4"
  }, [_c('el-button', {
    staticClass: "u-text-capitalize u-width100",
    attrs: {
      "type": "info"
    },
    on: {
      "click": _vm.processPayments
    }
  }, [_vm._v("\n        " + _vm._s(_vm.paymentCard.actionText) + "\n      ")])], 1) : _vm._e()]) : _c('div', {
    staticClass: "payments-card dashboard__outer-card_info"
  }, [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (!_vm.computePoliciesByType),
      expression: "!computePoliciesByType"
    }],
    staticClass: "el-card__header"
  }, [_vm._v(_vm._s(_vm.t('dashboard.payments')))]), _vm._v(" "), _c('h2', {
    staticClass: "u-mt0 u-mb5"
  }, [_vm._v(_vm._s(_vm.t('payments.use_external_payment_site_dashboard_label')))]), _vm._v(" "), _c('div', {
    staticClass: "payments-card__links payment-card-links u-mt4"
  }, [_c('el-button', {
    staticClass: "u-text-capitalize u-width100",
    attrs: {
      "type": "info"
    },
    on: {
      "click": function($event) {
        return _vm.openExternalSite()
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.t('dashboard.payments')) + "\n      ")])], 1)]), _vm._v(" "), _c('bc-payment-dialog', {
    attrs: {
      "openPaymentDialog": _vm.openPaymentDialog,
      "checkedPolicies": _vm.policiesForPay,
      "activePayType": _vm.activePayType
    },
    on: {
      "handleMakePaymentsDialog": _vm.handleMakePaymentsDialog
    }
  })], 1) : _vm._e()
},staticRenderFns: []}