module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "array-field"
  }, [(_vm.field.label != null) ? _c('div', {
    staticClass: "u-color-secondary u-mt1 u-text-trim"
  }, [_vm._v(_vm._s(_vm.t(_vm.field.label)) + " (" + _vm._s(_vm.field.value.length) + ")")]) : _vm._e(), _vm._v(" "), _vm._l((_vm.field.value), function(x, index) {
    return _c('div', {
      key: index,
      staticClass: "u-mt2 u-text-multiline-trim"
    }, [_vm._v("\n    " + _vm._s(x.value) + "\n    "), (x.icon) ? _c('i', {
      staticClass: "fa u-color-primary",
      class: 'fa-' + x.icon
    }) : _vm._e()])
  })], 2)
},staticRenderFns: []}