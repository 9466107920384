<template>
  <div class="language-selector">
    <el-popover
      placement="bottom-start"
      width="110"
      trigger="click"
      v-model="visible"
    >
      <div v-if="availableLanguages">
        <div v-for="(lang, index) in availableLanguages" :key="index">
          <div class="language-selector__data" @click="changeLanguage(lang)">
            <div class="u-inline-block iti__flag" :class="flagClass(lang)"></div>
            <span>{{ lang | capitalize }}</span>
          </div>
        </div>
      </div>
      <el-button slot="reference" @click="visible != visible" type="info" class="language-selector__btn">
        <div class="language-selector__content">
          <div>
            <div class="u-inline-block iti__flag" :class="flagClass(selectedLanguage)"></div>
            <span class="language-selector__btn--text">{{ selectedLanguage | capitalize }}</span>
          </div>
          <div class="language-selector__btn--text u-pl1">
            <fa-icon :icon="['fas', 'caret-down']" :class="visible ? 'u-rotate-180' : ''"/>
          </div>
        </div>
      </el-button>
  </el-popover>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'

  export default {
    name: 'ba-language-selector',
    props: ['notifyBackend'],
    data () {
      return {
        visible: false
      }
    },
    methods: {
      ...mapMutations('common', ['setLanguage']),
      ...mapActions('common', ['savePreferredLanguage']),
      flagClass (lang) {
        // this classes are borrowed from intl-tel-input lib. Flags stored in /static/flags.
        switch (lang) {
        case 'english':
          return 'iti__us'
        case 'spanish':
          return 'iti__es'
        }
        console.error('Unknown language', lang)
      },
      changeLanguage (selected) {
        this.visible = false
        if (selected !== this.applicationLanguage) {
          this.setLanguage(selected)
          if (this.notifyBackend) {
            this.savePreferredLanguage(selected)
          }
        }
      }
    },
    computed: {
      ...mapState('common', ['applicationLanguage']),
      ...mapGetters('common', ['availableLanguages']),
      selectedLanguage: {
        get () {
          return this.applicationLanguage
        },
        set (selected) {
          this.changeLanguage(selected)
        },
      },
    },
  }
</script>

<style lang="scss">

  .language-selector {
    width: 125px;
    &__data {
      height: 34px;
      line-height: 34px;
      cursor: pointer;
    }
    &__icon {
      font-size: 15px;
      font-weight: 800;
    }
    &__content {
      display: flex;
      justify-content: space-between;
    }
    &__btn, &__btn:hover {
      border-color: #dcdfe6 !important;
      color: #606266 !important;
      & > span > span {
        display: inline-block;
        width: 100%;
      }
    }

    &__btn--text {
      font-size: 14px;
      font-weight: 500;
    }
  }
</style>
