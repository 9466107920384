<template>
  <div>
    <bc-navbar :title="t('account.my_account')" class="u-mb4"></bc-navbar>
    <div class="my-account">
      <div class="my-account__menu-outer">
        <div class="my-account__nested-menu">
          <div>
            <router-link to="/account/settings" class="my-account__link">
              {{ t('account.account_settings') }}
            </router-link>
          </div>
          <div>
            <router-link to="/account/phone-numbers" class="my-account__link">
              {{ t('account.phone_numbers') }}
            </router-link>
          </div>
          <div>
            <router-link to="/account/emails" class="my-account__link">
              {{ t('global.emails_label') }}
            </router-link>
          </div>
          <div>
            <router-link to="/account/change-password" class="my-account__link">
              {{ t('account.change_password') }}
            </router-link>
          </div>
          <div v-if="isEnabledElectronicDeliveryOption">
            <router-link to="/account/document-delivery" class="my-account__link">
              {{ t('enrollment.document_preferences') }}
            </router-link>
          </div>
          <div>
            <router-link to="/account/delete-my-account" class="my-account__link"
               v-html="t('account.delete_my_account_title')">
            </router-link>
          </div>
        </div>
      </div>
      <div class="my-account__view">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
export default {
  name: 'overview',
  computed: {
    isEnabledElectronicDeliveryOption () {
      return this.setting('global.electronic_delivery_option')
    },
    ...mapGetters('common', ['isSingleContactAccount']),
    ...mapState('common', ['contacts']),
  }
}
</script>

<style lang="scss">
  @import '../../sass/_variables.scss';
  
  .my-account {
    display: flex;
    padding-top: 20px;
    border-top: 2px solid #E5E5E5;
    
    @media only screen and (max-width: $l-screen) {
      flex-direction: column;
    }
    
    &__menu-outer {
      padding-right: 80px;
      
      @media only screen and (max-width: $l-screen) {
        padding-right: 0;
      }
    }
    &__nested-menu {
      display: block;
      width: 200px;
      
      @media only screen and (max-width: $l-screen) {
        display: flex;
        width: 100%;
        overflow-x: scroll;
      }
      .router-link-exact-active {
        color: $color-primary !important;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          border-left: 4px solid $color-primary;
          height: 40px;
  
          @media only screen and (max-width: $l-screen) {
            border-left: none;
            border-bottom: 4px solid $color-primary;
            bottom: 0;
            width: 100%;
          }
        }
      }
    }
    &__link {
      display: inline-block;
      white-space: nowrap;
      position: relative;
      padding-left: 15px;
      line-height: 40px;
      margin-bottom: 10px;
      font-weight: 500;
      color: #626262 !important;
      font-size: 16px !important;
      text-decoration: none !important;
  
      @media only screen and (max-width: $l-screen) {
        padding-left: 0;
        margin-left: 15px;
      }
      
    }
    &__view {
      width: 520px;
      
      @media only screen and (max-width: $m-screen) {
        width: 100%;
      }
    }
  }
</style>
