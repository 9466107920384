module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "root payment-method u-flex u-flex-horizontal-center u-flex-align-items u-py2 u-pl2 u-mt2 u-pr3"
  }, [_c('div', {
    staticClass: "payment-method_icon-box"
  }, [_c('fa-icon', {
    attrs: {
      "size": "2x",
      "icon": _vm.currentIcon
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "u-text-collapse-line-height"
  }, [_vm._v(_vm._s(_vm.title))]), _vm._v(" "), (_vm.expires) ? _c('div', {
    staticClass: "subheader u-ml3 u-mt0 u-mb0"
  }, [_vm._v("\n    " + _vm._s(_vm.expires) + "\n  ")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "payment-method__remove"
  }, [_c('el-button', {
    staticClass: "payment-method__remove-button",
    attrs: {
      "icon": ['fas', 'trash'],
      "size": "small"
    },
    on: {
      "click": function($event) {
        return _vm.$emit('willDelete', _vm.paymentMethod)
      }
    }
  })], 1)])
},staticRenderFns: []}