module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }]
  }, [_c('div', {
    staticClass: "file-claim-forms"
  }, [_c('div', {
    staticClass: "file-claim-forms__fields"
  }, [(_vm.selectedPolicy.gen2_auto) ? _c('div', [_c('el-form', {
    staticClass: "u-max-width u-mt6"
  }, [_c('div', {
    staticClass: "inputs-list u-mb3"
  }, [(_vm.selectedPolicy) ? _c('div', {
    staticClass: "list-item--select"
  }, [_c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('auto.vehicles')) + " *")]), _vm._v(" "), _c('el-select', {
    staticClass: "item-input-field u-block",
    on: {
      "change": function($event) {
        return _vm.showNonOwnedMessage()
      }
    },
    model: {
      value: (_vm.selectedPropertyId),
      callback: function($$v) {
        _vm.selectedPropertyId = $$v
      },
      expression: "selectedPropertyId"
    }
  }, [_c('el-option', {
    attrs: {
      "value": "-1",
      "label": _vm.t('auto.non-owned_vehicle')
    }
  }), _vm._v(" "), _vm._l((_vm.selectedPolicy.properties), function(property) {
    return _c('el-option', {
      key: property.id,
      attrs: {
        "value": property.id,
        "label": property.vehicle_year + ' ' + property.vehicle_make + ' ' + property.vehicle_model
      }
    })
  })], 2)], 1) : _vm._e(), _vm._v(" "), (_vm.validateInputValuesDictCopy.vehicle) ? _c('div', {
    staticClass: "u-warning u-mb4"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.vehicle) + "\n            ")]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "inputs-list u-mb3"
  }, [_c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('auto.accident_address_heading')) + " *")]), _vm._v(" "), _c('el-select', {
    staticClass: "item-input-field u-block",
    on: {
      "change": function($event) {
        return _vm.showAddressFields()
      }
    },
    model: {
      value: (_vm.selectedAddress),
      callback: function($$v) {
        _vm.selectedAddress = $$v
      },
      expression: "selectedAddress"
    }
  }, _vm._l((_vm.addresses), function(address) {
    return _c('el-option', {
      key: address.value,
      attrs: {
        "value": address.value,
        "label": address.label
      }
    })
  }), 1), _vm._v(" "), (_vm.addressFieldsVisible) ? _c('div', [_c('el-input', {
    staticClass: "u-block u-mb1",
    attrs: {
      "type": "text",
      "placeholder": _vm.t('auto.street'),
      "disabled": _vm.isCanceledCurrentPolicy
    },
    model: {
      value: (_vm.addressStreet),
      callback: function($$v) {
        _vm.addressStreet = $$v
      },
      expression: "addressStreet"
    }
  }), _vm._v(" "), _c('el-input', {
    staticClass: "u-block u-mb1",
    attrs: {
      "type": "text",
      "placeholder": _vm.t('auto.cross_street'),
      "disabled": _vm.isCanceledCurrentPolicy
    },
    model: {
      value: (_vm.addressCross),
      callback: function($$v) {
        _vm.addressCross = $$v
      },
      expression: "addressCross"
    }
  }), _vm._v(" "), _c('el-input', {
    staticClass: "u-block u-mb1",
    attrs: {
      "type": "text",
      "placeholder": _vm.t('global.city'),
      "disabled": _vm.isCanceledCurrentPolicy
    },
    model: {
      value: (_vm.addressCity),
      callback: function($$v) {
        _vm.addressCity = $$v
      },
      expression: "addressCity"
    }
  }), _vm._v(" "), _c('el-select', {
    staticClass: "item-input-field u-mb1 u-mr3 address-state",
    attrs: {
      "type": "select",
      "placeholder": _vm.t('global.state'),
      "disabled": _vm.isCanceledCurrentPolicy
    },
    model: {
      value: (_vm.addressState),
      callback: function($$v) {
        _vm.addressState = $$v
      },
      expression: "addressState"
    }
  }, _vm._l((_vm.usStates), function(_, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "value": _
      }
    })
  }), 1), _vm._v(" "), _c('el-input', {
    staticClass: "u-mb1 address-zip",
    attrs: {
      "type": "text",
      "placeholder": _vm.t('global.zip_code'),
      "disabled": _vm.isCanceledCurrentPolicy
    },
    model: {
      value: (_vm.addressZip),
      callback: function($$v) {
        _vm.addressZip = $$v
      },
      expression: "addressZip"
    }
  })], 1) : _vm._e(), _vm._v(" "), (_vm.validateInputValuesDictCopy.address) ? _c('div', {
    staticClass: "u-warning"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.address) + "\n            ")]) : _vm._e()], 1)])], 1) : _c('el-form', {
    staticClass: "u-max-width u-mt6"
  }, [_c('div', {
    staticClass: "inputs-list u-mb3"
  }, [(_vm.selectedPolicy) ? _c('div', {
    staticClass: "list-item--select"
  }, [_c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('claims.location_title')) + " *")]), _vm._v(" "), _c('el-select', {
    staticClass: "item-input-field u-block",
    attrs: {
      "disabled": _vm.selectedPolicy.properties.length === 1
    },
    model: {
      value: (_vm.selectedPropertyId),
      callback: function($$v) {
        _vm.selectedPropertyId = $$v
      },
      expression: "selectedPropertyId"
    }
  }, [_c('el-option', {
    attrs: {
      "value": "",
      "disabled": "",
      "hidden": ""
    }
  }), _vm._v(" "), _vm._l((_vm.selectedPolicy.properties), function(property) {
    return _c('el-option', {
      key: property.id,
      attrs: {
        "value": property.id,
        "label": _vm.formCleanAddress(property)
      }
    })
  })], 2)], 1) : _vm._e()])]), _vm._v(" "), _c('el-form', {
    staticClass: "u-max-width"
  }, [_c('div', {
    staticClass: "inputs-list u-mb3"
  }, [_c('div', [_c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('claims.loss_date_title')) + " *")]), _vm._v(" "), _c('el-date-picker', {
    staticClass: "u-width100 loss-date",
    attrs: {
      "format": "MM/dd/yyyy",
      "picker-options": {
        disabledDate: _vm.isDateAfterToday
      },
      "disabled": _vm.isCanceledCurrentPolicy,
      "clearable": false
    },
    model: {
      value: (_vm.date),
      callback: function($$v) {
        _vm.date = $$v
      },
      expression: "date"
    }
  })], 1), _vm._v(" "), (_vm.validateInputValuesDictCopy.lossDate) ? _c('div', {
    staticClass: "u-warning u-mb4"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.lossDate) + "\n            ")]) : _vm._e()])]), _vm._v(" "), _c('el-form', {
    staticClass: "u-max-width"
  }, [_c('div', {
    staticClass: "inputs-list"
  }, [_c('div', {
    staticClass: "ba-intl-tel-input__wrapper"
  }, [(_vm.phoneNumberRequired) ? _c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('global.phone_number')) + " *")]) : _c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('global.phone_number')))]), _vm._v(" "), _c('ba-intl-tel-input', {
    class: _vm.validateInputValuesDictCopy.phoneNumber ? 'phone-number-u-warning' : '',
    attrs: {
      "customClass": "el-input__inner",
      "disabled": _vm.isCanceledCurrentPolicy
    },
    nativeOn: {
      "keyup": function($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.fileClaimOperation.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.contactPhoneNumber),
      callback: function($$v) {
        _vm.contactPhoneNumber = $$v
      },
      expression: "contactPhoneNumber"
    }
  })], 1), _vm._v(" "), (_vm.validateInputValuesDictCopy.phoneNumber) ? _c('div', {
    staticClass: "u-warning"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.phoneNumber) + "\n          ")]) : _vm._e()])])], 1), _vm._v(" "), (!_vm.isCanceledCurrentPolicy) ? _c('drag-n-drop-photo', {
    on: {
      "onPhotoUpload": _vm.onPhotoUpload
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('el-form', {
    staticClass: "u-max-width"
  }, [_c('div', {
    staticClass: "inputs-list u-mt4 u-mb3"
  }, [_c('div', [_c('span', {
    staticClass: "u-block u-text-uppercase subheader"
  }, [_vm._v(_vm._s(_vm.t('claims.loss_description_title')) + " *")]), _vm._v(" "), _c('el-input', {
    staticClass: "u-block",
    attrs: {
      "type": "textarea",
      "disabled": _vm.isCanceledCurrentPolicy,
      "resizable": ""
    },
    model: {
      value: (_vm.description),
      callback: function($$v) {
        _vm.description = $$v
      },
      expression: "description"
    }
  })], 1), _vm._v(" "), (_vm.validateInputValuesDictCopy.description) ? _c('div', {
    staticClass: "u-warning"
  }, [_c('i', {
    staticClass: "material-icons md-text",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("warning")]), _vm._v(" " + _vm._s(_vm.validateInputValuesDictCopy.description) + "\n      ")]) : _vm._e()])]), _vm._v(" "), (_vm.chosenPhotos.length) ? _c('div', [_c('el-row', {
    attrs: {
      "gutter": 10
    }
  }, _vm._l((_vm.chosenPhotos), function(photo, pos) {
    return _c('el-col', {
      key: pos,
      attrs: {
        "md": 8,
        "sm": 24
      }
    }, [_c('el-card', {
      staticClass: "el-card__claim-image-preview u-mb1 u-p0"
    }, [_c('img', {
      staticClass: "claim-image-thumbnail",
      attrs: {
        "src": photo.image
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "u-flex-justify-content-end u-m1"
    }, [_c('el-button', {
      attrs: {
        "type": "info",
        "size": "small",
        "icon": ['fas', 'trash']
      },
      on: {
        "click": function($event) {
          return _vm.deletePhoto(pos)
        }
      }
    })], 1)])], 1)
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "u-mt4 u-text-right"
  }, [_c('el-button', {
    staticClass: "u-text-uppercase",
    attrs: {
      "type": "primary",
      "disabled": _vm.selectedPolicy === null || _vm.isCanceledCurrentPolicy
    },
    on: {
      "click": _vm.fileClaimOperation
    }
  }, [_vm._v("\n        " + _vm._s(_vm.t('claims.submit_claim_buttom_caption')) + "\n      ")])], 1), _vm._v(" "), (_vm.invalidateHack) ? _c('div') : _vm._e(), _vm._v(" "), _c('el-dialog', {
    staticClass: "u-mt5",
    attrs: {
      "title": _vm.t('auto.non_owned_heading'),
      "visible": _vm.dialogVisible,
      "modal-append-to-body": true,
      "center": true,
      "close-on-click-modal": false,
      "append-to-body": true,
      "show-close": false,
      "width": "25%"
    },
    on: {
      "update:visible": function($event) {
        _vm.dialogVisible = $event
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.t('auto.non_owned_message')))]), _vm._v(" "), _c('span', {
    staticClass: "non-owned-dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function($event) {
        _vm.dialogVisible = false
      }
    }
  }, [_vm._v(_vm._s(_vm.t('global.dismiss')))])], 1)])], 1)
},staticRenderFns: []}