module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [(!_vm.riskData) ? _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (true),
      expression: "true"
    }],
    staticClass: "u-mt4 u-mb0 u-text-center"
  }, [_c('h2', [_vm._v(_vm._s(_vm.t('global.loading')))])]) : _vm._e(), _vm._v(" "), _vm._l((_vm.riskData), function(section) {
    return _c('div', {
      key: section.id
    }, [_c('div', {
      staticClass: "u-mt4 u-mb0"
    }, [(_vm.showHeader(section)) ? _c('h2', {
      staticClass: "secondary-header"
    }, [_vm._v(_vm._s(_vm.t(section.heading)) + " (" + _vm._s(section.items.length) + ")")]) : _vm._e(), _vm._v(" "), _c('el-row', {
      attrs: {
        "gutter": 30
      }
    }, _vm._l((section.items), function(riskComponent, index) {
      return _c('el-col', {
        key: index,
        attrs: {
          "xs": 24,
          "span": _vm.itemsPerRow(section) * 2
        }
      }, [_c('el-card', {
        attrs: {
          "shadow": "never"
        }
      }, [_c('div', {
        staticClass: "u-mb2"
      }, [_c('ba-risk-icon', {
        staticClass: "u-mr2 u-color-secondary",
        attrs: {
          "risk-component": riskComponent
        }
      }), _vm._v("\n              " + _vm._s(riskComponent.name) + "\n            ")], 1), _vm._v(" "), (riskComponent.fields) ? _c('ba-risk-repeated-fields', {
        attrs: {
          "fields": riskComponent.fields,
          "riskType": riskComponent.type
        }
      }) : _vm._e()], 1)], 1)
    }), 1)], 1)])
  })], 2)
},staticRenderFns: []}