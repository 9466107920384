<template>
  <div class="pages">
    <span class="centered-text">{{ t('account.account_is_blocked_page_text') }}</span>
      <el-button :type="info" class="centered" @click="onReturnToLoginPageClick">Return to login page</el-button>
  </div>
</template>

<script>

  import {mapMutations} from 'vuex'
  import {deleteCookie} from '@shared_src/utils/api.utils'

  export default {
    name: 'Account Blocked',
    methods: {
      ...mapMutations('login', ['removeTokenAndLoginTime']),
      onReturnToLoginPageClick () {
        this.removeTokenAndLoginTime()
        localStorage.removeItem('last_location')
        deleteCookie('user_online')
        this.$router.replace('/login')
      }
    }
  }
</script>

<style scoped>
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -50px;
    color: black;
    text-align: center;
  }
</style>
