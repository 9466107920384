module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return (_vm.orderedPolicies) ? _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }]
  }, [_c('div', {
    staticClass: "make-payments__page u-mb4"
  }, [_c('bc-navbar', {
    staticClass: "u-m0",
    attrs: {
      "title": _vm.t('payments.make_payments')
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "make-payments__btn"
  }, [_c('el-button', {
    staticClass: "u-width100 u-text-uppercase",
    attrs: {
      "disabled": !_vm.checkedPolicies.length,
      "type": "primary"
    },
    on: {
      "click": function($event) {
        return _vm.handleMakePaymentsDialog(true)
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.t('payments.make_payment')) + "\n      ")])], 1)], 1), _vm._v(" "), _vm._l((_vm.orderedPolicies), function(policy) {
    return _c('div', {
      key: policy.id,
      staticClass: "u-mb3"
    }, [_c('div', {
      staticClass: "card-outer",
      class: ("card-outer__badge_" + (_vm.badgeType(policy))),
      on: {
        "click": function($event) {
          return _vm.processPolicy(policy)
        }
      }
    }, [(!policy.is_canceled) ? _c('div', {
      staticClass: "card-content-inner"
    }, [_c('el-row', {
      attrs: {
        "gutter": 10
      }
    }, [(_vm.isAllowedToBePaid(policy)) ? _c('el-col', {
      attrs: {
        "xs": 4,
        "sm": 1
      }
    }, [_c('div', {
      staticClass: "checkbox"
    }, [_c('div', {
      staticClass: "payments__custom-checkbox u-mt2"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: (_vm.checkedPolicies),
        expression: "checkedPolicies"
      }],
      attrs: {
        "type": "checkbox",
        "disabled": !_vm.isAllowedToBePaid(policy)
      },
      domProps: {
        "value": policy,
        "checked": Array.isArray(_vm.checkedPolicies) ? _vm._i(_vm.checkedPolicies, policy) > -1 : (_vm.checkedPolicies)
      },
      on: {
        "change": function($event) {
          var $$a = _vm.checkedPolicies,
            $$el = $event.target,
            $$c = $$el.checked ? (true) : (false);
          if (Array.isArray($$a)) {
            var $$v = policy,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.checkedPolicies = $$a.concat([$$v]))
            } else {
              $$i > -1 && (_vm.checkedPolicies = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
            }
          } else {
            _vm.checkedPolicies = $$c
          }
        }
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "item-media"
    }, [_c('i', {
      staticClass: "icon icon-form-checkbox"
    })])])])]) : _vm._e(), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": {
          span: 20,
          offset: _vm.calculateColumnOffset(policy, 'xs')
        },
        "sm": {
          span: 8,
          offset: _vm.calculateColumnOffset(policy, 'sm')
        }
      }
    }, [_c('div', {
      staticClass: "u-ml2"
    }, [_c('div', {
      staticClass: "payments__card-head"
    }, [_c('fa-icon', {
      staticClass: "payments__card-head_light link-icon-medium",
      attrs: {
        "icon": _vm.determinePolicyIcon(policy)
      }
    }), _vm._v(" "), _c('span', {
      staticClass: "u-pl1"
    }, [_vm._v("\n                  " + _vm._s(policy.properties && policy.properties.length ? policy.properties[0].address_line1 : '') + "\n                ")])], 1), _vm._v(" "), _c('div', {
      staticClass: "payments__card-info u-mt1"
    }, [_vm._v("\n                " + _vm._s(_vm.t('policies.header_policy_number', {
      policy_number: policy.policy_number
    })) + "\n              ")])])]), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 8,
        "sm": 5
      }
    }, [_c('div', [_c('div', {
      staticClass: "payments__card-head"
    }, [_vm._v(_vm._s(_vm.policyDueWeb(policy.id).dateTxt))]), _vm._v(" "), _c('div', {
      staticClass: "payments__card-info"
    }, [_vm._v(_vm._s(_vm.t('payments.due_date')))])])]), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 8,
        "sm": 5
      }
    }, [(_vm.policyHasEntireTerm(policy)) ? _c('div', [_c('div', {
      staticClass: "payments__card-head"
    }, [_vm._v(_vm._s(_vm.asCurrency(policy.payoff_amount)))]), _vm._v(" "), _c('div', {
      staticClass: "payments__card-info"
    }, [_vm._v(_vm._s(_vm.t('payments.entire_term')))])]) : _c('div', [_c('div', {
      staticClass: "payments__card-head_hidden"
    }, [_vm._v("--")])])]), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 8,
        "sm": 5
      }
    }, [(_vm.isAllowedNextTerm(policy)) ? _c('div', [_c('div', {
      staticClass: "payments__card-head"
    }, [_vm._v(_vm._s(_vm.asCurrency(policy.next_due)))]), _vm._v(" "), _c('div', {
      staticClass: "payments__card-info"
    }, [_vm._v(_vm._s(_vm.t('payments.next_term')))])]) : _c('div', {
      class: _vm.policyDueWeb(policy.id).cssClass
    }, [_c('div', {
      staticClass: "payments__card-head"
    }, [_vm._v(_vm._s(_vm.policyDueWeb(policy.id).amount))]), _vm._v(" "), _c('div', {
      staticClass: "payments__card-info"
    }, [_vm._v(_vm._s(_vm.policyDueWeb(policy.id).message))])])])], 1), _vm._v(" "), (!policy.payments_allowed) ? _c('div', {
      staticClass: "u-mt4 disallowed-message"
    }, [_vm._v("\n            " + _vm._s(_vm.t('payments.disallowed_payment_message')) + "\n        ")]) : _vm._e()], 1) : _c('div', {
      staticClass: "card-content-inner"
    }, [_c('el-row', {
      attrs: {
        "gutter": 10
      }
    }, [_c('el-col', {
      attrs: {
        "xs": {
          span: 23,
          offset: 2
        },
        "sm": {
          span: 9,
          offset: 1
        }
      }
    }, [_c('div', [_c('div', {
      staticClass: "payments__card-head"
    }, [_c('fa-icon', {
      staticClass: "payments__card-head_light link-icon-medium",
      attrs: {
        "icon": _vm.determinePolicyIcon(policy)
      }
    }), _vm._v(" "), _c('span', {
      staticClass: "u-pl1"
    }, [_vm._v("\n                  " + _vm._s(policy.properties && policy.properties.length ? policy.properties[0].address_line1 : '') + "\n                ")])], 1), _vm._v(" "), _c('div', {
      staticClass: "payments__card-info u-mt1"
    }, [_vm._v("\n                " + _vm._s(_vm.t('policies.header_policy_number', {
      policy_number: policy.policy_number
    })) + "\n              ")])])]), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": {
          span: 14,
          offset: 2
        },
        "sm": {
          span: 5,
          offset: 9
        }
      }
    }, [_c('div', {
      staticClass: "payments__card-info u-mt1"
    }, [_vm._v("\n              " + _vm._s(policy.policy_status) + "\n            ")])])], 1)], 1)])])
  }), _vm._v(" "), _c('bc-payment-dialog', {
    attrs: {
      "openPaymentDialog": _vm.openPaymentDialog,
      "checkedPolicies": _vm.checkedPolicies,
      "activePayType": _vm.activePayType
    },
    on: {
      "handleMakePaymentsDialog": _vm.handleMakePaymentsDialog,
      "resetCheckedPoliciesList": _vm.resetCheckedPoliciesList,
      "reloadPoliciesInProgress": _vm.reloadPoliciesInProgress
    }
  })], 2) : _vm._e()
},staticRenderFns: []}