<template>
  <nav class="sidenav">
    <div class="sidenav__logo" @click="navigateTo('/dashboard')">
      <img class="sidenav-image" src="~@/assets/desktop_logo.png"/>
    </div>
    <transition name="fade">
    <div v-if="isToggled || isOpen" class="sidenav__content">
      <router-link class="c-header__nav-link u-text--uppercase" :to="'/dashboard'">
        <div @click="hideMobile" class="sidenav__link" :class="isCurrent(['/dashboard'])">
            <fa-icon :icon="['fas', 'tachometer-alt']" class="nav-icon"/>
            <span class="u-pl1">{{ t('dashboard.dashboard_label') }}</span>
        </div>
      </router-link>
      <router-link class="c-header__nav-link u-text--uppercase"
                   v-if="!useExternalPaymentSite && hasAtLeastOnePolicy && paymentFeatureEnabled"
                   :to="linkForPayments">
        <div @click="hideMobile" class="sidenav__link" :class="isCurrent(['/payments'])">
            <fa-icon :icon="['far', 'credit-card']" class="nav-icon"/>
            <span class="u-pl1">{{  t('dashboard.payments') }}</span>
        </div>
      </router-link>
      <a class="c-header__nav-link u-text--uppercase"
         v-if="useExternalPaymentSite && hasAtLeastOnePolicy"
         :href="setting('payments.url_to_external_payment_site')" target="_blank">
        <div @click="hideMobile" class="sidenav__link">
            <fa-icon :icon="['far', 'credit-card']" class="nav-icon"/>
            <span class="u-pl1">{{  t('dashboard.payments') }}</span>
        </div>
      </a>

      <router-link class="c-header__nav-link u-text--uppercase" v-if="hasAtLeastOnePolicy" :to="linkForPolicies">
        <div @click="hideMobile" class="sidenav__link" :class="isCurrent(['/policies/'])">
            <fa-icon :icon="['far', 'shield-check']" class="nav-icon"/>
            <span class="u-pl1">{{ t('global.policies_header') }}</span>
        </div>
      </router-link>

      <router-link class="c-header__nav-link u-text--uppercase" :to="linkForClaims"
                   v-if="(hasAtLeastOnePolicy && claimsFeatureEnabled && alwaysShowClaimsMenuLink) || (openClaims && openClaims.length > 0 && claimsFeatureEnabled && hasAtLeastOnePolicy)">
        <div @click="hideMobile" class="sidenav__link" :class="isCurrent(['/claims/'])">
          <fa-icon :icon="['far', 'file-alt']"class="nav-icon" />
          <span class="u-pl1">{{ t('dashboard.claims') }}</span>
        </div>
      </router-link>
      <router-link class="c-header__nav-link u-text--uppercase" :to="linkForDocuments">
        <div @click="hideMobile" class="sidenav__link" :class="isCurrent(['/documents/', '/document-view/'])">
          <fa-icon :icon="['far', 'folder-open']" class="nav-icon" />
          <span class="u-pl1">{{ t('documents.documents_label') }}</span>
        </div>
      </router-link>
      <router-link class="c-header__nav-link u-text--uppercase" v-if="hasAtLeastOnePolicy" :to="linkForAgent">
        <div @click="hideMobile" class="sidenav__link" :class="isCurrent(['/agents/'])">
            <fa-icon :icon="['far', 'address-card']" class="nav-icon" />
            <span class="u-pl1">{{ t('global.contact') }}</span>
        </div>
      </router-link>
      <div class="c-header__nav-link u-text--uppercase">
        <div class="sidenav__link" :class="isCurrent(['/custom/'])">
          <ba-custom-pages @hideMobile="hideMobile" placement="bottom"/>
        </div>
      </div>
      <div class="sidenav__content-mobile">
        <router-link class="c-header__nav-link u-text--uppercase" :to="'/account/settings'">
          <div class="sidenav__link u-pl4" :class="isCurrent(['/account/'])" @click="hideMobile">
            <fa-icon :icon="['far', 'user']" class="nav-icon" />
            <span class="u-pl1">{{ t('dashboard.view_account') }}</span>
          </div>
        </router-link>
        <a class="c-header__nav-link u-text--uppercase" @click="logOutClicked()">
          <div class="sidenav__link u-pl4">
              <fa-icon :icon="['far', 'sign-out']" class="nav-icon" />
              <span class="u-pl1" > {{ t('global.logout') }}</span>
          </div>
        </a>

      </div>
    </div>
    </transition>

  </nav>
</template>

<script>
  import BaCustomPages from '@/components/custom-pages-menu'

  export default {
    name: 'side-navigation',
    components: {
      'ba-custom-pages': BaCustomPages
    },
    props: {
      isOpen: {
        type: [Function, Boolean],
      },
      isToggled: {
        type: Boolean,
      },
      hasAtLeastOnePolicy: {
        type: Boolean,
      },
      linkForPayments: {
        type: String,
      },
      linkForPolicies: {
        type: String,
      },
      linkForClaims: {
        type: String,
      },
      linkForDocuments: {
        type: String,
      },
      claimsFeatureEnabled: {
        type: [String, Boolean],
      },
      alwaysShowClaimsMenuLink: {
        alwaysShowClaimsMenuLink: Boolean,
      },
      openClaims: {
        type: Array,
        required: false,
      },
      linkForAgent: {
        type: String,
      },
    },
    methods: {
      navigateTo (path) {
        this.$router.push(path)
      },
      hideMobile () {
        this.$emit('hideMobile')
      },
      logOutClicked () {
        this.$emit('logOut')
      },
      isCurrent (hrefList) {
        for (let href of hrefList) {
          if (this.$route.fullPath.includes(href)) {
            return 'sidenav__current'
          }
        }
        return ''
      },
    },
    computed: {
      useExternalPaymentSite () {
        return this.setting('payments.use_external_payment_site')
      },
      paymentFeatureEnabled () {
        return !this.setting('payments.disable_payment_functionality')
      }
    }
  }
</script>

<style lang="scss">
  @import '../sass/_variables.scss';
  

  body.body #app .sidenav {
    width: 280px;
    background: #F8F8F8;
    
    @media only screen and (max-width: $l-screen) {
      width: auto;
    }
    
    &__logo {
      padding: 40px;
      cursor: pointer;
      
      @media only screen and (max-width: $l-screen) {
        padding: 0;
        width: 180px;
        max-height: 80px;
        margin: auto;
      }
      
      @media only screen and (max-width: $s-screen) {
        width: 130px;
        max-height: 50px;
      }
      
      & > .sidenav-image {
        max-width: 200px;
  
        @media only screen and (max-width: $l-screen) {
          max-width: 100%;
        }
      }
    }
    &__content {
      position: relative;
      
      @media only screen and (max-width: $l-screen) {
        position: absolute;
        top: 75px;
        width: 250px;
        left: 0;
        bottom: 0;
        padding: 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #F8F8F8;
        z-index: 2001;
        &:before{
          content: " ";
          position: absolute;
          width:  100%;
          height: 100%;
          z-index: -1;
          box-shadow: 7px 0 5px -2px #e6e6e6;
        }
      }
      
      @media only screen and (max-width: $s-screen) {
        top: 50px;
      }

      a.c-header__nav-link {
        display: flex;
        align-items: center;
        font-size: 16px;
        text-decoration: none;
        transition: opacity .3s ease;
        &:hover {
          opacity: .6;
          text-decoration: none;
          transition: opacity .3s ease;
        }
      }
      
      a.router-link-active {
        color: $color-primary !important;
        text-decoration: none !important;
        svg path {
          fill: $color-primary;
        }
      }
    }
    
    
    &__link {
      padding: 20px 40px;
      font-weight: 600;
      color: #626262;
      text-decoration: none;
      @media only screen and (max-width: $s-screen) {
        padding: 15px 40px;
      }
      svg.nav-icon {
        width: 25px;
      }
    }
  
    &__current {
      width: 100%;
      background: $color-white;
      color: $color-primary;
      svg path {
        fill: $color-primary;
      }
    }
    
    &__content-mobile {
      display: none;
      padding-bottom: 25px;
      
      @media only screen and (max-width: $s-screen) {
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
      &:before {
        content: '';
        position: absolute;
        left: 20px;
        width: 210px;
        border-top: 2px solid transparentize($color-text--disabled, .3);
      }
      a {
        color: $color-primary !important;
      }
      svg path {
        fill: $color-primary !important;
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 345.5ms cubic-bezier(0.32, 0.08, 0.24, 1) 20ms,
    transform 0s cubic-bezier(0.32, 0.08, 0.24, 1),
    -webkit-transform 0s cubic-bezier(0.32, 0.08, 0.24, 1);
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
