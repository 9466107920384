module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [(_vm.navigationVisible) ? _c('bc-side-navigation', {
    attrs: {
      "isOpen": _vm.isOpen,
      "isToggled": _vm.isToggled,
      "hasAtLeastOnePolicy": _vm.hasAtLeastOnePolicy,
      "linkForPayments": _vm.linkForPayments,
      "linkForPolicies": _vm.getLinkData('/policies/'),
      "linkForDocuments": _vm.getDocumentsLink,
      "linkForClaims": _vm.linkForClaims,
      "claimsFeatureEnabled": _vm.claimsFeatureEnabled,
      "alwaysShowClaimsMenuLink": _vm.alwaysShowClaimsMenuLink,
      "openClaims": _vm.openClaims,
      "linkForAgent": _vm.linkForAgent
    },
    on: {
      "hideMobile": _vm.hideMobile,
      "logOut": _vm.logOutClicked
    }
  }) : _vm._e(), _vm._v(" "), _c('el-container', {
    staticClass: "c-page",
    class: _vm.isLoginPage ? 'bg-login' : ''
  }, [(_vm.navigationVisible) ? _c('bc-top-navigation', {
    attrs: {
      "badge": _vm.badge,
      "isFeatureEnabled": _vm.isFeatureEnabled('message-center'),
      "isToggled": _vm.isToggled
    },
    on: {
      "hideMobile": _vm.hideMobile,
      "toggleMobMenu": _vm.toggleMobileMenu,
      "logOut": _vm.logOutClicked
    }
  }) : _vm._e(), _vm._v(" "), _c('main', {
    class: _vm.isLoginPage ? 'u-h100 c-page__main' : ''
  }, [_c('router-view')], 1), _vm._v(" "), _c('bc-footer', {
    style: (_vm.loginStyle)
  })], 1)], 1)
},staticRenderFns: []}