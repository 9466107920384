/* eslint-disable */
import _ from 'lodash'
import axios from 'axios'
import config from '@/../config/britecore'
import content from '@/../config/content'
import {deepClone} from '@shared_src/utils/misc.util'
import * as qs from 'qs'
let languageCodeKey = 'languageCode'

export function paymentMethodToDict (paymentMethod) {
    let maskedNumber = paymentMethod.masked_number
    // todo: why this even exist ?
    return {
        id: paymentMethod.id,
        masked_number: maskedNumber,
        label: `${paymentMethod.account_name} - ${paymentMethod.account_type} ending in #${maskedNumber}`,
        type: paymentMethod.account_type,
        name: paymentMethod.account_name,
        nameAndNumber: `${paymentMethod.account_name} ending in #${maskedNumber}`,
        insured_id: paymentMethod.insured_id,
        contact_id: paymentMethod.contact_id,
        method: paymentMethod.method,
        masked_routing: paymentMethod.masked_routing,
        name_on_account: paymentMethod.name_on_account,
        expire_date: paymentMethod.expire_date,
    }
}

const commonModule = {
  namespaced: true,
  state: {
    settings: null,
    contact: null,
    contacts: [],
    userPhones: [],  //phones from backend. Already preprocessed on backend side (merge etc). Belong to user entity (to all contacts) but without data duplication between bc contacts
    modifiableContact: null,
    perils: null,
    content: null,
    unhandledExceptionCause: null,
    version: config.DOCKER_IMAGE_TAG,
    featureFlags: null,
    applicationLanguage: null,
    dialogPayData: {
      isDialogVisible: false,
      currentSubMenu: '',
      customInsureds: null,
      context: {
        fromAutopay: false
      }
    },
    dialogNewPhoneNumber: {
      isDialogVisible: false
    },
    isLoadingInProgress: false,
  },
  mutations: {
    handlePayDialog (state, payload) {
      state.dialogPayData = {
        isDialogVisible: payload.isDialogVisible,
        currentSubMenu: payload.currentSubMenu,
        customInsureds: payload.customInsureds,
        context: payload.context
      }
    },
    handleDialogNewPhoneNumber (state, payload) {
      state.dialogNewPhoneNumber = payload
    },
    setVersion (state, payload) {
      state.version = payload
    },
    setContacts (state, payload) {
      let result = []
      payload.forEach(contact => {
        let billingAddress = contact.addresses.find(data => data.type && data.type.includes('Billing'))
        contact = { ...contact, hasBillingAddress: !!billingAddress }
        result.push(contact)
      })
      state.contacts = result
    },
    setUserPhones (state, payload) {
      payload.forEach(phoneRecord => phoneRecord.id = Math.round(Math.random()*1000000000))
      state.userPhones = payload
    },
    setLanguage (state, payload) {
      state.applicationLanguage = payload
      localStorage.setItem(languageCodeKey, state.applicationLanguage)
    },
    setLoading (state, payload) {
      state.isLoadingInProgress = payload
    },
    updateFeatureFlags (state, payload) {
      state.featureFlags = payload
    },
    setModifiableContact (state, payload) {
      state.modifiableContact = deepClone(payload)
    },
    setContactPhoto (state, payload) {
      let contactId = payload.contactId
      let photo = payload.photo
      let contact = _.find(state.contacts, {id: contactId})
      contact.photo = photo
    },
    setSettings (state, payload) {
      if (_.isEmpty(state.settings)) {
        state.settings = payload
      } else {
        state.settings = Object.assign(state.settings, payload)  // this way properties will be reactive
      }
    },
    setPerils (state, payload) {
      state.perils = payload
    },
    setContent (state, payload) {
      localStorage.content = JSON.stringify(payload)
      state.content = payload
    },
    initLanguage (state) {
      // we will need to load user-preferred language from account settings
      // this will be a global version used for enrollment
      // after enrollment we can take this flag and submit it as user-preferred language
      let previouslySelectedLanguage = localStorage.getItem(languageCodeKey)
      if (previouslySelectedLanguage === null) {
        // init with carrier's language
        let carrierPreferredLanguage = state.settings.global.language
        localStorage.setItem(languageCodeKey, carrierPreferredLanguage)
        state.applicationLanguage = carrierPreferredLanguage
      } else {
        state.applicationLanguage = previouslySelectedLanguage
      }
    },
    initContent (state) {
      if (state.content) {
        return
      }
      let localStorageContent = localStorage.getItem('content')
      if (localStorageContent) {
        state.content = JSON.parse(localStorageContent)
      } else {
        state.content = content
      }
      localStorage.content = JSON.stringify(state.content)
    },
    setUnhandledExceptionCause (state, payload) {
      state.unhandledExceptionCause = payload
    },
    setSessionExpiration (state, payload) {
      //web-related
      state.session_expiration = payload
    },
  },
  actions: {
    loadVersion: function (context) {
      return new Promise((resolve, reject) => {
        axios.get('version.txt')
          .then(result => {
            let lines = result.data.split('\n')
            let versionStr = 'mobile_' + lines[0] + '_' + lines[3] + '_' + lines[5]
            localStorage.setItem('versionStr', versionStr)
            context.commit('setVersion', versionStr)
          }, () => {
            let unknownVersion = 'mobile_unknown'
            localStorage.setItem('versionStr', unknownVersion)
            context.commit('setVersion', unknownVersion)
          })
          .catch(error => {
            console.log('loadVersion', error)
            reject()
          })
      })
    },
    async loadFeatureFlags (context, payload) {
      let response = await axios.get(`${config.url}/feature-flags/`)
      context.commit('updateFeatureFlags', response.data)
    },
    async savePreferredLanguage (context, payload) {
      if (payload === null) {
        payload = localStorage.getItem(languageCodeKey)
      }

      await axios.post(config.url + '/save-preferred-language/', {token: localStorage.token, language: payload})
    },
    loadSessionExpiration: function (context) {
      //web-related
      return new Promise((resolve, reject) => {
        axios.post(config.get_session_expiration, qs.stringify({company_id: context.state.settings.company_id})).then((response) => {
          context.commit('setSessionExpiration', response.data.data)
          resolve()
         }).catch((e) => {
           reject(e)
         })
       })
    },
    loadContact: function (context) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        axios.post(config.get_contact_data_url, {token: localStorage.token})
        .then(response => {
          let contactsData = response.data.data
          context.commit('setContacts', contactsData.contacts)
          context.commit('setUserPhones', contactsData.phones)
          // load contact photo
          context.state.contacts.forEach(contact => {
            let photoId = contact.photo_id
            if (photoId) {
              context.dispatch('documents/getAttachmentFileAesGcm', [photoId, false], {root: true}).then((data) => {
                context.commit('setContactPhoto', {
                  photo: 'data:' + data.contentType + ';base64,' + data.data,
                  contactId: contact.id
                })
              },
              reason => {
                // status 404 means that user has no photo
                console.log('loadAttachment failed', reason)
              })
            }
          })
          context.commit('setLoading', false)
          resolve()
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.error('get_contact_data_url failed', error)
          reject()
        })
      })
    },
    loadContent: function getRemoteContentPromise (context) {
      return new Promise((resolve, reject) => {
        axios.post(config.get_company_content, qs.stringify({ company_id: context.state.settings.company_id }))
        .then(response => {
          if (response.data) {
            context.commit('setContent', response.data)
          } else {
            console.log("No data in server response to 'get_company_content'")
          }
          resolve()
        })
        .catch(error => {
          console.error('get_company_content failed', error)
          reject()
        })
      })
    },
    async up(context) {
      let response = await axios.post(config.up_url, {company_id: context.state.settings.company_id})
      return response.data

    },
    loadSettings: function getRemoteSettingsPromise (context, applyFontStyle) {
      return new Promise((resolve, reject) => {
        axios.post(config.get_company_settings_url, qs.stringify({ company_id: context.state.settings.company_id }))
        .then(response => {
          if (response.data) {
            let l = response.data.global.language
            let override = localStorage.getItem('language')
            if (override !== null) {
              l = override
            }
            // Set font in App - does it works? //web-related. This apply breaks mobile app
            if (applyFontStyle) document.getElementById('app').className = response.data.global.default_font
            context.commit('setSettings', response.data)
            context.commit('setLanguage', l)
          } else {
            console.log("No data in server response to 'get_company_settings_url'")
          }
          resolve()
        })
        .catch(error => {
          console.error('get_company_settings_url failed', error)
          reject()
        })
      })
    },
    async updateUserPhoneNumbers (context, payload) {
      let initPhones = payload.initPhones
      let updatedPhones = payload.updatedPhones

      let addedPhones = []
      let modifiedPhones = []
      let deletedPhones = []
      updatedPhones.forEach(phoneRecord => {
        let recordToPush = {phone:phoneRecord.phone, type: phoneRecord.type}
        if (!phoneRecord.added) { //need to check here what was name on retrieval from BA
            let originalRecord = _.find(initPhones, {id: phoneRecord.id})
            recordToPush.original_phone = originalRecord.phone
            recordToPush.original_type = originalRecord.type
            if (phoneRecord.deleted) {
              deletedPhones.push(recordToPush)
            } else if (recordToPush.phone !== recordToPush.original_phone || recordToPush.type  !== recordToPush.original_type) {
              modifiedPhones.push(recordToPush)
            }
        } else {
          addedPhones.push(recordToPush)
        }
      })
      try {
        let result = await axios.post(config.update_user_phones, {
           added_phones: addedPhones,
           modified_phones: modifiedPhones,
           deleted_phones: deletedPhones,
           token: localStorage.token
        })
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
  getters: {
    modifiableContact: state => {
      return deepClone(state.modifiableContact)
    },
    contactName: state => {
      //web-related
      return state.contact ? state.contact.name : 'null'
    },
    sessionExpiration: state => {
      //web-related
      return state.session_expiration
    },
    perils: state => {
      return state.perils
    },
    content: state => {
      return state.content
    },
    isSingleContactAccount: state => {
      return state.contacts && state.contacts.length === 1
    },
    settings: state => {
      return state.settings
    },
    getSetting: state => (settingSlug) => {
      let parts = settingSlug.split('.')
      let settings = state.settings
      if (!settings) return null
      if (!settings.hasOwnProperty(parts[0])) return null
      let section = settings[parts[0]]
      if (!section.hasOwnProperty(parts[1])) return null
      return section[parts[1]]
    },
    version: state => {
      return state.version
    },
    availableLanguages: state => {
      if (!state.content) {
        return []
      }
      let keys = Object.keys(state.content)
      return keys
    },
    isFeatureEnabled: state => (featureName) => {
      if (!state.featureFlags) {
        return false
      }

      return state.featureFlags.indexOf(featureName) >= 0
    },
  },
}

export default commonModule
