<template>
  <div class="credit-card-details">
    <div data-f7="list" form class="inputs-list no-hairlines">

      <span v-if="setting('payments.show_cc_processing_fee_warning')"
            v-html="t('payments.cc_processing_fee_warning')"
            class="u-warning u-mb3 u-block">
      </span>

      <div data-f7="list-item">
        <span data-f7="label" class="subheader u-block">{{ t('payments.credit_card_number') }} *</span>
          <div class="media__image media__image--card">
            <bc-payment-method-icon :paymentMethod="paymentMethodForIcon"></bc-payment-method-icon>
          </div>
          <el-input :maxlength="19"
                    class="credit-card-input__text2"
                    auto-complete="cc-number"
                    v-model="masked"
                    type="tel"
                    placeholder="1111 2222 3333 4444"
                    required />

      </div>
      <div data-f7="list-item" v-if="validateCardCopy.card" class="u-warning">
        <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateCardCopy.card }}
      </div>

      <div data-f7="list-item" class="u-mt3">
        <span data-f7="label" class="subheader u-block">{{ t('payments.full_name_as_it_appears') }} *</span>
        <el-input class="item-input-field"
                  auto-complete="cc-name" v-model="card.name_on" type="text" required />
      </div>
      <div data-f7="list-item" v-if="validateCardCopy.fullName" class="u-warning">
        <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateCardCopy.fullName }}
      </div>


      <div data-f7="list-item">
        <div class="row u-mt3">
          <div class="col-40">
            <span data-f7="label" class="subheader u-block">{{ t('payments.month') }} *</span>
            <el-select data-f7="input"
                       auto-complete="cc-exp-month"
                       class="item-input-field"
                       v-model="card.expires_mm"
                       placeholder="">
              <el-option value="0" disabled hidden>{{ t('payments.month') }}</el-option>
              <el-option value="01">01</el-option>
              <el-option value="02">02</el-option>
              <el-option value="03">03</el-option>
              <el-option value="04">04</el-option>
              <el-option value="05">05</el-option>
              <el-option value="06">06</el-option>
              <el-option value="07">07</el-option>
              <el-option value="08">08</el-option>
              <el-option value="09">09</el-option>
              <el-option value="10">10</el-option>
              <el-option value="11">11</el-option>
              <el-option value="12">12</el-option>
            </el-select>
          </div>
          <div v-if="validateCardCopy.expiresMonth" class="u-warning item-inner">
            <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateCardCopy.expiresMonth }}
          </div>
          <div class="col-10 credit-card__month-year-separator">
          </div>
          <div class="col-40">
            <span data-f7="label" class="subheader u-block">{{ t('payments.year') }} *</span>
            <el-select placeholder=""
                       data-f7="input"
                       auto-complete="cc-exp-year"
                       class="item-input-field"
                       v-model="card.expires_yy"
                       placeholder="">
              <el-option value="0" disabled hidden :label="t('payments.year')"></el-option>
              <el-option v-for="year in years" :value="year + ''" :key="year" :label="year"></el-option>
            </el-select>
          </div>
        </div>

        <div v-if="validateCardCopy.expiresYear" class="u-warning item-inner">
          <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateCardCopy.expiresYear }}
        </div>

        <div class="row u-mt3">
          <div class="col-40">
            <span data-f7="label" class="subheader">{{ t('payments.sec_code') }} *</span>
            <el-input v-model="card.cvv2"
                      class="item-input-field credit-card__cvv"
                      type="tel"
                      auto-complete="off"
                      placeholder="***"/>
          </div>
        </div>
        <div v-if="validateCardCopy.securityCode" class="u-warning item-inner">
          <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validateCardCopy.securityCode }}
        </div>
        <!-- row ends -->
      </div>
    </div>
  </div>
</template>

<script>
import {deepClone} from '@shared_src/utils/misc.util'
import {getCreditCardType} from '@shared_src/utils/payments.utils'
import BcPaymentMethodIcon from './payment-method-icon.component.vue'
import _ from 'lodash'

export default {
  components: {BcPaymentMethodIcon},
  name: 'bc-credit-card-details',
  props: {},
  data () {
    let years = _.range(new Date().getFullYear(), new Date().getFullYear() + 10)
    let card =
      {
        number: '',
        name_on: '',
        expires_mm: '',
        expires_yy: '',
        cvv2: '',
      }

    return {
      years,
      card,
      validateCardCopy: {
        card: null,
        fullName: null,
        expiresMonth: null,
        expiresYear: null,
        securityCode: null,
      }
    }
  },
  computed: {
    masked: {
      get: function () {
        if (this.card.number) {
          let formatted = this.card.number.replace(/(\d{4}(?!\s))/g, '$1 ')
          if (formatted.length === 20) {
            formatted = formatted.trim()
          }
          return formatted
        }
        return ''
      },
      set: function (newValue) {
        this.card.number = newValue.replace(/\s/g, '')
      },
    },
    validateCard () {
      let cardDateValid = this.validateExpirationDate()

      return {
        card: !this.card.number.trim() ? this.t('payments.credit_card_number_is_required') : null,
        fullName: !this.card.name_on.trim() ? this.t('payments.full_name_is_required') : null,
        expiresMonth: cardDateValid.monthError,
        expiresYear: cardDateValid.yearError,
        securityCode: !this.card.cvv2.trim() ? this.t('payments.security_code_is_required') : null,
      }
    },
    validateCardValues () {
      return Object.keys(this.validateCard).every(key => !this.validateCard[key])
    },
    paymentMethodForIcon () {
      let cardType = this.cardTypeFromNumber(this.card.number)
      return { type: 'Credit Card', name: cardType }
    },
  },
  methods: {
    validateExpirationDate () {
      let monthError
      let yearError
      try {
        if (!this.card.expires_mm || !this.card.expires_mm.trim()) {
          monthError = this.t('payments.month_is_required')
        }

        if (!this.card.expires_yy || !this.card.expires_yy.trim()) {
          yearError = this.t('payments.year_is_required')
        }

        if (yearError || monthError) return {yearError, monthError}

        let date = new Date()
        let currentYr = date.getFullYear()
        let currentMth = date.getMonth() + 1
        let ccYr = parseInt(this.card.expires_yy)
        let ccMth = parseInt(this.card.expires_mm)

        if (ccYr < currentYr) {
          yearError = this.t('payments.invalid_expiration_date')
        }

        if (currentYr === ccYr && ccMth < currentMth) {
          monthError = this.t('payments.invalid_expiration_date')
        }
      } catch (err) {
        yearError = this.t('payments.invalid_expiration_date')
        monthError = this.t('payments.invalid_expiration_date')
      }

      return {yearError, monthError}
    },

    getPaymentMethodData () {
      this.validateCardCopy = deepClone(this.validateCard)
      if (!this.validateCardValues) {
        return
      }

      let cardInformation = Object.assign({}, this.card)
      cardInformation.cardTypeFromNumber = this.cardTypeFromNumber(this.card.number)
      return cardInformation
    },
    cardTypeFromNumber (cardNumber) {
      return getCreditCardType(cardNumber)
    },
    clearFields () {
      this.card = {
        number: '',
        name_on: '',
        expires_mm: '',
        expires_yy: '',
        cvv2: '',
      }

      this.validateCardCopy = {
        card: null,
        fullName: null,
        expiresMonth: null,
        expiresYear: null,
        securityCode: null,
      }
    }
  },
}
</script>
<style>
  .credit-card__cvv {
    -webkit-text-security: disc;
  }

  .credit-card__month-year-separator {
    padding-top: 24px;
  }
</style>
