module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('bc-navbar', {
    staticClass: "u-mb4",
    attrs: {
      "title": _vm.t('account.my_account')
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "my-account"
  }, [_c('div', {
    staticClass: "my-account__menu-outer"
  }, [_c('div', {
    staticClass: "my-account__nested-menu"
  }, [_c('div', [_c('router-link', {
    staticClass: "my-account__link",
    attrs: {
      "to": "/account/settings"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.t('account.account_settings')) + "\n          ")])], 1), _vm._v(" "), _c('div', [_c('router-link', {
    staticClass: "my-account__link",
    attrs: {
      "to": "/account/phone-numbers"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.t('account.phone_numbers')) + "\n          ")])], 1), _vm._v(" "), _c('div', [_c('router-link', {
    staticClass: "my-account__link",
    attrs: {
      "to": "/account/emails"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.t('global.emails_label')) + "\n          ")])], 1), _vm._v(" "), _c('div', [_c('router-link', {
    staticClass: "my-account__link",
    attrs: {
      "to": "/account/change-password"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.t('account.change_password')) + "\n          ")])], 1), _vm._v(" "), (_vm.isEnabledElectronicDeliveryOption) ? _c('div', [_c('router-link', {
    staticClass: "my-account__link",
    attrs: {
      "to": "/account/document-delivery"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.t('enrollment.document_preferences')) + "\n          ")])], 1) : _vm._e(), _vm._v(" "), _c('div', [_c('router-link', {
    staticClass: "my-account__link",
    attrs: {
      "to": "/account/delete-my-account"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.t('account.delete_my_account_title'))
    }
  })], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "my-account__view"
  }, [_c('router-view')], 1)])], 1)
},staticRenderFns: []}