/* eslint-disable */
import config from '../config/britecore'
import settings from '../config/settings'
import axios from 'axios'
import {setupCache} from 'axios-cache-adapter'
import sjcl from 'sjcl'

const cache = setupCache({
  maxAge: 15 * 60 * 1000
})

const api = axios.create({
  adapter: cache.adapter
})

function decryptAesGcm (data, secretKey) {
  // we expect base64 here. After decoding we expect nonce+(encrypted data+tag)
  // server sends us strings, so we convert bytes to string after decrypting
  const bitArray = sjcl.codec.base64.toBits(data);
  const bitArrayCopy = bitArray.slice(0);
  const ivdec = bitArrayCopy.slice(0, 4);
  const encryptedBitArray = bitArray.slice(4);
  let key = sjcl.codec.hex.toBits(secretKey);
  let cipher = new sjcl.cipher.aes(key);

  const data_bytes = sjcl.mode.gcm.decrypt(cipher, encryptedBitArray, ivdec, {}, 128);

  let result = sjcl.codec.utf8String.fromBits(data_bytes);

  return result
}

export function retrieveAttachmentAesGcm (fileId, base64Decode, resized, secretKey, uniqueValueToIsolateCache, doNotCache) {
  return new Promise((resolve, reject) => {
    let useCache = doNotCache !== true  // don't cache if doNotCache explicitly passed as "true"
    axios({
      url: config.get_encrypted_attachment_aes_gcm,
      method: 'get',
      headers: {Authorization: localStorage.token},
      params: {
        file_id: fileId,
        base64_decode: base64Decode,
        resized: resized,
        unique_value_to_isolate_cache: uniqueValueToIsolateCache
          // several users can access to same file_id.
          // uniqueValueToIsolateCache will help to isolate cache
      },
      useCache: useCache})
    .then(response => {
      let dataToSave = {
        data: decryptAesGcm(response.data.data.ciphertext, secretKey),
        contentType: response.data.data.content_type,
        contentDisposition: response.data.data.content_disposition,
      }
      resolve(dataToSave)
    })
    .catch(error => {
      console.error('retrieveAttachmentAesGcm failed', error)
      reject(error)
    })
  })
}