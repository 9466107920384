<template>
  <div class="reminder">
    <bc-navbar :title="t('payments.reminders_page_title')" class="u-mt0 u-mb4"></bc-navbar>

    <div v-loading="loading">

      <div class="reminder__page reminder__options"
           v-if="paymentReminders && accountData">

        <div class="reminder__info u-text-center">
          <div class="u-text-uppercase u-mb1" v-html="t('payments.reminders_switch_label')"></div>
          <el-switch :active-text="t('global.on')"
                     :inactive-text="t('global.off')"
                     active-color="#5AB67F"
                     inactive-color="#CB6B0B"
                     v-model="paymentReminders.enabled">
          </el-switch>
        </div>

        <div v-if="paymentReminders.enabled">

          <div class="reminder__info_subtitle u-mb1 " v-html="t('payments.reminders_schedule_help')"></div>
          <el-checkbox-group v-model="paymentReminders.schedule">
            <div v-for="schedule in remindersScheduleUiList" :key="schedule" class="u-mb2">
              <el-checkbox :label="schedule"><span v-html="t('payments.reminders_schedule_day', schedule)"></span></el-checkbox>
            </div>
          </el-checkbox-group>
          <div v-if="!isAnyScheduleChecked" class="u-warning">
            <i class="material-icons md-text" aria-hidden="true">warning</i>
            <span v-html="t('payments.reminders_schedule_warning')"></span>
          </div>

          <div class="reminder__info_subtitle u-mt3 u-mb1" v-html="t('payments.reminders_types')"></div>
          <el-checkbox-group v-model="checkList">
            <div v-for="el in reminderTypesToDisplay" :key="el" class="u-mb2">
              <el-checkbox :label="el" :disabled="isReminderTypeDisabled(el)">
                <span v-html="t('global.' + el)"></span>
                <el-popover v-if="(el==='text') && !isConsentToText"
                            placement="top-start"
                            title=""
                            width="325"
                            trigger="hover">
                  <span slot="reference"><fa-icon icon="exclamation-circle"></fa-icon></span>
                  <span v-html="t('payments.reminders_requires_consent_to_text')"></span>
                </el-popover>
                <el-popover v-if="(el==='push') && !isPushAvailable"
                            placement="top-start"
                            title=""
                            width="325"
                            trigger="hover">
                  <span slot="reference"><fa-icon icon="exclamation-circle"></fa-icon></span>
                  <span v-html="t('payments.reminders_requires_mobile_app_and_push_permissions')"></span>
                </el-popover>
              </el-checkbox>
            </div>
          </el-checkbox-group>
          <div v-if="!isAnyReminderTypeChecked" class="u-warning">
            <i class="material-icons md-text" aria-hidden="true">warning</i>
            <span v-html="t('payments.reminders_type_warning')"></span>
          </div>

          <div v-if="isTextChecked" class="u-mt3">
            <!-- Phone Numbers -->
            <el-form v-if="userPhones" class="u-max-width">
              <div class="inputs-list">
                <div class="list-item--select">
                  <span class="u-block u-text-uppercase subheader">
                    {{ t('account.cell_phone_number') }} *
                  </span>
                  <div class="u-flex-align-items">
                    <div class="reminder__info_phone">
                      <el-select v-model="textRecipientNumber" class="item-input-field u-block">
                        <el-option v-for="phone in userPhones"
                                   :value="phone.phone"
                                   :key="phone.id"
                                   :label="getPhoneNationalFormat(phone.phone)">
                        </el-option>
                      </el-select>
                    </div>
                    <div @click="handlePhoneNumberDialog" class="u-ml3">
                      <fa-icon :icon="[ 'fal', 'plus-circle']" class="list-item--select__slot-icon u-primary" />
                    </div>
                  </div>
                  <div v-if="!textRecipientNumber" class="u-warning">
                    <i class="material-icons md-text" aria-hidden="true">warning</i>
                    <span v-html="t('enrollment.phone_not_defined_notification')"></span>
                  </div>
                </div>
              </div>
            </el-form>
            <div class="u-mt3" v-html="t('payments.reminders_text_expenses_warning')"></div>
          </div>
        </div>

        <div class="u-mt3">
          <el-button type="primary"
                     @click="updateRemindersSetup"
                     class="dialog-info__btn u-text-uppercase"
                     :disabled="saveButtonDisabled">
            {{ t('global.save_changes') }}
          </el-button>
        </div>

        <phone-number-dialog />

      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapState, mapMutations} from 'vuex'
  import eventBus from '@shared_src/eventHub'
  import PhoneNumberDialog from '@/components/new-phone-number.dialog.vue'
  import {deepClone, deepCompare, parsePhoneNationalFormat} from '@shared_src/utils/misc.util'
  
  export default {
    name: 'payment-reminders',
    components: {
      PhoneNumberDialog
    },
    created () {
      this.loadData()
      eventBus.$on('phone-added', (val) => {
        this.textRecipientNumber = val
      })
      eventBus.$on('payment-reminders-updated', () => {
        this.loadData()
      })
    },
    destroyed () {
      eventBus.$off('payment-reminders-updated')
      eventBus.$off('phone-added')
    },
    data () {
      return {
        remindersScheduleUiList: [1, 7, 14, 30],
        checkList: [],
        reminderTypes: ['push', 'email', 'text'],
        paymentReminders: null,
        loadedPaymentReminders: null,
        textRecipientNumber: null,
        loading: false
      }
    },
    computed: {
      ...mapState('common', ['userPhones']),
      ...mapState('account', ['accountData']),
      saveButtonDisabled () {
        if (!this.isAnythingChanged) return true  // disable if no changes
        if (this.paymentReminders.enabled) {
          // if reminders enabled - force user to set everything properly
          return !this.isAnyScheduleChecked ||
              !this.isAnyReminderTypeChecked ||
              (this.isTextChecked && !this.textRecipientNumber)
        }
        return false
      },
      dataToSave () {
        // forms dict to save.
        let data = {
          enabled: this.paymentReminders.enabled,
          schedule: this.paymentReminders.schedule,
          text_recipient_number: this.textRecipientNumber
        }
        this.reminderTypes.forEach(
            reminder => { data[reminder] = this.checkList.includes(reminder) }
        )
        return data
      },
      reminderTypesToDisplay () {
        if (this.setting('global.application_versions_supported') === 'version_web_only') {
          return this.reminderTypes.filter(type => type !== 'push')
        }
        return this.reminderTypes
      },
      isAnythingChanged () {
        // checks if any reminder setup updated
        return !deepCompare(this.dataToSave, this.loadedPaymentReminders)
      },
      isConsentToText () {
        return this.accountData.consent_to_text
      },
      isPushAvailable () {
        return (!!this.accountData.push_tokens &&
          (this.setting('global.application_versions_supported') !== 'version_web_only'))
      },
      isReminderTypeDisabled: function () {
        return (reminderType) => {
          if (reminderType === 'push') {
            return !this.isPushAvailable
          }

          if (reminderType === 'text') {
            return !this.isConsentToText
          }

          return false
        }
      },
      isAnyScheduleChecked () {
        // checks if any schedule selected
        return !!this.paymentReminders.schedule.length
      },
      isAnyReminderTypeChecked () {
        // checks if any schedule selected. Ignores checked reminder if it disabled
        return !!this.checkList.filter(
            reminderType => { return !this.isReminderTypeDisabled(reminderType) }).length
      },
      isTextChecked () {
        return this.checkList.includes('text')
      },
    },
    methods: {
      ...mapMutations('common', ['handleDialogNewPhoneNumber']),
      ...mapActions('payments', ['getPaymentReminders', 'updatePaymentReminders']),
      handlePhoneNumberDialog () {
        this.handleDialogNewPhoneNumber({ isDialogVisible: true })
      },
      getPhoneNationalFormat (phone) {
        return parsePhoneNationalFormat(phone)
      },
      async loadData () {
        try {
          this.loading = true
          let response = await this.getPaymentReminders()
          this.paymentReminders = response.data
          this.loadedPaymentReminders = deepClone(this.paymentReminders)
          this.textRecipientNumber = this.paymentReminders.text_recipient_number
          this.checkList = this.reminderTypes.filter(reminderType => {
            return this.paymentReminders[reminderType]
          })
          this.paymentReminders.schedule.forEach(val => {
            if (!this.remindersScheduleUiList.includes(val)) this.remindersScheduleUiList.push(val)
          })
        } catch (e) {
          this.paymentReminders = null
          let cause = (e.response && e.response.cause) ? e.response.cause : 'global.error'
          this.$elAlert(this.t(cause), this.t('global.notice'))
        }
        this.loading = false
      },
      async updateRemindersSetup () {
        this.loading = true
        await this.updatePaymentReminders(this.dataToSave)
        this.loading = false
        this.$elAlert(this.t('payments.reminders_updated'), this.t('payments.reminders_page_title'))
      }
    }
  }
</script>

<style lang="scss">
  @import '../../sass/_variables.scss';
  
   .reminder {
    &__page {
      
      .el-switch__label.is-active {
        color: $color-black;
      }
    }
    &__options {
      border: 2px solid #E5E5E5;
      border-radius: 4px;
      padding: 20px;
    }
    &__info {
      margin-top: 10px;
      padding: 10px;
      border-radius: 2px;
      background: #F8F8F8;
      margin-bottom: 20px;
      &_subtitle {
        font-size: 22px;
      }
      &_phone {
        width: 220px;
        @media only screen and (max-width: $l-screen) {
          width: auto;
        }
      }
    }
  }
  

</style>
