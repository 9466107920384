<template>
  <div>
    <div class="claim-gallery__preview"
         v-for="(item, i) in rows"
         :key="i"
         @click.stop="clickPhoto(i)">
      <div v-if="item.isPreferred" class="preferred-image-star"><fa-icon icon="star" class="u-primary"></fa-icon></div>
      <div v-if="(claimPreview && i === 0) || !claimPreview" class="image claim-gallery__image">
        <bc-general-image :fileId="item.id"
                          :class="[{ 'preferred-image' : item.isPreferred  }]"
                          :style="{ backgroundImage: 'url(' + item.imageUri + ')' }">
        </bc-general-image>
      </div>
    </div>
    <el-dialog ref="carouselDialog"
            :title="carouselCaption"
            :visible.sync="photoViewerVisible"
            custom-class="dialog-slider">
      <el-carousel ref="carousel" height="450px"
        trigger="click"
        :autoplay="false"
        indicator-position="none"
        class="property-slider"
        arrow="always"
      >
        <el-carousel-item v-for="item in rows" :key="item.id">
          <div class="property-slider__frame">
            <bc-general-image :fileId="item.id" class="bc-general-image"></bc-general-image>
          </div>
          <div class="property-slider__info u-mt2">
            <div class="u-flex-horizontal-space-between u-width100">
              <div>{{ item.caption }}</div>
              <div class="property-slider__modify">
                <el-button v-if="isVisible(item)" :icon="['fas', 'trash']" type="info"
                           @click.stop="$emit('removeImage', item)">
                </el-button>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="browseType === 'insured'" @click="clickPhotoEdit" :icon="['fas', 'edit']">{{ t('policies.edit_photo') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'bc-images-table',
  props: {
    rows: {
      type: Array
    },
    isDeletableFunction: {
      type: Function
    },
    carouselCaption: {
      type: String
    },
    browseType: {
      type: String
    },
    claimPreview: {
      type: Boolean
    }
  },
  data () {
    return {
      index: null,
      photoViewerVisible: false,
    }
  },
  methods: {
    isVisible (item) {
      return (this.isDeletableFunction !== undefined && this.isDeletableFunction(item))
    },
    clickPhoto (index) {
      this.photoViewerVisible = true
      let setCarouselActivePicture = () => {
        if (this.$refs.carousel === undefined) {
          setTimeout(setCarouselActivePicture, 200)
        } else {
          this.$refs.carousel.setActiveItem(index)
        }
      }
      setCarouselActivePicture()
    },
    clickPhotoEdit () {
      this.$refs.carouselDialog.close()
      this.$emit('viewImage', this.updatedRows[this.$refs.carousel.activeIndex])
    }
  },
}
</script>

<style lang="scss">
  @import '../sass/_variables.scss';

  .claim-gallery {
    display: flex !important;
    flex-wrap: wrap;
    z-index: 1;
    &__image {
      display:block;
      position: relative;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center top;
      // background-size: contain;
      background-size: cover;
      z-index: 2;
      border: none;
      border-image: none;
    }
    &__preview {
      position: relative;
      margin-bottom: 20px;
      border: none;

      @media only screen and (max-width: $m-screen) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    &__button {
      position: absolute;
      bottom: -3em;
      z-index: 3;
    }
  }
    .el-carousel {
      &__container {
        height: 60vh !important;

        @media only screen and (max-width: $m-screen) {
          height: 40vh !important;
        }
      }
      &__item > img {
        width: 100%;
        background-position: center;
        background-size: contain;
      }
    }
    .el-dialog {
      .carousel-remove-btn {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      @media only screen and (max-width: $m-screen) {
        margin: 0 15px;
      }
    }
  .preferred-image {
    border: 3px solid $color-primary;
    &-star {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 3;
      & > i {
        font-size: 30px;
        color: $color-primary;
      }
    }
  }
</style>
