module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "pages"
  }, [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    staticClass: "centered"
  }, [_c('div', {
    staticClass: "u-text-center"
  }, [_vm._v(_vm._s(_vm.t('enrollment.initiate_enrollment_proceed_text')))]), _vm._v(" "), _c('div', {
    staticClass: "wrapper"
  }, [_c('el-button', {
    staticClass: "u-text-center",
    attrs: {
      "type": "info"
    },
    on: {
      "click": _vm.process
    }
  }, [_vm._v("\n          " + _vm._s(_vm.t('enrollment.initiate_enrollment_proceed_button')) + "\n        ")])], 1)])])
},staticRenderFns: []}