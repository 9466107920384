module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "pages"
  }, [_c('img', {
    staticClass: "init-screen__logo-image",
    attrs: {
      "src": require("@/assets/maintenance.jpg")
    }
  }), _vm._v(" "), _c('el-button', {
    staticClass: "centered",
    attrs: {
      "disabled": _vm.maintenance,
      "type": "info"
    },
    on: {
      "click": _vm.onReturnToSiteClick
    }
  }, [_vm._v("\n      Return to site\n    ")]), _vm._v(" "), (_vm.maintenance) ? _c('span', {
    staticClass: "centered-text"
  }, [_vm._v("Return to site available only when maintenance is over")]) : _vm._e()], 1)
},staticRenderFns: []}