<template>
  <div class="array-field">
    <div v-if="field.label != null" class="u-color-secondary u-mt1 u-text-trim">{{ t(field.label) }} ({{field.value.length}})</div>
    <div v-for="(x, index) in field.value" v-bind:key="index" class="u-mt2 u-text-multiline-trim">
      {{x.value}}
      <i v-if="x.icon" :class="'fa-' + x.icon" class="fa u-color-primary"></i>

    </div>
  </div>
</template>
<script>
  export default {
    name: 'ba-array-field',
    props: ['field'],
  }
</script>

<style scoped lang="scss">
  @import "./web-src/sass/variables";
  .u-color-primary{
    color: $color-primary;
  }
</style>
