<template>
  <div class="init-screen" v-loading="loading">
    <init-sidebar/>
    <div class="init-screen__info centered-block">
      <div class="init-block">
        <div class="navigation">
          <div class="init-block__back" @click="goBack()">
            <ba-icon-link icon_type="far" icon_name="arrow-left" icon_class="u-pl2" :label="t('documents.back')"/>
          </div>
        </div>
        <div>
          <bc-navbar :title="t('enrollment.setup_account_step_headline')"></bc-navbar>
          <div class="subheader">{{ t('enrollment.enter_the_details_below_to_create_your_login_credentials') }}</div>
          <el-form>
            <el-form-item :label="t('enrollment.username')" v-if="!usersAreIdentifiedByEmailAddress">
              <el-input data-cy="username"
                        @keyup.enter.native="verifyAndRegister"
                        type="text"
                        v-model="authUsername"
                        data-test-id="username"
                        :class="{'u-input-error': validateUserNameMessage}"/>
              <div v-if="validateUserNameMessage" class="u-input-validation u-danger u-pt2 u-pb1">
                {{ validateUserNameMessage }}
              </div>
            </el-form-item>
            <!--Email field-->
            <el-form-item :label="t('enrollment.email_address_input_label') + ' *'">
              <el-input data-cy="email"
                        @keyup.enter.native="verifyAndRegister"
                        v-model.trim="authEmail"
                        type="email"
                        data-test-id="email"
                        :disabled="!!emailFromExtra"
                        :class="{'u-input-error': validateEmailByRegexMessage}"/>
              <div v-if="validateEmailByRegexMessage" class="u-input-validation u-danger u-pt2 u-pb1">
                {{ validateEmailByRegexMessage }}
              </div>
            </el-form-item>
            <!--Password field-->
            <ba-new-password ref="newPasswordField" :showSubmitButton="false" @newPasswordValue="onNewPasswordValue" class="u-mb3"></ba-new-password>

            <el-form-item v-if="!hidePin" class="u-mb0" label="PIN">
              <el-input @keyup.enter.native="verifyAndRegister" v-model.trim="authPin" type="password" autocomplete="off"  :maxlength="4" data-test-id="pin-number" />
              <div v-if="validatePinByRegexMessage" class="u-warning u-mt1">
                <i class="material-icons md-text" aria-hidden="true">warning</i> {{ validatePinByRegexMessage }}
              </div>
            </el-form-item>

            <el-form-item v-if="!hidePin" class="subheader u-mt1">
              {{ t('enrollment.pin_help_text') }}
            </el-form-item>
          </el-form>

          <div class="u-mt3 u-mb3">
            <p class="secondary-header">{{ t('account.context_to_text_choice') }}
              <span class="u-ml2 portal-code-info">
                <el-popover
                  placement="top-start"
                  title=""
                  width="325"
                  trigger="hover">
                <span slot="reference"><fa-icon icon="exclamation-circle"></fa-icon></span>
                <span v-html="t('account.consent_to_text_help')"></span>
              </el-popover>
              </span>
            </p>
            <ba-consent-to-text ref="consentRef" :account="null" :notify-backend='false'/>
          </div>
      
          <!-- Document Preferences -->
          <div v-if="isEnabledElectronicDeliveryOption">
            <div class="document-delivery secondary-header">{{ t('enrollment.choose_document_delivery_method') }}</div>
            <div>
              <div>
                <el-radio data-cy="go-paperless" class="u-mt2 secondary-header__radio" v-model="deliveryByEmail" :label="true" @change="onDocDeliveryUpdate(true)">
                  {{ t('enrollment.go_paperless') }}*
                </el-radio>
              </div>
              <div>
                <el-radio data-cy="receive-paper-docs" class="u-mt2 receive-paper-docs" v-model="deliveryByEmail" :label="false" @change="onDocDeliveryUpdate(false)">
                  {{ t('enrollment.receive_paper_documents')  }}
                </el-radio>
              </div>
            </div>
            <div v-if="validateDocDeliveryMessage" class="u-input-validation u-danger u-pt2 u-pb2">
              {{ validateDocDeliveryMessage }}
            </div>
          </div>
          
          <div v-if="tos" class="enrollment-tos">
            <el-checkbox data-cy="accept-checkbox" v-model="acceptedTOS" @change="confirmTos(acceptedTOS)">{{ t('enrollment.i_agree_to') }}</el-checkbox>
            <a class="enrollment-tos__highlighted" @click="dialogTermsOfService = true">{{ t('enrollment.terms_of_service') }}</a>
          </div>
      
          <div class="u-mt3" data-f7="block">
            <el-button type="primary" data-cy="proceed" @click="verifyAndRegister" class="el-button--wide u-text-uppercase" data-test-id="save-account-information" :disabled="tos && !acceptedTOS">
              {{ t('enrollment.save_account_information_button_caption') }}
            </el-button>
          </div>
          
          <el-dialog
            :title="t('enrollment.terms_of_service')"
            :visible.sync="dialogTermsOfService"
            width="50%"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            custom-class="tos-dialog">
            <div>
              <terms-of-service
                :isEnrollmentTOS="true"
                @handleEnrollmentDialogTOS="handleDialog"
                ref="terms"
              />
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button @click="agreeToTOS" type="primary" class="el-button--wide u-text-uppercase">{{ t('enrollment.agree_to_terms') }}</el-button>
            </div>
          </el-dialog>
          <!-- BEGIN Confirmation code dialog -->
          <el-dialog
              :title="t('account.user_action_required')"
              :visible.sync="confirmationCodeDialogVisible"
              :before-close="hideConfirmationCodeDialog"
              custom-class="confirmation-code-dialog"
              v-loading="loading">
            <div class="el-message-box__message">
                <div v-html="t('enrollment.email_confirmation_sent', {new_email: authEmail})"></div>
                <el-input class="u-pt3"
                          type="tel"
                          v-model="emailConfirmationCode"
                          @keyup.enter.native="registerAndLoginInternal"
                ></el-input>
                <div class="u-mt3">
                  <a v-if="!canBypass" class="text-underline" :class="resendAvailableIn > 0 ? 'non-interactive': ''" @click="verifyAndRegister">
                    {{ t('enrollment.resend_code') }}
                    <span v-if="resendAvailableIn > 0">{{t('enrollment.action_available_in', {due: resendAvailableIn})}}</span>
                  </a>
                  <a v-if="canBypass" class="text-underline" :class="resendAvailableIn > 0 ? 'non-interactive': ''" @click="continueWithoutConfirmation">
                    {{ t('enrollment.continue_without_confirmation') }}
                    <span v-if="resendAvailableIn > 0">{{t('enrollment.action_available_in', {due: resendAvailableIn})}}</span>
                  </a>
                </div>
            </div>
            <div slot="footer" class="confirmation-code-dialog__footer">
               <el-button type="info" size="small" @click="hideConfirmationCodeDialog" >{{ t('global.cancel') }}</el-button>
               <el-button :disabled="emailConfirmationCode.length === 0" class="u-ml1" type="primary" size="small" @click="registerAndLoginInternal">{{ t('global.confirm') }}</el-button>
            </div>
          </el-dialog>
          <!-- END Confirmation code dialog -->

          <el-dialog
                  :title="t('account.user_action_required')"
                  :visible.sync="dialogVisible"
                  width="50%"
                  :show-close="false"
                  :close-on-press-escape="false"
                  :close-on-click-modal="false"
                  custom-class="change-email-dialog">
            <span>{{ t('account.user_action_required_email_text') }}</span>
            <span slot="footer" class="dialog-footer emails-list-footer">
              <div class="u-mt2" v-for="dialogButton in dialogButtons" :key="dialogButton.text">
                 <el-button type="info" @click="dialogButton.onClick" >{{ dialogButton.text }}</el-button>
              </div>
              <div  class="dialog-footer__cancel u-mt3">
               <el-button type="primary" @click="dialogVisible = false">{{ t('documents.documents_popover_menu_cancel') }}</el-button>
              </div>
            </span>
          </el-dialog>
          <dialog-info
            :visibleDialogInfo="visibleDialogInfo"
            @userApproved="userApprovedDeliveryType"
            @handleCloseDialog="handleCloseDialog"
            :isVisibleCloseIcon="false"
            :title="t('e_delivery.acknowledge_info_title')"
            :description="t('e_delivery.acknowledge_info')"
          ></dialog-info>
        </div>
      </div>
    </div>
    <div class="init-screen__logo-language">
      <bc-footer bgClass="bg-login"></bc-footer>
      <div class="init-screen__bc-language" v-if="displayLanguageLink">
        <ba-language-selector/>
      </div>
    </div>

  </div>
</template>

<script>
import settings from '@/../config/settings'
import config from '@/../config/britecore'
import axios from 'axios'
import {mapActions, mapGetters, mapState, mapMutations} from 'vuex'
import DialogInfo from '@/components/dialog-info.modal'
import detectIP from '@shared_src/ip-detection.utils'
import TermsOfService from './terms-of-service'
import initSidebar from '@/components/init-sidebar'
import iconLink from '@/ui/icon-link.component'
import BaLanguageSelector from '@/components/language-selector.component'
import BaNewPassword from '@/components/new-password.component'
import BaConsentToText from '@/components/consent_to_text.component'
import {isEmailInvalidWithCause, isPinValid} from '@shared_src/utils/misc.util'

let DefaultWaitTimeout = 30

export default {
  components: {
    DialogInfo,
    TermsOfService,
    initSidebar,
    'ba-icon-link': iconLink,
    BaLanguageSelector,
    'ba-new-password': BaNewPassword,
    'ba-consent-to-text': BaConsentToText
  },
  created () {
    this.getIP()
  },
  destroyed () {
    if (this.intervalRef) {
      clearInterval(this.intervalRef)
    }
  },
  methods: {
    ...mapActions('common', ['savePreferredLanguage']),
    ...mapActions('account', ['validateUsername']),
    ...mapMutations('enrollment', ['confirmTos', 'savePreviousView']),
    onNewPasswordValue (newPasswordValue) { // since we use our own submit button - only way to get new passw is to listen this event
      this.authPassword = newPasswordValue
    },
    goBack () {
      this.savePreviousView('setup')
      this.$router.back()
    },
    agreeToTOS () {
      this.$refs.terms.advance()
    },
    handleDialog (value) {
      this.acceptedTOS = value
      if (value === true) this.dialogTermsOfService = false
    },
    async getIP () {
      this.ip = await detectIP()
    },
    handleCloseDialog () {
      this.visibleDialogInfo = false
    },
    userApprovedDeliveryType (value) {
      this.handleCloseDialog()
      value === 'accept' ? this.deliveryByEmail = true : this.deliveryByEmail = false
    },
    onDocDeliveryUpdate (value) {
      if (value && this.setting('e_delivery.e_delivery_acknowledgement')) {
        this.visibleDialogInfo = true
        return
      }
      this.deliveryByEmail = value
    },
    updateWarnings () {
      this.validateEmailByRegexMessage = this.emailValidationMessage
      this.validatePinByRegexMessage = this.pinValidationMessage
      this.validateDocDeliveryMessage = this.docDeliveryValidationMessage
      this.validateUserNameMessage = this.userNameValidationMessage
    },
    beginLoading () {
      this.loading = true
    },
    endLoading () {
      this.loading = false
    },
    async showErrorDialogWithText (message) {
      await this.$elAlert(message, this.t('global.notice'))
    },
    async showGenericErrorDialog () {
      return this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.notice'))
    },
    async sendRegistrationData () {
      return axios.post(config.enrollment_sign_up_with_code, {
        auth: {
          username: this.authUsername,
          emailObj: {
            email: this.authEmail
          },
          password: this.authPassword,
          company_id: settings.company_id,
          pin: this.authPin
        },
        preferred_language: localStorage.getItem('language'),
        edelivery: this.deliveryByEmail,
        token: this.enrollmentToken,
        email_confirmation_code: this.emailConfirmationCode,
        consent_to_text: this.$refs.consentRef.consentToText,
      })
    },
    async registerAndLoginInternal (byPass) {
      if (byPass === true) {
        // protect against Enter key hit without code
        // (byPass === true) is important condition. By default UI can send Event if call was not explicitly defined
        // byPass == true is "continue without confirmation" pressed. We should reset emailConfirmationCode to satisfy backend
        this.emailConfirmationCode = ''
      } else if (!this.emailConfirmationCode.length) {
        // if not byPass and emailConfirmationCode empty - seems someone pressed Enter with empty input (cannot prevent this behavior by html markup)
        return
      }

      try {
        this.beginLoading()
        let result = await this.sendRegistrationData()
        this.endLoading()
        if (!result.data.success) {
          let message
          if (result.data.cause) {
            message = this.causeToString(result.data.cause)
          } else {
            message = 'Registration failed'
          }

          this.validateEmailByRegexMessage = message
          await this.showErrorDialogWithText(message)
          if (result.data.cause === 'account.email_confirmation_code_does_not_match') {
            await this.verifyAndRegister()
          }
          return
        }

        await this.login()
      } catch (result) {
        this.endLoading()
        let responseData = result.response.data
        let message = responseData.messages && responseData.messages.length > 0
          ? responseData.messages[0] : this.t('enrollment.some_error_happened_notification')
        this.$elAlert(message, this.t('global.notice'))
      }
    },
    startCountdown () {
      if (this.intervalRef) {
        clearInterval(this.intervalRef)
      }
      this.resendAvailableIn = DefaultWaitTimeout

      this.intervalRef = setInterval(() => {
        this.resendAvailableIn -= 1
        if (this.resendAvailableIn <= 0) {
          clearInterval(this.intervalRef)
        }
      }, 1000)
    },
    async verifyAndRegister () {
      let isPasswordInvalid = !this.$refs.newPasswordField.checkPasswordValidAndRenderErrors(true)
      this.updateWarnings()
      if (isPasswordInvalid || !this.validateInputValues) {
        this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.notice'))
        return
      }

      try {
        this.beginLoading()
        await this.performUsernameValidation()
        this.emailConfirmationCode = ''
        let shouldDoVerification = await this.checkDoesEmailNeedConfirmation()
        this.endLoading()
        if (shouldDoVerification) {
          this.startCountdown()
          this.showConfirmationCodeDialog()
        } else {
          this.registerAndLoginInternal()
        }
      } catch (e) {
        this.endLoading()
      }
    },
    getLoginKey: function () {
      return this.usersAreIdentifiedByEmailAddress ? this.authEmail : this.authUsername
    },
    async performUsernameValidation () {
      let username = this.getLoginKey()
      try {
        await this.validateUsername({
          username, companyId: settings.company_id
        })
      } catch (e) {
        if (e.response.cause) {  // validateUsername returns no cause if username exists
          await this.showGenericErrorDialog()
        } else {
          this.$elAlert(this.t('enrollment.user_with_username_exists', {username: username}), this.t('global.error'))
        }
        return Promise.reject()
      }
    },
    hideConfirmationCodeDialog: function () {
      this.confirmationCodeDialogVisible = false
    },
    showConfirmationCodeDialog: function () {
      this.confirmationCodeDialogVisible = true
    },
    async continueWithoutConfirmation () {
      try {
        await this.askToBypass()
      } catch (e) {
        return
      }
      this.hideConfirmationCodeDialog()
      await this.registerAndLoginInternal(true)
    },
    async askToBypass () {
      return await this.$confirm(
          this.t('enrollment.bypass_verification_message'),
          this.t('enrollment.bypass_verification_title'),
          {dangerouslyUseHTMLString: true},
      )
    },
    // Returns true, if email needs confirmation. otherwise - false
    async checkDoesEmailNeedConfirmation () {
      this.beginLoading()
      try {
        let response = await axios.post(config.enrollment_email_check, {
          token: this.enrollmentToken,
          new_email: this.authEmail,
        })

        // reset existing bypass state in case if user has entered different email
        this.canBypass = false
        if (response.data.data && response.data.data.can_bypass_code) {
          // This happens when user tried multiple times using same email
          this.canBypass = true
          return true
        }
        if (!response.data.success) {
          this.endLoading()
          await this.showGenericErrorDialog()
          return Promise.reject()
        }
        // Dialog was closed; give another chance to submit
        this.endLoading()
        return true
      } catch (e) {
        this.endLoading()
        await this.showGenericErrorDialog()
      }
    },
    async login () {
      this.beginLoading()
      let loginKey = this.getLoginKey()
      try {
        // token will be intercepted by axios and placed into localStorage
        await axios.post(config.ajax_login_url,
          {
            username: loginKey,
            password: this.authPassword,
            company_id: settings.company_id
          })
      } catch (e) {
        await this.showGenericErrorDialog()
        return Promise.reject()
      }

      this.savePreferredLanguage(null)
      // Logging for no registered user
      if (this.confirmedTos) {
        this.logInsuredAction('Acknowledge Terms of Service', 'custom_acknowledge_tos', {
          username: this.contact.name ? this.contact.name : this.authUsername,
          ip_address: this.ip,
        })
        this.confirmTos(false)
      }
      this.$router.push('/dashboard', () => location.reload(true))
    },
  },
  computed: {
    ...mapGetters('enrollment', ['enrollmentToken']),
    ...mapState('enrollment', ['confirmedTos', 'tos', 'extradata']),
    emailFromExtra () {
      return this.extradata ? this.extradata.email : null
    },
    hidePin () {
      return this.setting('authentication.hide_pin') === true
    },
    usersAreIdentifiedByEmailAddress () {
      return this.setting('enrollment.users_are_identified_by_email_address')
    },
    isEnabledElectronicDeliveryOption () {
      return this.setting('global.electronic_delivery_option')
    },
    validateInputValues () {
      let result = (this.emailValidationMessage === '' &&
      this.docDeliveryValidationMessage === '' &&
      this.pinValidationMessage === '')
      if (!this.usersAreIdentifiedByEmailAddress) {
        result = result && (this.userNameValidationMessage === '')
      }
      return result
    },
    userNameValidationMessage () {
      return this.authUsername.trim() === this.authUsername ? '' : this.t('enrollment.no_lead_trail_space_in_username')
    },
    emailValidationMessage () {
      let emailValidationCause = isEmailInvalidWithCause(this.authEmail)
      return emailValidationCause ? this.t(emailValidationCause) : ''
    },
    pinValidationMessage () {
      let pin = this.authPin
      if (pin && pin.length !== 0 && !isPinValid(pin)) {
        return this.t('enrollment.pin_should_be_a_4_digit_number')
      }
      return ''
    },
    docDeliveryValidationMessage () {
      let errorMsg = ''
      if (this.deliveryByEmail === null && this.isEnabledElectronicDeliveryOption) {
        errorMsg = this.t('enrollment.document_delivery_warning')
      }
      return errorMsg
    },
    displayLanguageLink () {
      return this.setting('global.display_spanish_link') === true
    },
  },
  data () {
    let contact = this.$store.getters['enrollment/linkedContact']
    let suggestedUsername = this.$store.state.enrollment.suggestedUsername
    let email = (contact.emails && contact.emails.length) ? contact.emails[0].email : ''
    let extradata = this.$store.state.enrollment.extradata
    if (extradata && extradata.email) email = extradata.email
    return {
      loading: false,
      originalEmail: email,
      authUsername: suggestedUsername,
      authEmail: email,
      authPassword: '',
      authPin: '',
      contact: contact,
      validateEmailByRegexMessage: '',
      validateUserNameMessage: '',
      validatePinByRegexMessage: '',
      deliveryByEmail: null,
      validateDocDeliveryMessage: '',
      dialogVisible: false,
      dialogButtons: [],
      visibleDialogInfo: false,
      ip: '',
      acceptedTOS: false,
      dialogTermsOfService: false,
      emailConfirmationCode: '',
      confirmationCodeDialogVisible: false,
      canBypass: false,
      intervalRef: null,
      resendAvailableIn: DefaultWaitTimeout,
    }
  }
}
</script>

<style lang="scss">
  @import '../../sass/_variables.scss';

  .secondary-header {
    &__radio {
      .el-radio__label {
        text-transform: capitalize;
        font-size: 15px;
        font-weight: 400;
      }
    }
  }
  .receive-paper-docs {
    .el-radio__label {
      text-transform: capitalize;
      font-size: 15px;
      font-weight: 400;
    }
  }
  .change-email-dialog, .tos-dialog {
    background: $color-white !important;
  }
  .tos-dialog {
    .el-dialog__header,
    .el-dialog__footer {
      padding: 20px;
    }
    .el-dialog__title {
      font-size: 22px;
    }
    .el-dialog__header {
      &:after {
        content: "";
        display: block;
        margin-top: 20px;
        width: 100%;
        background-color: #E5E5E5;
        height: 2px;
      }
    }
    & > .el-dialog__body {
      height: 350px;
      margin: 10px 20px 0;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      @media only screen and (max-width: $m-screen) {
       height: 200px;
      }
    }
  
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 3px;
      height: 80px;
      background-color: $color-primary;
    }
  }

  .enrollment-tos {
    display: flex;
    align-items: baseline;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 2px solid #E5E5E5;
    font-size: 15px;
    &__highlighted {
      font-size: 15px;
      color: $color-primary;
      display: inline-block;
    }
    .el-checkbox__label {
      font-size: 15px;
    }
  }

  .document-delivery {
    font-size: 18px;
  }

  .text-underline {
    border-bottom: 1px dotted $color-primary;
  }
  .text-underline:hover {
    border-bottom: none;
  }
  .non-interactive {
    pointer-events: none;
    cursor: not-allowed;
    border-bottom: none;
    color: $color-text--disabled !important;
  }
</style>

