<template>
  <div v-loading="loading">

    <div v-if="policy" class="pay-plan-component">
      <div v-if="displayedTerm === 'current'|| (nextTermId && (currentTermId !== nextTermId))">
        <div class="pay-plan-component__title u-text-uppercase u-mt3 u-mb2">{{ termTitle }}</div>
        <div v-if="isShowPaymentPlan">
          <div v-if="effectiveTermDates" class="pay-plan-component__date u-mb3">
            <div>{{ effectiveTermDates.begin }} - {{ effectiveTermDates.end }}</div>
          </div>
          <div>
            <el-select class="item-input-field"
                       v-model="selectedBillingSchedule"
                       @change="onInputBillingSheduleChange"
                       :disabled="!isBillingScheduleChangeAllowed || isEscrowBillingSchedule(selectedBillingSchedule)">
              <el-option v-for="schedule in billingSchedulesList"
                         :disabled="isEscrowBillingSchedule(schedule.id)"
                         v-show="shouldShowEscrowPlans(schedule.id)"
                         :selected="schedule.id === selectedBillingSchedule"
                         :placeholder="placeholder"
                         :key="schedule.id"
                         :value="schedule.id"
                         :label="t('payments.selected_payment_schedule', {payment_schedule : schedule.billingSchedule})"
              >
                {{ t('payments.selected_payment_schedule', {payment_schedule : schedule.billingSchedule}) }}
              </el-option>
            </el-select>
            <div class="pay-plan-component__msg u-warning u-mt1 u-mb2" v-if="!isBillingScheduleChangeAllowed">
              <i class="material-icons md-text u-mr1">warning</i>{{ t('payments.payment_plan_change_not_allowed') }}
            </div>
          </div>

          <div v-if="paymentValidationError" class="u-mb2">
            <div class="u-warning">
              <i class="material-icons md-text" aria-hidden="true">warning</i> {{ paymentValidationError }}
            </div>
          </div>
        </div>

         <div v-if="isVisibleBillingSchedule" class="schedule-preview u-mt2 u-mb2">
          <ba-schedule-preview :selectedBillingScheduleOptions="selectedBillingScheduleOptions" />
        </div>

        <div v-if="isAutopayAllowed">
          <div v-if="form.current.autopayEnabled">
              <el-select v-model="form.current.selectedPaymentMethod">
                <el-option v-for="m in policyPaymentMethods"
                           :disabled="!checkIfPaymentMethodAllowed(m.id)"
                           :selected="m.id === form.current.selectedPaymentMethod"
                           :value="m.id"
                           :key="m.id"
                           :label="m.label"></el-option>
              </el-select>
          </div>
          <div class="pay-plan-component__info u-text-center">
            <div class="u-text-uppercase u-mb1">{{ t('payments.automatic_bill_pay') }}</div>
            <div>
              <el-switch
                  :active-text="t('global.on')"
                  :inactive-text="t('global.off')"
                  active-color="#5AB67F"
                  inactive-color="#CB6B0B"
                  @change="validatePaymentMethod"
                  v-model="form.current.autopayEnabled"
                  :disabled="isAutoPaySwitchDisabled"
              >
              </el-switch>
            </div>
          </div>
        </div>
        <div v-if="invalidInitialBillingSetup" class="u-warning u-mb2 u-mt2" v-html="t('payments.invalid_billing_setup')"></div>
        <div class="u-mt2">
          <el-button
              v-if="hasChangesForSave && !policy.is_canceled && !loading"
              :disabled="invalidInitialBillingSetup"
              size="small"
              type="primary"
              class="u-text-uppercase u-width100"
              @click="saveWithConfirmation"
          >
            {{ t('global.save_changes') }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'

  import { mapActions, mapMutations, mapState } from 'vuex'
  import {localize as t} from '@/utils/i18n'
  import moment from 'moment'
  import BaSchedulePreview from '@/pages/payments-redesign/payment-plans/schedule-preview.component'
  import {paymentMethodToDict} from '@shared_src/store/common.module'

  export default {
    components: {
      BaSchedulePreview
    },
    props: {
      policy: {
        type: Object,
        required: true
      },
      termsType: {
        type: String,
        required: true
      }
    },
    mounted () {
      this.loading = true
      this.loadDataOnInit()
    },
    watch: {
      ownedPaymentMethods: {
        handler (value, oldValue) {
          let _value = [...value]
          let _oldValue = [...oldValue]
          if (!_.isEqual(_value.sort(), _oldValue.sort())) {
            this.loadDataOnInit()
          }
        },
        immediate: false
      }
    },
    data () {
      let form = {
        initial: {
          autopayEnabled: false,
          selectedSchedule: '',
          selectedPaymentMethod: '',
        },
        current: {
          autopayEnabled: false,
          selectedSchedule: '',
          selectedPaymentMethod: '',
        }
      }
      return {
        form,
        hasAutoPayOnlySchedules: false,
        paymentValidationError: '',
        initialUserBillingSchedules: [],
        initialUserObject: null,
        initialBillingScheduleOptions: [],
        policyBillingInformation: null,
        loading: false,
        placeholder: this.placeholderPaymentSchedule(),
        displayedTerm: this.termsType,
        currentTermId: null,
        nextTermId: null,
        policyTerms: [],
        invalidInitialBillingSetup: false
      }
    },
    methods: {
      ...mapActions('payments', [
        'changeBillingSchedule', 'getPolicyBillingInformation',
        'retrieveBillingScheduleOptions', 'changeAutopaySingle']),
      ...mapActions('policies', ['getPolicyTerms']),
      ...mapMutations('common', ['handlePayDialog']),
      async loadDataOnInit () {
        try {
          let data = await this.getPolicyTerms(this.policyId)
          let currentTermId = this.policy.current_term.id
          let nextTermId = this.policy.next_policy_term_id
          this.policyTerms = {}
          data.forEach(value => {
            let isTermValid = this.validateTerm(value)
            if (isTermValid) {
              this.policyTerms[value.id] = value
              // assign current and next term ids only if terms have valid revisions
              if (value.id === currentTermId) {
                this.currentTermId = currentTermId
              } else if (value.id === nextTermId) {
                this.nextTermId = nextTermId
              }
            }
          })
          let termIdToFetch = this.isCurrentTermDisplayed ? this.currentTermId : this.nextTermId
          if (termIdToFetch) {  // this way we skip loading data for terms with non-committed revisions only
            this.loadData(true)
          }
        } catch (e) {
          console.error(e)
          this.$elAlert(this.t('account.error_retrieving_data_from_insured'), this.t('global.error'))
        }
      },
      validateTerm (termValue) {
        let result = false
        termValue.revisions.forEach(revision => {
          if (['pending', 'committed'].indexOf(revision.revision_state) !== -1) {
            result = true
          }
        })
        return result
      },
      loadData (updateLoadingOnSuccess) {
        this.loading = true
        this.invalidInitialBillingSetup = false
        let policyTermId = this.isCurrentTermDisplayed ? this.currentTermId : this.nextTermId
        Promise.all([
          this.getPolicyBillingInformation({
            policy_id: this.policyId,
            policy_term_id: policyTermId
          }),
          this.retrieveBillingScheduleOptions({policy_number: this.policy.policy_number, policy_term_id: policyTermId}),
        ])
          .then(data => {
            let getPolicyBillingInformationResponse = data[0]
            this.policyBillingInformation = getPolicyBillingInformationResponse.data
            let initialPaymentMethod = this.policyBillingInformation['current_payment_method_id']
            let autoPaymentEnabled = initialPaymentMethod !== '0' && initialPaymentMethod !== ''
            if (autoPaymentEnabled) {
              this.initialUserObject = this.getOwnerOfPaymentMethod(initialPaymentMethod)
            } else {
              let currentPayingContactId = this.policyBillingInformation['current_paying_contact_id']
              this.initialUserObject = this.policyBillingInformation.payments_contacts.find(
                contact => contact.contact_id === currentPayingContactId)
            }

            let currentScheduleId = this.policyBillingInformation['current_schedule_id']

            if (!this.initialUserObject) {  // we didn't found user. That means it's broken setup
              this.invalidInitialBillingSetup = true
              let billingScheduleData
              this.policyBillingInformation.payments_contacts.forEach(contactData => {
                let billingSchedule = contactData.billing_schedules.find(
                   billingSchedule => billingSchedule.id === currentScheduleId)
                if (billingSchedule) billingScheduleData = billingSchedule
              })
              let fakeUser = {
                billing_schedules: [billingScheduleData],
                contact_id: this.policyBillingInformation['current_paying_contact_id'],
                contact_name: this.t('payments.unknown_payor'),
                payment_methods: [
                  {
                    id: initialPaymentMethod,
                  }
                ],
                info: 'This is fake user which keeps UI from failing apart',
                fakeUser: true
              }
              this.policyBillingInformation.payments_contacts.push(fakeUser)
              this.initialUserObject = fakeUser
            }

            this.initialUserBillingSchedules = this.initialUserObject['billing_schedules']
            this.form.initial.selectedSchedule = currentScheduleId
            this.initialBillingScheduleOptions = data[1].data
            this.hasAutoPayOnlySchedules = this.initialUserBillingSchedules.every(
              schedule => schedule.hasOwnProperty('migrateOnFunding'))
            if (autoPaymentEnabled) {
              this.form.initial.selectedPaymentMethod = initialPaymentMethod
            } else {
              this.form.initial.selectedPaymentMethod = this.chooseAppropriatedPayMethodForAutoPay()
            }
            if (updateLoadingOnSuccess) {
              this.loading = false
            }
            this.form.initial.autopayEnabled = autoPaymentEnabled
            Object.assign(this.form.current, this.form.initial)
          }).catch(() => {
            this.loading = false
            this.$elAlert(this.t('account.error_retrieving_data_from_insured'), this.t('global.error'))
          })
      },
      chooseAppropriatedPayMethodForAutoPay () {
        if (!this.policyPaymentMethods.length) {
          return null
        }
        let res = this.policyPaymentMethods.find((el : PaymentMethod) => {
          if (el.method === 'ACH' && this.bankAccountEnabled) {
            return true
          }
          if (el.method === 'CC' && this.creditCardEnabled) {
            return true
          }
        })
        if (!res) {
          return null
        }
        return res.id
      },
      async askAuthorizationConfirmation () {
        try {
          await this.$confirm(
            this.t('payments.auto_pay_modal_authorization_text'),
            this.t('payments.confirm_authorization'),
            {
              confirmButtonText: this.t('payments.accept'),
              cancelButtonText: this.t('payments.decline')
            }
          )
          this.logInsuredAction('Acknowledge Auto Pay Agreement', 'custom_acknowledge_autopay',
            {
              policy_id: this.policyId,
              policy_number: this.policy.policy_number,
              policy_term_id: this.isCurrentTermDisplayed ? this.currentTermId : this.nextTermId,
              is_current_term: this.isCurrentTermDisplayed
            }
          )
          return true
        } catch (err) {
          return false
        }
      },
      validatePaymentMethod () {
        if (!this.policyPaymentMethods.length) {
          this.form.current.autopayEnabled = false
          this.openAddNewPayMethodDialog()
          return
        }
        this._validatePaymentMethod()
      },
      openAddNewPayMethodDialog () {
        let policyContactIds = this.policyBillingInformation.payments_contacts.map(contact => contact.contact_id)
        let customInsuredIds = []

        let insureds = this.accountData.insureds
        for (let insuredId in insureds) {
          if (!insureds.hasOwnProperty(insuredId)) continue
          if (policyContactIds.indexOf(insureds[insuredId].contact_id) !== -1) customInsuredIds.push(insuredId)
        }
        return this.handlePayDialog({
          isDialogVisible: true,
          currentSubMenu: 'payment-plans',
          customInsureds: customInsuredIds,
          context: {
            fromAutopay: true,
          }
        })
      },
      _validatePaymentMethod () {
        if (this.form.current.autopayEnabled && (this.form.current.selectedPaymentMethod === null)) {
          this.form.current.autopayEnabled = false
          this.$elAlert(this.t('payments.please_add_bank_account_or_credit_card'),
            this.t('payments.automatic_payments_are_turned_off'), () => this.openAddNewPayMethodDialog())
        }
      },
      isValidAutoPay () {
        // To save a new Current Term with existing payment method even if it was disabled
        if (this.form.current.selectedPaymentMethod === this.form.initial.selectedPaymentMethod) {
          return true
        }
        if (!this.isAllowedToSelect(this.selectedPaymentMethod.id) && this.form.current.autopayEnabled) {
          this.$elConfirm(
            this.t('payments.please_add_bank_account_or_credit_card'),
            this.t('payments.automatic_payments_are_turned_off'), {
              type: 'warning',
              okCallback: () => {
                this.handlePayDialog({
                  isDialogVisible: true,
                  currentSubMenu: 'payment-plans',
                  context: {
                    fromAutopay: true
                  }
                })
              }
            })
          return false
        } return true
      },
      async saveWithConfirmation () {
        if (!this.isValidAutoPay()) {
          return
        }
        if (!this.validatePaymentPlan) {
          this.$elAlert(this.t('payments.payment_plan_is_not_defined'), this.t('global.notice'))
          return
        }
        if (this.shouldShowAutoPayAuthorizationMessage) {
          if (await this.askAuthorizationConfirmation()) {
            this.save()
          } else {
            this.$elAlert(this.t('payments.user_declined_payment_authorization_confirmation'), this.t('global.notice'))
          }
        } else {
          let okCallback = () => {
            this.save()
          }
          let message = this.t('payments.are_you_sure_you_want_to_change_your_billing_schedule')
          let title = this.t('payments.payment_plan')
          this.$elConfirm(message, title, {
            type: 'warning',
            okCallback
          })
        }
      },
      async saveNewPaymentScheduleIfChanged () {
        let current = this.form.current
        if (current.selectedSchedule === this.form.initial.selectedSchedule) {
          return false
        }

        await this.changeBillingSchedule({
          policy_id: this.policyId,
          billing_schedule_id: current.selectedSchedule,
          policy_term_id: this.isCurrentTermDisplayed ? this.currentTermId : this.nextTermId
        })
        return true
      },
      async saveNewAutopayMethodIfChanged () {
        if (!this.autopayChanged) {
          return false
        }
        let current = this.form.current
        let autoPaymentMethodId = current.autopayEnabled ? current.selectedPaymentMethod : ''
        let autoPayDaysBefore = '0'
        let contactId = autoPaymentMethodId === '' ? this.initialUserObject['contact_id']
          : this.getOwnerOfPaymentMethod(autoPaymentMethodId)['contact_id']

        await this.changeAutopaySingle({
          policy_id: this.policy.id,
          policy_term_id: this.isCurrentTermDisplayed ? this.currentTermId : this.nextTermId,
          auto_payment_method_id: autoPaymentMethodId,
          auto_pay_days_before: autoPayDaysBefore,
          contact_id: contactId,
          policy_number: this.policy.policy_number,
        })

        return true
      },
      shouldShowEscrowPlans (scheduleId) {
        let selectedPlan = this.form.current.selectedSchedule
        let optionToBeShownIsEscrow = this.isEscrowBillingSchedule(scheduleId)
        let areSamePlans = selectedPlan === scheduleId
        // we do not want to hide Escrow plan, if it is active plan; Render it disabled
        return !optionToBeShownIsEscrow || (this.currentPlanIsEscrow() && areSamePlans)
      },
      currentPlanIsEscrow () {
        return this.isEscrowBillingSchedule(this.form.current.selectedSchedule)
      },
      placeholderPaymentSchedule () {
        return this.t('payments.payment_plan')
      },
      async save () {
        this.logInsuredAction('Clicked "Save payment plan"', 'custom_save_payment_plan', {})

        this.loading = true
        try {
          let isAutoPayMethodUpdateTriggered = await this.saveNewAutopayMethodIfChanged()
          let isPaymentScheduleUpdateTriggered = await this.saveNewPaymentScheduleIfChanged()
          if (!isAutoPayMethodUpdateTriggered && !isPaymentScheduleUpdateTriggered) {
            // no update requests were triggered
            this.loading = false
            return
          }
          await this.getReloadPromises()
          this.loading = false
          this.$elAlert(
            this.t('payments.billing_schedule_changed'),
            this.t('payments.billing_schedule_changed_success_title')
          )
        } catch (error) {
          await this.$elAlert(this.t('payments.error_changing_billing_schedule'), this.t('global.notice'))
          await this.getReloadPromises()
          this.loading = false
        }
      },
      getReloadPromises () {
        this.$emit('updateCustomPayPlan', this.termsType)
        return Promise.all([
          this.$store.dispatch('policies/reload', 1),
          this.loadData(false)
        ])
      },
      isEscrowBillingSchedule (billingScheduleId) {
        let billingSchedule = this.billingSchedulesList.find(schedule => schedule.id === billingScheduleId)
        if (billingSchedule) {
          let scheduleName = billingSchedule.billingSchedule
          return scheduleName.toLowerCase().indexOf('escrow') >= 0
        }
        return false
      },
      determineSelectedBillingSchedule (billingSchedulesList, initialSelectedSchedule, currentSelectedSchedule) {
        if (billingSchedulesList.find(
          billingSchedule => billingSchedule.id === currentSelectedSchedule) !== undefined) {
          // we have this id in listing, ok
          return currentSelectedSchedule
        }
        if (this.hasAutoPayOnlySchedules && this.hasMigrateOnFunding) {
          return this.hasMigrateOnFunding
        }
        if (billingSchedulesList.find(
          billingSchedule => billingSchedule.id === initialSelectedSchedule) !== undefined) {
          // we have initial id in listing, ok
          return initialSelectedSchedule
        }
        let nonEscrowBillingSchedule = billingSchedulesList.find(
          billingSchedule => billingSchedule.billingSchedule.toLowerCase().indexOf('escrow') === -1)
        if (nonEscrowBillingSchedule === undefined) {
          return null
        } else {
          return nonEscrowBillingSchedule.id
        }
      },
      getOwnerOfPaymentMethod (paymentMethodId) {
        let paymentContacts = this.policyBillingInformation.payments_contacts
        let ownerOfSelectedPaymentMethod = paymentContacts.find(
          contact => {
            let foundPaymentMethod = contact.payment_methods.find(
              paymentMethod => paymentMethod.id === paymentMethodId
            )
            return (foundPaymentMethod !== null) && (foundPaymentMethod !== undefined)
          }
        )
        return ownerOfSelectedPaymentMethod
      },
      getPaymentMethodType (id) {
        return this.policyPaymentMethods.find(el => el.id === id).method
      },
      isAllowedToSelect (paymentMethodId) {
        if (this.policy.is_canceled) return false  // forbid changes if policy cancelled

        if (this.policyBillingInformation === undefined || this.policyBillingInformation === null) {
          return false
        }
        const paymentType = this.getPaymentMethodType(paymentMethodId)
        if ((!this.bankAccountEnabled && paymentType === 'ACH') || (!this.creditCardEnabled && paymentType === 'CC')) {
          return false
        }
        return true
      },
      checkIfPaymentMethodAllowed (paymentMethodId) {
        // sometimes we cannot change policy billing schedule.
        // If payment method belong to another person and schedule can be changed - this payment method should be disabled
        if (!this.isAllowedToSelect(paymentMethodId)) return false

        let ownerOfSelectedPaymentMethod = this.getOwnerOfPaymentMethod(paymentMethodId)
        let checkSelectedInputMethodOnPaymentMethodUpdate = this.determineSelectedBillingSchedule(
          ownerOfSelectedPaymentMethod['billing_schedules'],
          this.form.initial.selectedSchedule,
          this.form.initial.selectedSchedule
        )
        return (checkSelectedInputMethodOnPaymentMethodUpdate === this.form.initial.selectedSchedule)
      },
      onInputBillingSheduleChange () {
        if (!this.isBillingScheduleChangeAllowed) {
          return
        }
        let noticeSlug
        if (this.nextTermId && this.policy.can_change_billing_schedule_next_term_ba && this.isCurrentTermDisplayed) {
          // we're updating current term and we have changeable next term. Tis will update next term too
          noticeSlug= 'payments.next_term_will_be_affected_by_current_term_change'
        } else if (this.isNextTermDisplayed) {
          noticeSlug = 'payments.current_term_will_not_be_affected_by_next_term_change'
        }
        if (!noticeSlug) {
          return
        }
        this.$elAlert(
          this.t(
            noticeSlug, {
              nextTermBegin: this.nextTermDates.begin,
              nextTermEnd: this.nextTermDates.end,
              currentTermBegin: this.currentTermDates.begin,
              currentTermEnd: this.currentTermDates.end,
            }),
          this.t('global.notice'))
      }
    },
    computed: {
      ...mapState('account', ['accountData']),
      ...mapState('payments', ['ownedPaymentMethods']),
      termPaymentMethods () {
        if (!this.policyBillingInformation) return []
        return this.policyBillingInformation.payment_methods_for_autopay.map(pm => paymentMethodToDict(pm))
      },

      allowedPaymentMethodTypes () {
        return this.setting('payments.autopay_payment_methods')
      },
      creditCardEnabled () {
        let autoPayMethodSetting = this.allowedPaymentMethodTypes
        return autoPayMethodSetting === 'ap_credit_cards' || autoPayMethodSetting === 'ap_ba_and_cc'
      },
      bankAccountEnabled () {
        let autoPayMethodSetting = this.allowedPaymentMethodTypes
        return autoPayMethodSetting === 'ap_bank_accounts' || autoPayMethodSetting === 'ap_ba_and_cc'
      },
      policyId () {
        return this.policy.id
      },
      isVisibleBillingSchedule () {
        return this.isBillingSchedulePreviewAllowed && this.isBillingScheduleChangeAllowed &&
            this.hasChangesForSave && this.selectedBillingScheduleOptions.length > 0
      },
      effectiveTermDates () {
        if (this.displayedTerm === 'current') {
          return this.currentTermDates
        } else {
          return this.nextTermDates
        }
      },
      termTitle () {
        if (this.termsType === 'current') {
          return this.t('payments.current_term_button')
        } else {
          return this.t('payments.next_term_button')
        }
      },
      policyPaymentMethods () {
        let methods = this.termPaymentMethods
        methods.forEach(methodData => {
          let paymentMethodOwner = this.getOwnerOfPaymentMethod(methodData.id)
          if (!paymentMethodOwner || paymentMethodOwner.fakeUser) {
            // broken setup, however payment method exists on BA/BC side apart from contacts related to this policy
            methodData.label = this.t('payments.unknown_payor') + ' - ' + methodData.label
          }
        })
        return methods
      },

      isCurrentTermDisplayed () {
        return this.displayedTerm === 'current'
      },
      isNextTermDisplayed () {
        return this.displayedTerm === 'next'
      },
      currentTermDates () {
        if (this.policyTerms && this.currentTermId && this.policyTerms[this.currentTermId]) {
          let currentTerm = this.policyTerms[this.currentTermId]
          return {
            begin: this.dateLong(moment(currentTerm.effective_date)),
            end: this.dateLong(moment(currentTerm.expiration_date))
          }
        }
        return {
          begin: null,
          end: null
        }
      },
      nextTermDates () {
        if (this.policyTerms && this.nextTermId && this.policyTerms[this.nextTermId]) {
          let nextTerm = this.policyTerms[this.nextTermId]
          return {
            begin: this.dateLong(moment(nextTerm.effective_date)),
            end: this.dateLong(moment(nextTerm.expiration_date))
          }
        }
        return {
          begin: null,
          end: null
        }
      },
      isBillingScheduleChangeAllowed () {
        return this.setting('payments.allow_billing_schedule_change') && !this.policy.is_canceled &&
          ((this.isCurrentTermDisplayed && this.policy.can_change_billing_schedule_current_term_ba)||
            (this.isNextTermDisplayed && this.nextTermId && this.policy.can_change_billing_schedule_next_term_ba)
          )
      },
      selectedBillingSchedule: {
        get: function () {
          this.form.current.selectedSchedule = this.determineSelectedBillingSchedule(
            this.billingSchedulesList, this.form.initial.selectedSchedule, this.form.current.selectedSchedule
          )
          return this.form.current.selectedSchedule
        },
        set: function (newValue) {
          this.form.current.selectedSchedule = newValue
        }
      },
      billingSchedulesList () {
        if (this.policyBillingInformation === null) {
          return []
        }
        let selectedPaymentMethodId = this.form.current.selectedPaymentMethod
        let autoPayOnlySchedules = this.hasAutoPayOnlySchedules
        if (this.form.current.autopayEnabled === false || !selectedPaymentMethodId) {
          // if autopay not enabled - we using same payment method - do not change schedule list then
          return autoPayOnlySchedules ? this.initialUserBillingSchedules.filter(
            schedule => schedule.settingsLabel !== 'AutoPay'
          ) : this.initialUserBillingSchedules
        }
        let paymentContacts = this.policyBillingInformation.payments_contacts
        let ownerOfSelectedPaymentMethod = paymentContacts.find(
          contact => {
            let foundPaymentMethod = contact.payment_methods.find(
              paymentMethod => paymentMethod.id === selectedPaymentMethodId
            )
            return (foundPaymentMethod !== null) && (foundPaymentMethod !== undefined)
          }
        )
        if (autoPayOnlySchedules) {
          return ownerOfSelectedPaymentMethod['billing_schedules'].filter(
            schedule => schedule.settingsLabel !== 'Manual'
          )
        }
        return ownerOfSelectedPaymentMethod['billing_schedules']
      },
      isBillingSchedulePreviewAllowed () {
        return this.setting('payments.display_payment_schedule_preview')
      },
      selectedBillingScheduleOptions () {
        let scheduleOptions = this.initialBillingScheduleOptions.filter(
          (schedule) => schedule.id === this.selectedBillingSchedule)[0]
        if (scheduleOptions) {
          let remainingInstallments = scheduleOptions['installments'].filter(
            (installment) => installment.amountDue > 0).map(
            (installment) => {
              installment.isPastDue = moment().isAfter(installment.dueDate)
              return installment
            })
          return remainingInstallments
        }
        return []
      },
      hasMigrateOnFunding () {
        if (this.hasAutoPayOnlySchedules) {
          return this.initialUserBillingSchedules.find(
            schedule => schedule.id === this.form.current.selectedSchedule
          ).migrateOnFunding
        }
        return false
      },
      shouldShowAutoPayAuthorizationMessage () {
        return this.setting('payments.auto_pay_confirmation_modal') && this.form.current.autopayEnabled
      },
      isShowPaymentPlan () {
        return this.setting('payments.show_payment_plan')
      },
      isAutopayAllowed () {
        if (this.currentPlanIsEscrow()) {
          if (!this.setting('payments.allow_auto_pay_change_on_escrow')) {
            return false
          }
        }
        return this.setting('payments.allow_auto_pay')
      },
      isAutoPayReadOnly () {
        return this.setting('payments.auto_pay_read_only') === true
      },
      isAutoPaySwitchDisabled () {
        if (!this.policy) return
        return this.policy.is_canceled || this.isAutoPayReadOnly
      },
      validatePaymentPlan () {
        this.selectedPaymentPlan
          ? this.paymentValidationError = '' : this.paymentValidationError = t('payments.payment_plan_is_not_defined')
        return this.selectedPaymentPlan
      },
      hasChangesForSave () {
        return !_.isEqual(this.form.initial, this.form.current)
      },
      selectedPaymentPlan () {
        let selectedSchedule = this.form.current.selectedSchedule
        let schedule = this.billingSchedulesList.find(schedule => schedule.id === selectedSchedule)
        return schedule ? schedule.billingSchedule : ''
      },
      selectedPaymentMethod () {
        let id = this.form.current.selectedPaymentMethod
        return this.policyPaymentMethods.find(x => x.id === id)
      },
      autopayChanged () {
        return this.form.current.autopayEnabled !== this.form.initial.autopayEnabled ||
            this.form.current.selectedPaymentMethod !== this.form.initial.selectedPaymentMethod
      }
    },
  }
</script>

<style scoped lang="scss">
@import '../../../sass/_variables.scss';

.pay-plan-component {
  &__title {
    font-size: 14px;
    color: #626262;
  }
  &__info {
    margin-top: 10px;
    padding: 10px;
    border-radius: 2px;
    background: #F8F8F8;
  }
  &__date, &__msg {
    font-size: 15px;
  }
  .el-switch__label {
    text-transform: uppercase;
  }
  .el-switch__label.is-active {
    color: $color-black;
  }
}
</style>
