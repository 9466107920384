module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "submenu"
  }, [_c('div', {
    staticClass: "submenu__menu-outer"
  }, [(_vm.screenType === 'policies') ? _c('ba-add-missing-policy', {
    staticClass: "u-mt1"
  }) : _vm._e(), _vm._v(" "), (_vm.policiesListByType) ? _c('div', {
    staticClass: "submenu__nested-menu u-mt4"
  }, _vm._l((_vm.getPoliciesTypes), function(type, index) {
    return _c('div', {
      key: index
    }, [(_vm.policyTitle(_vm.policiesListByType, type).length) ? _c('h2', {
      staticClass: "u-mb3 submenu__link-type-title"
    }, [_vm._v("\n          " + _vm._s(_vm.policyTitle(_vm.policiesListByType, type)) + "\n        ")]) : _vm._e(), _vm._v(" "), _c('div', {
      staticClass: "submenu__block-links u-mb2"
    }, _vm._l((_vm.policiesListByType[type]), function(policy) {
      return _c('router-link', {
        key: policy.id,
        class: [_vm.linkActiveClass(policy.id), 'submenu__link u-block'],
        attrs: {
          "to": _vm.generateMenuLink(_vm.screenType, policy.id)
        }
      }, [_c('div', {
        staticClass: "u-p1"
      }, [_c('el-popover', {
        attrs: {
          "placement": "top-start",
          "title": "",
          "width": "200",
          "open-delay": 200,
          "trigger": "hover",
          "content": _vm.policyShortHeadline(policy)
        }
      }, [_c('span', {
        attrs: {
          "slot": "reference"
        },
        slot: "reference"
      }, [_c('div', {
        staticClass: "submenu__link-line-1 u-text-trim"
      }, [_c('fa-icon', {
        class: 'link-icon-medium',
        attrs: {
          "icon": _vm.determinePolicyIcon(policy)
        }
      }), _vm._v(" "), _c('div', {
        staticClass: "submenu__link_bold u-pl1 u-inline"
      }, [_vm._v(_vm._s(_vm.policyShortHeadline(policy)))])], 1)])]), _vm._v(" "), _c('div', [_vm._v(_vm._s(policy.policy_number))])], 1)])
    }), 1)])
  }), 0) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "submenu__view"
  }, [_c('router-view', {
    key: _vm.$route.fullPath
  })], 1)])
},staticRenderFns: []}