<template>
  <div>
    <el-button @click="openDialog" type="info">{{ t('enrollment.add_missing_policy_label') }}</el-button>

    <el-dialog
        :visible.sync="state.dialogVisible"
        @handleCloseDialog="dialogWillClose"
        :close-on-press-escape="false"
        :close-on-click-modal="false">
      <div slot="title" class="el-dialog__title_border add-missing-policy__dialog-title">
        <el-button v-if="canGoBack && !state.loading"
                   @click="back" class="u-mr3"
                   :icon="['far', 'chevron-left']"
                   :type="'text'">
        </el-button>
        <div class="el-dialog__title">{{ dialogTitle }}</div>
      </div>
      <div>
        <div v-if="state.debug">
          <div>page `{{workflow.page}}`</div>
          <div>policy_number `{{state.policy_number}}`</div>
          <div>insured_id `{{searchState.selectedInsuredId}}`</div>
          <div>insured `{{searchState.selectedInsured}}`</div>
          <div>selectedContactMethod `{{challenge.selectedContactMethod}}`</div>
          <div>send_via `{{challenge.sendVia}}`</div>
          <div>code `{{challenge.code}}`</div>
          <div>emails: {{emails}}</div>
          <hr/>
        </div>
        <ba-search-by-policy-number v-if="workflow.page === 'search'" v-model="state.policy_number"/>
        <ba-insured-selection
            v-if="workflow.page === 'insuredSelection'"
            :insureds="searchState.insureds"
            :registered_insureds="searchState.registered_insureds"
            v-model="searchState.selectedInsuredId"
        />

        <ba-policy-challenge
            v-if="workflow.page === 'challenge'"
            @requestCode="requestCode"
            :challenge="challenge"
            :selectedInsured="searchState.selectedInsured"
            :errorMessage="state.errorMessageDetail"
        />
        <ba-code-delivery v-if="workflow.page === 'sendCode'"
            v-model="challenge.selectedContactMethod"
            :selectedInsured="searchState.selectedInsured"
            :sendVia="challenge.sendVia"/>

        <ba-use-different-email v-if="workflow.page === 'useAnotherEmail'"
                                v-model="emails.toUse"
                                :available-emails="emails.availableEmails"
                                :loading="state.loading"/>
        <ba-new-email-verification v-if="workflow.page === 'emailVerification'"
                                   v-model="emails.verificationCode"
                                   :email="emails.toUse"
                                   :verificationFailure="emails.verificationFailure" />

        <ba-missing-policy-added v-if="workflow.page === 'success'" :state="state" />

      </div>
      <div v-if="workflow.page !== 'success' || (workflow.page === 'success' && !state.loading)"
           slot="footer" class="u-flex-justify-content-end">
        <el-button v-loading="state.loading" :disabled="!canGoNext || state.loading" @click="next()" type="primary" class="u-text-capitalize">
          {{nextButtonText}}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import _ from 'lodash'

  import {mapActions, mapGetters} from 'vuex'

  import settings from '@/../config/settings'
  import config from '@/../config/britecore'
  import { getCauseData } from '@/../shared_src/utils/api.utils'

  import BaSearchByPolicyNumber from '@/pages/add-missing-policy/search-by-policy-number.component'
  import BaInsuredSelection from '@/pages/add-missing-policy/insured-selection.component'

  import BaPolicyChallenge from '@/pages/add-missing-policy/policy-challenge.component'
  import BaCodeDelivery from '@/pages/add-missing-policy/code-delivery.component'
  import BaMissingPolicyAdded from '@/pages/add-missing-policy/missing-policy-added.component'
  import BaUseDifferentEmail from './use-different-email.component'
  import BaNewEmailVerification from './new-email-verification.component'
  import axios from 'axios'

  const unexpectedErrorMessage = 'Unexpected error occurred. Check network connection or try again latter'

  export default {
    components: {
      BaNewEmailVerification,
      BaUseDifferentEmail,
      BaMissingPolicyAdded,
      BaCodeDelivery,
      BaPolicyChallenge,
      BaInsuredSelection,
      BaSearchByPolicyNumber
    },
    data () {
      return {
        workflow: {
          page: 'search',
        },
        state: {
          debug: false,
          dialogVisible: false,
          loading: false,
          policy_number: '',
          errorMessage: '',
          errorMessageDetail: '',
        },
        searchState: {
          insureds: [],
          registered_insureds: [],
          selectedInsuredId: '',
          selectedInsured: null,
        },
        challenge: {
          type: '',
          code: '',
          // [email | phone]
          sendVia: '',
          sentMessage: null,
          selectedContactMethod: null,
        },
        emails: {
          toUse: '',
          verificationCode: '',
          availableEmails: [],
          verificationFailure: '',
        },
        contactDataToAttach: null,
      }
    },
    name: 'ba-add-missing-policy',
    methods: {
      ...mapActions('enrollment', [
        'getInsuredDetailsFromPolicyNumber',
        'addMissingPolicy',
        'sendContactChallenge',
        'answerChallengeAndAttachContact',
        'findInsuredByProvidedData',
        'isInsuredEnrolled']),
      async next () {
        // const workflowChain = ['search', 'insuredSelection', 'challenge', 'sendCode', 'success']
        //                                 ->                  ->           ->          ->
        //                                                                  <-
        // Or, alternatively, for organization, additional 2 steps included:
        //  ... challenge -> useAnotherEmail -> emailVerification -> success
        switch (this.workflow.page) {
        case 'search':
          await this.searchForPolicy()
          break
        case 'insuredSelection':
          this.goToChallenge()
          break
        case 'sendCode':
          await this.sendSecurityCode()
          break
        case 'challenge':
          await this.confirmChallenge()
          break
        case 'useAnotherEmail':
          await this.preVerifyEmail()
          break
        case 'emailVerification':
          await this.submitEmailVerificationCode()
          break
        case 'success':
          this.dialogWillClose()
          break
        }
      },
      back () {
        switch (this.workflow.page) {
        case 'insuredSelection':
          this.goTo('search')
          break
        case 'challenge':
          let targetPage = 'insuredSelection'
          if (this.onlyOneInsuredOnPolicy()) {
            targetPage = 'search'
          }
          this.challenge.sentMessage = null
          this.state.errorMessageDetail = null
          this.goTo(targetPage)
          break
        case 'sendCode':
          this.goTo('challenge')
          break
        case 'emailVerification':
          this.goTo('useAnotherEmail')
          break
        }
      },
      openDialog () {
        this.state.dialogVisible = true
        this.resetState()
      },
      resetState () {
        this.workflow.page = 'search'
        this.challenge.sentMessage = null
        this.state.loading = false
        this.state.policy_number = ''
        this.challenge.code = ''
        this.state.errorMessageDetail = null
      },
      async searchForPolicy () {
        this.beginLoading()
        let postData = {
          policy_number: this.state.policy_number,
          token: localStorage.token,
        }
        try {
          let response = await this.addMissingPolicy(postData)
          let resp = response.data
          if (!resp.success) {
            this.$elAlert(this.causeToString(resp.cause), this.t('global.error'))
            this.endLoading()
            return
          }
          if (resp.automatic_verification.available === true) {
            let promise = this.$store.dispatch('loadUserData')
            this.beginLoading()
            this.goTo('success')

            await promise
            this.endLoading()
            return
          }

          this.endLoading()
          this.searchState.insureds = resp.data
          this.searchState.registered_insureds = resp.registered_insureds
          this.searchState.selectedInsuredId = resp.data[0].id
          if (this.onlyOneInsuredOnPolicy()) {
            this.goToChallenge()
          } else {
            this.goTo('insuredSelection')
          }
        } catch (e) {
          this.endLoading()
          this.$elAlert('Failed to perform search. Check network connection or try again latter')
        }
      },
      goTo (page) {
        this.workflow.page = page
      },
      requestCode (via) {
        this.challenge.sendVia = via
        let actualMethods = this.searchState.selectedInsured.contactMethods.filter(el => el[via])
        this.challenge.selectedContactMethod = actualMethods[0]
        this.goTo('sendCode')
      },
      setupCustomEmails () {
        let rawList = _.map(this.contactDataToAttach.contact.emails, (emailObj) => { return emailObj.email })
        let baEmails = _.map(this.account.insureds, insured => { return insured.email.email })
        rawList.push(...baEmails)
        rawList = _.compact(_.uniq(rawList))
        this.emails.availableEmails = rawList
        let baEmail = this.account.insureds[this.contactDataToAttach.insured_id].email.email
        this.emails.toUse = baEmail || ''

        this.goTo('useAnotherEmail')
      },
      async confirmChallenge () {
        this.state.errorMessageDetail = null

        let payload = {
          company_id: settings.company_id,
          policy_number: this.state.policy_number,
          contact_id: this.searchState.selectedInsuredId,
          code: this.challenge.code,
          challengeType: this.challenge.type,
          token: localStorage.token
        }

        this.beginLoading()
        try {
          let response = await this.answerChallengeAndAttachContact(payload)
          this.endLoading()
          if (!response.data.success) {
            this.setErrorFromResponse()
            return
          }
          this.contactDataToAttach = response.data.data
          let promise = this.$store.dispatch('loadUserData')
          this.beginLoading()
          let alwaysCustomizeEmails = this.contactDataToAttach.offer_email_customization
          if (alwaysCustomizeEmails) {
            await promise
            this.endLoading()
            await this.setupCustomEmails()
          } else {
            this.goTo('success')
            await promise
            this.endLoading()
          }
        } catch (e) {
          this.endLoading()
          this.$elAlert(
            this.t('enrollment.error_msg_linked_policy_failure'),
            this.t('enrollment.error_msg_label')
          )
        }
      },
      goToChallenge () {
        let challengeType
        let insured = _.find(this.searchState.insureds, {id: this.searchState.selectedInsuredId})
        this.searchState.selectedInsured = insured
        if (this.searchState.selectedInsured.ssn) {
          challengeType = 'ssn'
        } else if (this.searchState.selectedInsured.dob) {
          challengeType = 'dob'
        } else {
          challengeType = 'portal_code'
        }
        this.challenge.type = challengeType
        this.goTo('challenge')
      },
      async sendSecurityCode () {
        this.beginLoading()
        let payload = this.buildPayloadToSendContactChallenge()
        try {
          let result = await this.sendContactChallenge(payload)
          this.endLoading()
          let ret = result.data
          if (!ret.success) {
            let message = ret.message !== 'General Error' ? ret.message : null
            message = message || this.t('enrollment.it_wasnt_possible_to_send_a_security_code')
            this.$elAlert(message, this.t('global.error'))
            return
          }

          this.securityCodeSent()
        } catch (e) {
          this.endLoading()
          this.$elAlert(this.t('enrollment.it_wasnt_possible_to_send_a_security_code'), this.t('global.error'))
        }
      },
      securityCodeSent () {
        let targetMessage
        if (this.challenge.selectedContactMethod.email) {
          targetMessage = this.t('enrollment.your_security_code_was_sent_via_email',
            {email: this.challenge.selectedContactMethod.email})
        } else {
          targetMessage = this.t('enrollment.your_security_code_was_sent_via_sms',
            {number: this.challenge.selectedContactMethod.phone})
        }
        this.challenge.sentMessage = targetMessage

        this.goTo('challenge')
      },
      async submitEmailVerificationCode () {
        this.resetEmailVerificationFailure()
        this.beginLoading()

        try {
          let response = await axios.post(config.confirm_email_by_code, {
            token: localStorage.token,
            confirmation_code: this.emails.verificationCode,
            insured_id: this.contactDataToAttach.insured_id,
            email: this.emails.toUse
          })
          this.endLoading()
          let cause = getCauseData(response)

          if (!cause.key) {
            this.didFailEmailVerificationWithReason(unexpectedErrorMessage)
            return
          }
          if (response.data.success) {
            await this.reloadAccountAndShowSuccess()
          } else {
            this.didFailEmailVerificationWithReason(this.t(cause.key, cause.params))
          }
        } catch (e) {
          this.didFailEmailVerificationWithReason(this.t('account.error_saving_contact_information'))
          this.endLoading()
        }
      },
      async reloadAccountAndShowSuccess () {
        this.beginLoading()
        let promise = this.$store.dispatch('account/loadAccount')
        this.goTo('success')
        await promise
        this.endLoading()
      },

      async preVerifyEmail () {
        // outcomes:
        // 1. need confirmation
        // 2. Added successfully
        // 3. Some error
        let needVerification = false

        // Happens when Britecore already have such email bound to this contact
        let confirmedAutomatically = false

        this.emails.verificationCode = ''
        this.resetEmailVerificationFailure()
        this.beginLoading()

        try {
          let response = await axios.post(config.change_email, {
            token: localStorage.token,
            new_email: this.emails.toUse,
            insured_id: this.contactDataToAttach.insured_id,
          })
          this.endLoading()
          let cause = getCauseData(response)

          if (response.data.success) {
            switch (cause.key) {
            case 'account.email_update_confirmation_sent':
              needVerification = true
              break
            case 'account.email_updated_immediately':
              confirmedAutomatically = true
              break
            }
          } else {
            // TODO: maybe this case should return true from backend?
            if (cause.key === 'account.no_actions_emails_equal') {
              confirmedAutomatically = true
            } else {
              this.$elAlert(this.t('account.error_saving_contact_information'), this.t('global.warning'))
              return
            }
          }
        } catch (e) {
          this.$elAlert(unexpectedErrorMessage)
          return
        }

        if (!needVerification && !confirmedAutomatically) {
          // Reached unexpected state, user will need to try again.
          return
        }

        if (needVerification) {
          this.goTo('emailVerification')
          return
        }

        if (confirmedAutomatically) {
          await this.reloadAccountAndShowSuccess()
        }
      },
      resetEmailVerificationFailure () {
        this.emails.verificationFailure = ''
      },
      didFailEmailVerificationWithReason (reason) {
        this.emails.verificationFailure = reason
      },
      onlyOneInsuredOnPolicy () {
        return this.searchState.insureds.length === 1
      },
      setErrorFromResponse () {
        let challengeMismatchErrs = {
          portal_code: 'policy_lookup_err_not_matching_code',
          ssn: 'policy_lookup_err_not_matching_ssn',
          dob: 'policy_lookup_err_not_matching_dob',
        }

        this.state.errorMessage = this.t('enrollment.policy_lookup_fail_reason')
        this.state.errorMessageDetail = this.t('enrollment.' + challengeMismatchErrs[this.challenge.type])
      },
      buildPayloadToSendContactChallenge () {
        return {
          company_id: settings.company_id,
          policy_number: this.state.policy_number,
          contact_id: this.searchState.selectedInsuredId,
          contact_method_id: this.challenge.selectedContactMethod['id'],
          contact_method_type: this.challenge.selectedContactMethod.hasOwnProperty('email') ? 'emails' : 'phones',
          preferred_language: localStorage.getItem('languageCode')
        }
      },
      beginLoading () {
        this.state.loading = true
      },
      endLoading () {
        this.state.loading = false
      },
      dialogWillClose () {
        this.state.dialogVisible = false
      }

    },
    computed: {
      ...mapGetters('account', ['account']),
      canGoNext () {
        if (this.workflow.page === 'search') {
          return this.state.policy_number !== ''
        }
        if (this.workflow.page === 'challenge') {
          return this.challenge.code !== ''
        }
        if (this.workflow.page === 'useAnotherEmail') {
          return this.emails.toUse !== ''
        }
        if (this.workflow.page === 'emailVerification') {
          return this.emails.verificationCode !== ''
        }

        return true
      },
      canGoBack () {
        if (this.workflow.page === 'search') {
          return false
        }
        if (this.workflow.page === 'success') {
          return false
        }
        if (this.workflow.page === 'useAnotherEmail') {
          return false
        }
        return true
      },
      dialogTitle () {
        switch (this.workflow.page) {
        case 'search':
          return this.t('enrollment.add_missing_policy_label')
        case 'insuredSelection':
          return this.t('enrollment.accounts_found')
        case 'challenge':
          return this.t('enrollment.verify_policy')
        case 'sendCode':
          return this.t('enrollment.verify_policy')
        case 'useAnotherEmail':
          return this.t('enrollment.choose_email_step_headline')
        case 'emailVerification':
          return this.t('enrollment.choose_email_step_headline')
        default:
          return this.t('enrollment.add_missing_policy_label')
        }
      },
      nextButtonText () {
        if (this.workflow.page === 'insuredSelection') {
          return this.t('enrollment.verify_account')
        }
        if (this.workflow.page === 'success') {
          return this.t('global.done')
        }
        if (this.workflow.page === 'sendCode') {
          return this.t('enrollment.send_security_code')
        }
        return this.t('global.next')
      }

    },
  }
</script>


<style scoped lang="scss">
  .add-missing-policy__dialog-title {
    display: flex;
    align-items: center;
  }
</style>

