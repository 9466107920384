/* eslint-disable */
import Vue from 'vue'
import Vuex, {ActionContext} from 'vuex'
import axios from 'axios'

import content from '../../config/content'
import settings from '../../config/settings'

import loginModule from './login.module'
import commonModule from '@shared_src/store/common.module'
import enrollmentModule from '@shared_src/store/enrollment.module'
import policiesModule from '@shared_src/store/policies.module'
import accountModule from '@shared_src/store/account.module'
import paymentsModule from '@shared_src/store/payments.module'
import agentsModule from '@shared_src/store/agents.module'
import claimsModule from '@shared_src/store/claims.module'
import documentsModule from '@shared_src/store/documents.module'
import passwordResetModule from '@shared_src/store/password-reset.module'
import messageCenterModule from '@shared_src/store/message-center.module'
import customPagesModule from '@shared_src/store/custom-pages.module'
import config from "../../config/britecore";
import agentKeys from '../../shared_src/policies/agent-keys.helper'


let storeDescription = {
  state: {
    loading: false,
  },
  modules: {
    common: commonModule,
    enrollment: enrollmentModule,
    login: loginModule,
    policies: policiesModule,
    account: accountModule,
    agents: agentsModule,
    payments: paymentsModule,
    claims: claimsModule,
    documents: documentsModule,
    passwordReset: passwordResetModule,
    messageCenter: messageCenterModule,
    customPages: customPagesModule,
  },
  mutations:{
    setLoading (state, payload) {
      state.loading = payload
    },
  },
  actions: {
    async init (context, authDict) {
       async function updateWebVersion () {
        try {
          const updatedBuildVersion = await axios.get(`${config.url}/company/${settings.company_id}/builds/version/desktop/`);
          const currentBuildVersion = await axios.get('version.txt');
          let lines = currentBuildVersion.data.split("\n")
          if (updatedBuildVersion.data.success && updatedBuildVersion.length) {
            if (lines[0] && lines[0] !== 'web_unknown' && lines[0] !== updatedBuildVersion.data.version) {
              if (localStorage.getItem('language') && localStorage.getItem('content')) {
                let lang = localStorage.getItem('language')
                let currentContent = content[lang]
                Vue.prototype.$alert(
                  currentContent.global.get_the_latest_version_of_application,
                  currentContent.global.notice, {
                    confirmButtonText: currentContent.global.ok,
                    callback: () => {
                      location.reload()
                    },
                  })
              }
            }
          } else {
            localStorage.versionStr = 'web_' + lines[0] + '_' + lines[3] + '_' + lines[5]
          }
        } catch (e) {
          // console.log('error:', e)
          localStorage.versionStr = 'web_unknown'
        }
      }
      updateWebVersion()
      context.commit('common/setSettings', settings)
      context.commit('common/initLanguage')
      context.commit('common/initContent')
      context.dispatch('common/loadContent')
      context.dispatch('common/loadSettings', true)
      context.dispatch('common/loadFeatureFlags')
      context.dispatch('customPages/loadPages')
      context.dispatch('common/loadSessionExpiration')
      try {
        await context.dispatch('login/init', authDict)
        await context.dispatch('documents/getSecretKey')
      }  catch (err) {
        // login failed
      }
    },
    loadUserData: function (context: ActionContext, payload) {
      context.commit('setLoading', true)
      return Promise.all([
        context.dispatch('common/loadContact'),
        context.dispatch('account/loadAccount'),
        context.dispatch('policies/reload', 1),
        context.dispatch('agents/getPrimaryCarrier'),
        context.dispatch('messageCenter/loadNotifications'),
      ]).then(function() {
        let policies = context.state.policies.policies.policies
        let keys = agentKeys(policies)
        context.commit('setLoading', false)
        context.dispatch('agents/loadAgentsContactsData', { contactIdList: keys })
      })
    }
  },
  strict: process.env.NODE_ENV !== 'production' // enable it only for dev "to avoid the performance cost on prod."
}

Vue.use(Vuex)

const store = new Vuex.Store(storeDescription)

export default store
