/* eslint-disable */

import MyPolifils from './polyfill'
import Vue from 'vue'
import VueRouter from 'vue-router'
import BcDesignSystem from '@britecore/bc-design-system'
import ElementUtils from './utils/element-utils'
import App from './components/app'
import i18n from './utils/i18n'
import '@shared_src/filters'

import Meta from 'vue-meta'
import apiUtils from '@shared_src/utils/api.utils'
import {logInsuredAction} from '@shared_src/utils/api.utils'
import {deepClone} from '@shared_src/utils/misc.util'
import additionalVueFunctions from "@shared_src/utils/additionalVueFunctions"

import 'intl-tel-input/build/css/intlTelInput.css'

Vue.use(VueRouter)
Vue.use(BcDesignSystem)
Vue.use(ElementUtils)
Vue.use(i18n)
Vue.use(Meta)
Vue.use(apiUtils)
Vue.use(additionalVueFunctions)


import 'font-awesome/css/font-awesome.css'
import AppStyles from './sass/main.scss'

import store from '../web-src/store'
import '../web-src/shared/axios.interceptors'

import PaymentMethodIcon from './pages/payments-redesign/payment-methods/payment-method-icon.component.vue'
import Navbar from './components/navbar.vue'
import Footer from './components/footer.vue'
import GeneralImage from './components/general-image.vue'
import PhoneNationalFormat from '../shared_src/phone-wrap/phone-national-format.component.vue'

import '@shared_src/icons/font-awesome.includes'

import moment from 'moment'
import Routes from './routes.js'

Vue.component('bc-navbar', Navbar)
Vue.component('bc-footer', Footer)
Vue.component('bc-payment-method-icon', PaymentMethodIcon)
Vue.component('bc-general-image', GeneralImage)
Vue.component('bc-phone-national-format', PhoneNationalFormat)

const router = new VueRouter({routes: Routes})

let firstNav = true

router.afterEach((to, from) => {
  if (firstNav) { //it's first resolved nav call. We need to figure out if it is impersonation happening
    firstNav = false
    // check query params
    let query = router.currentRoute.query
    let username = query.username
    let password = query.password
    let usernameProvided = 'username' in query
    let passwordProvided = 'password' in query
    if (usernameProvided && passwordProvided) {
      store.commit('login/setSuppressLogInsuredAction', true)
    }
  }
  if (store.state.login.suppressLogInsuredAction) return
  let message = 'Navigating to ' + to.fullPath
  let messageCode = 'router_navigation'
  let extraVars = {url: to.fullPath}
  logInsuredAction(message, messageCode, extraVars)
})

window.__vue_router = router


// Init App
window.vueInstance = new Vue({
  el: '#app',
  store,
  router,
  template: '<app @startApp="startApp" />',
  components: {
    app: App
  },
  methods: {
    async startApp() {
      let routerPath = this.$router.currentRoute.fullPath
      if (routerPath.startsWith('/enrollment_initiation')) {
          // no matter logged in or not - go there without data load
          // allow the user to proceed with enrollment initiation
          return
      }

      if (this.$store.state.login.isAuthorized) {
        await this.$store.dispatch('loadUserData')
        let last_user =  store.getters['account/username']
        localStorage.setItem('last_user', last_user)

        if (store.getters['account/tos']) {
          /* If ToS is loaded, needs to be agreed */
          router.push('/enrollment/terms-of-service/0')
          return
        }

        if (!this.$route.matched.length) {
          router.push('/dashboard')
        }
      } else {
        if (routerPath.startsWith('/enrollment/')) {
          // allow the user to proceed with enrollment
          return
        }
        if (!routerPath.startsWith('/login')) {
          router.push('/login')
        }
      }
    }
  },
  created: function () {
    let query = deepClone(this.$route.query)
    let username = query.username
    let password = query.password
    let usernameProvided = 'username' in query
    let passwordProvided = 'password' in query
    if(usernameProvided) delete query.username
    if(passwordProvided) delete query.password

    if (usernameProvided || passwordProvided) {
      this.$router.replace({ query })
    }
    let authDict = (usernameProvided && passwordProvided) ? {username, password} : null

    if(authDict) {
      // clean it here to avoid making new calls on old user behalf
      store.commit('login/removeTokenAndLoginTime')
    }

    this.initPromise = store.dispatch('init', authDict)
  },
  mounted: function () {
    this.initPromise.then(() => {
      this.startApp();
    })
  },
});
