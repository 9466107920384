<template>
  <div class="js-vue-forced">
    <el-row v-if="columns" :gutter="30">
      <el-col v-for="(col, index) in columns" :key="index" :span="24 / columnsInRiskDisplay" :xs="24">
        <div v-for="(field, index) in col" :key="index">
          <component :is="decideOnRenderComponent(field)" :field="field"/>
        </div>
        <ba-risk-repeated-fields v-if="col.fields" :fields="col.fields"/>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import BaPrimitiveField from './primitive-field.component'
  import BaArrayField from '@/pages/policies/dynamic/array-field.component'

  export default {
    name: 'ba-risk-repeated-fields',
    components: {BaArrayField, BaPrimitiveField},
    props: ['fields', 'riskType'],
    methods: {
      decideOnRenderComponent (field) {
        if (field.show_as === 'primitive') {
          return 'ba-primitive-field'
        }
        if (field.show_as === 'array') {
          return 'ba-array-field'
        }
      }
    },
    computed: {
      columnsInRiskDisplay () {
        switch (this.riskType) {
        case 'drivers':
          return 1
        case 'vehicle':
          return 3
        }
        return 1 // non-type components which are fields should be at max width
      },
      columns () {
        let col1 = []
        let col2 = []
        let col3 = []
        let result = []
        for (let index in this.fields) {
          if (!this.fields.hasOwnProperty(index)) {
            continue
          }
          let _ = this.fields[index]

          if (_.label === 'auto.VIN') {
            col1.push(_)
            continue
          }
          if (_.label === 'auto.garaging_location') {
            col2.push(_)
            continue
          }
          if (_.label === 'auto.drivers') {
            col3.push(_)
            continue
          }
          //  else
          col1.push(_)
        }
        result.push(col1)
        result.push(col2)
        result.push(col3)
        return result
      }
    },
  }
</script>
