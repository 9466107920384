/* eslint-disable */

import axios from 'axios'
import config from '../../config/britecore'
import agentKeys from '../policies/agent-keys.helper'
import {ActionContext} from 'vuex'
import {axiosWrapper, deepClone, sleep} from '@shared_src/utils/misc.util'
import {localize as t} from '@/utils/i18n'
import moment from 'moment'
import { sanitizeHTML } from 'simple-sanitizer'

function PoliciesModule () {
  this.loaded = false
}

PoliciesModule.prototype.clearCache = function () {
  this.loaded = false
}

PoliciesModule.prototype.load = async function (context: ActionContext) {
  if (this.loaded) {
    return
  }

  let response = await axios.post(config.get_policies_url, {token: localStorage.token})
  try {
    let dataToStore = response.data.data
    let agentToPolicies = {}
    if (dataToStore.policies) {
      let claims = []
      dataToStore.policies.forEach((policy) => {
        policy.claims.forEach((claim) => {
          claims.push(claim)
        })
        policy['current_due'] = Number(policy['current_due'])
        policy['next_due'] = Number(policy['next_due'])
        policy['payoff_amount'] = Number(policy['payoff_amount'])
        policy['due'] = 'Stop using this value in our application'
        // this value should be used to check active/canceled policy
        policy.is_canceled = policy.policy_status.startsWith('Canceled')

        let agencyContactId = policy.agency_contact_id
        let agentContactId = policy.agent_contact_id

        function addPolicy(key, policy) {
          agentToPolicies[key].policies.push({
            policy_number: policy.policy_number,
            policy_id: policy.id,
            policy_type: policy.policy_type,
            policy_status: policy.policy_status
          })
        }

        if (agentContactId) {
          if (!agentToPolicies.hasOwnProperty(agentContactId)) {
            agentToPolicies[agentContactId] = {
              type: 'agent',
              agent_id: agentContactId,
              agency_id: agencyContactId,
              id: agentContactId,
              policies: [],
              contact: {}
            }
          }

          addPolicy(agentContactId, policy)
        }

        if (agencyContactId) {
          if (!agentToPolicies.hasOwnProperty(agencyContactId)) {
            agentToPolicies[agencyContactId] = {
              type: 'agency',
              agency_id: agencyContactId,
              id: agencyContactId,
              policies: [],
              contact: {}
            }
          }

          addPolicy(agencyContactId, policy)
        }

      })
      context.commit('claims/setClaims', claims, { root: true })
      // TODO: perhaps return all agent data with `get_policies_url` and not later...
      context.commit('agents/setAgentToPolicies', agentToPolicies, { root: true })
      let keys = agentKeys(dataToStore.policies)
      context.dispatch('agents/loadAgentsContactsData', { contactIdList: keys }, { root: true })

    }
    context.commit('setPolicies', dataToStore)

    context.dispatch('payments/getOwnedPaymentMethods', {}, {root: true})
      .catch(error => {
        console.error('cannot load owned payment methods', error)
      })


    let policies = dataToStore.policies

    // Only load Gen3 claims if we have Gen3 policies
    let gen3Policies = policies.find(p => p.isGen3)
    if (gen3Policies && context.rootGetters['common/getSetting']('claims.claims_enabled')) {
      await context.dispatch('claims/getGen3Claims', {}, { root: true })
    }

    if (dataToStore && dataToStore.policies.length) {
      dataToStore.policies.forEach(policy => {
        let historyPayload = {
          policyId: policy.id,
          currentTermId: policy.current_term.id,
          nextTermId: policy.next_policy_term_id
        }

        context.dispatch('payments/loadAccountHistory', historyPayload, {root: true})
        .catch((error) => {
          console.warn('account history in not loaded')
        })

        if (policy.isGen3) {
          context.dispatch(
            'policies/getRevisionRiskDataWithFallback',
            {
              policyId: policy.id,
              policyType: policy.policy_type
            },
            { root: true }
          ).catch((error) => {
            console.warn('risk data not loaded', policy.id)
          })
        }
        if (policy.gen2_auto) {
          // load drivers and vehicles to cache to avoid UI glitches on claims page.
          // TODO we can move these calls to claims page to avoid "requests attack" on dashboard load
          context.dispatch(
            'policies/getDriversGen2AutoWithCache',
            policy.revision.id,
            { root: true }
          ).catch((error) => {
            console.warn('drivers data not loaded', policy.id)
          })
          context.dispatch(
            'policies/getVehiclesGen2AutoWithCache',
            policy.revision.id,
            { root: true }
          ).catch((error) => {
            console.warn('vehicles data not loaded', policy.id)
          })
        }
      })

    }
    this.loaded = true
  }
  catch (response) {
    console.error(response, 'error in PoliciesModule->load; (get_policy response)')
    return Promise.reject(response)
  }
}


let instance = new PoliciesModule()

const policiesModule = {
  namespaced: true,
  state: {
    policies: null,
    property: null,
    insured: null,
    uploadedPhoto: null,
    photoMetadata: null,
    preferredPhoto: '',
    policyRevsByDates: {},  // contains policyId + Date for revision check + revisionId. Gen3-only data.
    risksByRevs: {},  // contains {_revId_: _risks} . Gen3-only data.
    driversByRevsGen2Auto: {}, // contains {_revId_: _drivers} . Gen2Auto-only data.
    vehiclesByRevsGen2Auto: {}, // contains {_revId_: _drivers} . Gen2Auto-only data.
  },
  mutations: {
    setPolicies (state, payload) {
      state.policies = payload
    },
    setProperty (state, payload) {
      state.property = payload
    },
    setInsured (state, payload) {
      state.insured = payload
    },
    setUploadedPhoto (state, payload) {
      state.uploadedPhoto = payload
    },
    setPhotoMetadata (state, payload) {
      state.photoMetadata = payload
    },
    setPreferredPhoto (state, payload) {
      state.preferredPhoto = payload
    },
    setPolicyRevByDate (state, payload) {
      let policyId = payload.policyId
      let revisionDate = payload.revisionDate
      let revisionId = payload.revisionId
      if (!(policyId in state.policyRevsByDates)) state.policyRevsByDates[policyId] = {}
      state.policyRevsByDates[policyId][revisionDate] = revisionId
      state.policyRevsByDates = deepClone(state.policyRevsByDates) // deepClone data to trigger getters/computed
    },
    setRisksByRev (state, payload) {
      state.risksByRevs[payload.revisionId] = payload.risks
      state.risksByRevs = deepClone(state.risksByRevs) // deepClone data to trigger getters/computed
    },
    setDriversByRevsGen2Auto (state, payload) {
      state.driversByRevsGen2Auto[payload.revisionId] = payload.drivers
      state.driversByRevsGen2Auto = deepClone(state.driversByRevsGen2Auto) // deepClone data to trigger getters/computed
    },
    setVehiclesByRevGen2Auto (state, payload) {
      state.vehiclesByRevsGen2Auto[payload.revisionId] = payload.vehicles
      state.vehiclesByRevsGen2Auto = deepClone(state.vehiclesByRevsGen2Auto) // deepClone data to trigger getters/computed
    },
  },
  actions: {
    reload: function getPoliciesPromise (context) {
      instance.clearCache()
      return instance.load(context)
    },
    load: function getPoliciesPromise (context) {
      return instance.load(context)
    },
    retrieveProperty: function retrievePropertyPromise(context, propertyId) {
      return new Promise((resolve, reject) => {
        axios.post(config.get_property_url, {token: localStorage.token, property_id:propertyId})
        .then(response => {
          let dataToStore = response.data.data

          if (dataToStore.photos.preferred) {
            // a preferred means that at least one photo exists
            let preferredPhotoId = dataToStore.photos.preferred.id;
            ['carrier', 'insured'].forEach((target) => {
              let dataLength = dataToStore.photos[target].length
              for (let i = 0; i < dataLength; i++) {
                let curPhoto = dataToStore.photos[target][i]
                if (curPhoto.id === preferredPhotoId)  {
                  context.commit('setPreferredPhoto', curPhoto.id)
                  curPhoto.isPreferred = true
                }
              }
            })
          }

          context.commit('setProperty', dataToStore)
          resolve()
        }, response => {
          reject(response)
        })
      })
    },
    retrieveInsured: function retrieveInsuredPromise(context, contactId) {
      return new Promise((resolve, reject) => {
        axios.post(config.get_contact_url, {token: localStorage.token, contact_id:contactId})
        .then(response => {
          let dataToStore = response.data.data
          context.commit('setInsured', dataToStore)
          resolve()
        }, response => {
          reject(response)
        })
      })
    },
    getPolicyTerms: function getPolicyTermsPromise(context, policyId) {
      return new Promise((resolve, reject) => {
        axios.post(config.get_policy_terms_url, {token: localStorage.token, policy_id: policyId})
        .then(response => {
          let data = response.data.data
          resolve(data)
        }, response => {
          reject(response)
        })
      })
    },
    uploadPropertyPhoto: function uploadPropertyPhoto(context, payload) {
      let propertyId = payload.propertyId
      let filename = context.state.uploadedPhoto.name
      let mimetype = context.state.uploadedPhoto.type
      let policyNumber = payload.policyNumber
      return new Promise((resolve, reject) => {
        axios.post(config.add_property_photo_url, {
          token: localStorage.token,
          file_id: '',
          caption: sanitizeHTML(payload.caption),
          set_as_preferred: payload.preferred,
          photo: payload.photo,
          property_id: propertyId,
          filename: filename,
          mimetype: mimetype,
          policy_number: policyNumber,
        }).then(response => {
          resolve(response.data)
        }, response => {
          reject(response)
        })
      })
    },
    getPropertyPhotoMetadata: function getPropertyPhotoMetadata(context, payload) {
      let fileId = payload.fileId

      return new Promise((resolve, reject) => {
        axios.post(config.get_property_photo_metadata_url, {
          token: localStorage.token,
          file_id: fileId
        }).then(response => {
          let photoMetadata = response.data.data
          context.commit('setPhotoMetadata', photoMetadata)
          resolve(photoMetadata)
        }, response => {
          reject(response)
        })
      })
    },
    updatePropertyPhoto: function updatePropertyPhoto(context, payload) {
      let propertyId = payload.propertyId
      let caption = payload.caption
      let setAsPreferred = payload.setAsPreferred
      let fileId = payload.fileId

      return new Promise((resolve, reject) => {
        axios.post(config.modify_property_photo_url, {
          token: localStorage.token,
          property_id: propertyId,
          caption: sanitizeHTML(caption),
          set_as_preferred: setAsPreferred,
          file_id: fileId
        }).then(response => {
          resolve()
        }, response => {
          reject(response)
        })
      })
    },
    async deletePropertyPhoto (context, fileId) {
      return await axiosWrapper(
        axios.post(config.delete_property_photo_url, {
          token: localStorage.token,
          file_id: fileId,
        })
      )
    },
    async getRiskData(context, policyInfo) {
      return (await axios.post(config.get_riskdata_url, {
        token: localStorage.token,
        policyType:policyInfo.type,
        revisionId:policyInfo.revisionId}
      )).data
    },
    async getRevisionByEffectiveDate(context, payload) {
      return axiosWrapper(
        axios.get(config.get_revision_by_date_url, {
          params: {policy_id: payload.policyId, effective_date: payload.effectiveDate},
          headers: {Authorization: localStorage.token},
        })
      )
    },
    async getRevisionByRevisionDateCached(context, payload) {
      let policyId = payload.policyId
      let revisionDate = payload.revisionDate
      let state = context.state
      let dispatch = context.dispatch
      let revisionId = null // null means no revision for this date found!
      if (!(policyId in state.policyRevsByDates) || !(revisionDate in state.policyRevsByDates[policyId])) {
        try {
          let response = await dispatch('getRevisionByEffectiveDate', {policyId, effectiveDate: revisionDate})
          revisionId = response.data
        } catch (error) {
          console.warn(error)
        }
        context.commit('setPolicyRevByDate', {policyId, revisionDate, revisionId})
      }
      return state.policyRevsByDates[policyId][revisionDate]
    },
    async getRisksByRevIdWithCache(context, payload) {
      let state = context.state
      let dispatch = context.dispatch
      let revisionId = payload.revisionId
      let policyId = payload.policyId  // need this to determine policyType
      let risks = state.risksByRevs[revisionId]
      if (!risks) {
        let policyType = null
        if (state.policies && state.policies.policies) {
          let policy = state.policies.policies.find(p => p.id === policyId)
          if (policy) policyType = policy.policy_type
        }
        //  payload.policyType is workaround when we don't have  state.policies populated
        if (!policyType) policyType = payload.policyType
        risks = await dispatch('getRiskData', {revisionId, type: policyType})
        context.commit('setRisksByRev', {revisionId, risks})
      }
      return risks
    },
    async getRevisionRiskDataWithFallback(context, payload) {
      // regular getRevisionRiskData operates with revisionDate. for policy load we use "today". however, some policies could be "not started" or "ended up". For them we need to retrieve data by revision id passed in policy
      let policyId = payload.policyId
      if (!payload.revisionDate) payload.revisionDate = moment(new Date()).format('YYYY-MM-DD')
      let policyType = payload.policyType //optional, used ONLY in load Policies
      let dispatch = context.dispatch
      let revisionId = null
      try {
        revisionId = await dispatch('getRevisionByRevisionDateCached', payload)
      } catch (response) {
        // cannot retrieve by date, try by revId
        console.warning(response, 'error in PoliciesModule->getRevisionRiskData')
      }
      if (!revisionId) {
        // failed to get revision for current time. Trying to get risks for revision in policy body
        let policy = state.policies.policies.find(p => p.id === policyId)
        revisionId = policy.revision.id
      }
      return await dispatch('getRisksByRevIdWithCache', {revisionId, policyType, policyId})
    },
    async getRiskDataForPolicyCard(context, policyId) {
      return context.getters.riskDataForClaimsConverter(
          await context.dispatch('getRevisionRiskDataWithFallback',{policyId}))
    },
    async getDriversGen2AutoWithCache(context, revisionId) {
      let state = context.state
      let dispatch = context.dispatch
      let drivers = state.driversByRevsGen2Auto[revisionId]
      if (drivers == 'pending') {
        // someone else trying to fetch data, let's wait a bit for result
        let iteration = 0
        while ((drivers == 'pending') && (iteration < 10)) {
          await sleep(500)
          iteration = iteration +1
          drivers = state.driversByRevsGen2Auto[revisionId]
        }
        if (drivers && drivers !== 'pending') return drivers
        drivers = null
      }
      if (!drivers) {
        context.commit('setDriversByRevsGen2Auto', {revisionId, drivers: 'pending'}) // flag we're retrieving data
        try {
          drivers = (await dispatch('getDriversGen2Auto', revisionId)).data
          context.commit('setDriversByRevsGen2Auto', {revisionId, drivers})
        } catch (err) {
          // remove pending state on error
          context.commit('setDriversByRevsGen2Auto', {revisionId, drivers: null})
          throw err
        }
      }
      return drivers
    },
    async getVehiclesGen2AutoWithCache(context, revisionId) {
      let state = context.state
      let dispatch = context.dispatch
      let vehicles = state.vehiclesByRevsGen2Auto[revisionId]
      if (vehicles == 'pending') {
        // someone else trying to fetch data, let's wait a bit for result
        let iteration = 0
        while ((vehicles == 'pending') && (iteration < 10)) {
          await sleep(500)
          iteration = iteration +1
          vehicles = state.vehiclesByRevsGen2Auto[revisionId]
        }
        if (vehicles && vehicles !== 'pending') return vehicles
        vehicles = null
      }
      if (!vehicles) {
        context.commit('setVehiclesByRevGen2Auto', {revisionId, vehicles: 'pending'}) // flag we're retrieving data
        try {
          vehicles = (await dispatch('getVehiclesGen2Auto', revisionId)).data
          context.commit('setVehiclesByRevGen2Auto', {revisionId, vehicles})
        } catch (err) {
          // remove pending state on error
          context.commit('setVehiclesByRevGen2Auto', {revisionId, vehicles: null})
          throw err
        }
      }
      return vehicles
    },
    async getDriversGen2Auto(context, revisionId) {
      return await axiosWrapper(
        axios.post(config.get_drivers_gen2auto_url, {
          revision_id: revisionId,
          token: localStorage.token
        })
      )
    },
    async getVehiclesGen2Auto(context, revisionId) {
      return await axiosWrapper(
        axios.post(config.get_vehicles_gen2auto_url, {
          revision_id: revisionId,
          token: localStorage.token
        })
      )
    },
  },
  getters: {
    hasAtLeastOnePolicy: (state, getters) => {
      if (!state.policies)
      {
        return false
      }

      if (state.policies.policies.length === 0) {
        return false
      }

      return true
    },
    hasOneActivePolicy: (state, getters) => {
      if (!state.policies) {
        return false
      }
      if (state.policies.policies.length === 0) {
        return false
      }
      return !state.policies.policies.every(policy => policy.is_canceled)
    },
    policyById: (state, getters) => (id) => {
      if (!state.policies)
      {
        return null
      }
      let policies = state.policies.policies
      if (policies) {
        return policies.find(p => p.id === id)
      }
      else {
        return null
      }
    },
    policyByNumber: (state, getters) => (policy_number) => {
      if (!state.policies)
      {
        return null
      }
      let policies = state.policies.policies
      if (policies) {
        return policies.find(p => p.policy_number === policy_number)
      }
      else {
        return null
      }
    },
    policyFromPropertyId: (state, getters) => (id) => {
      let policies = state.policies.policies
      if (!policies) return null
      let result = policies.find(policy => policy.properties.find(p => p.id === id))
      return result
    },
    policiesListByType (state, getters) {
      if (!state.policies) return null
      let activePolicies = []
      let canceledPolicies = []
      state.policies.policies.forEach(policy => {
        if (policy.is_canceled) {
          canceledPolicies.push(policy)
        } else {
          activePolicies.push(policy)
        }
      })
      return {
        active: activePolicies,
        canceled: canceledPolicies
      }
    },
    rawRiskDataForPolicy: (state, getters) => (policyId) => {
      // retrieves stored data
      let revisionDate = moment(new Date()).format('YYYY-MM-DD')
      if(!(policyId in state.policyRevsByDates) || !(revisionDate in state.policyRevsByDates[policyId])) return null // no data
      let revisionId = state.policyRevsByDates[policyId][revisionDate]
      if (!revisionId) {
        let policy = getters.policyById(policyId)
        if (!policy) return
        revisionId = policy.revision.id
      }
      return state.risksByRevs[revisionId]
    },
    riskDataForPolicyCard: (state, getters) => (policyId) => {
      // retrieves stored data and convert it
      let riskData = getters.rawRiskDataForPolicy(policyId)
      if (!riskData) return null
      return getters.riskDataForClaimsConverter(riskData)
    },
    riskDataForClaimsConverter: (state) => (rawRiskData) => {
      let risks = []
      rawRiskData.sections.forEach(section => section.items.forEach(item => {
        if (item.is_claim_risk) {
          risks.push(item)
        }
      }))
      return risks
    },
    determinePolicyIcon: (state, getters) => (policy) => {
      let polType = policy.policy_type.toLowerCase()
      if (polType.includes('boats')) {
        return ['fas', 'ship']
      }
      if (polType.includes('auto')) {
        return ['fas', 'car']
      }
      return ['fas', 'home']
    },
    riskLabelByPolicy: (state, getters) => (policy, plural) => {
      if (policy.gen2_auto) {
        if (plural) {
          return t('auto.vehicles')
        } else {
          return t('auto.vehicle')
        }
      }
      let polType = policy.policy_type.toLowerCase()
      if (polType.includes('boats')) {
        // no plural here
        return t('watercraft.watercraft_details')
      } else if (polType.includes('auto')) {
        if (plural) {
          return t('auto.vehicles')
        } else {
          return t('auto.vehicle')
        }
      } else {
        if (plural) {
          return t('policies.location_title_plural')
        } else {
          return t('policies.location_title_singular')
        }
      }
    },
    policiesMarkedByPaymentType (state, getters) {
      if (!state.policies) return null
      return state.policies.policies.map(policy => {
        let obj = Object.assign({}, policy);
        if (policy.payoff_amount > 0) {
          obj.paymentType = 'entireTerm'
        }
        if (policy.current_due > 0) {
          obj.paymentType = 'currentDue'
        }
        if (policy.payoff_amount === 0 && policy.next_due > 0) {
          obj.paymentType = 'nextTerm'
        }
        return obj
      })
    },
    orderedPolicies (state, getters) {
      if (!getters.policiesMarkedByPaymentType) return null
      const comparePaymentsType = (policy) => {
        if (policy.is_canceled) return Infinity  // Canceled policies moved to the end
        else {
          if (policy.paymentType === 'currentDue')  {
            let currentDate = new Date()
            let dueDate = new Date(policy.due_date)
            if (currentDate > dueDate) return 1 // Overdue
            return 2 // Currently Due
          }
          if (policy.paymentType === 'entireTerm') return 3 // Entire Term
          if (policy.paymentType === 'nextTerm') return 4 // Next Term
          return 5  // Other types
        }
      }
      return getters.policiesMarkedByPaymentType.sort((a, b) => comparePaymentsType(a) - comparePaymentsType(b))
    },
    policyShortHeadline: (state, getters) => (policy, mandatoryPolicyType) => {
      // headline without policy number.
      // if mandatoryPolicyType is true - policy type will be included in result in any scenario
      function formatResult(mandatoryPolicyType, policyType, shortResult) {
        return mandatoryPolicyType ? `${policyType} - ${shortResult}`: shortResult
      }
      let policyType = policy.policy_type
      if (policy.isGen3) {
        let riskData = getters.riskDataForPolicyCard(policy.id)
        if (riskData) {
          let totalRisks = riskData.length
          let label = getters.riskLabelByPolicy(policy, true)
          if (totalRisks > 1) {
            let shortResult = `${totalRisks} ${label}`
            return formatResult(mandatoryPolicyType, policyType, shortResult)
          } else {
            return policyType
          }
        } else {
          return policyType
        }
      }
      if (policy.gen2_auto) {
        let label = getters.riskLabelByPolicy(policy, true)
        let vehiclesAmount = policy.properties.length
        if (vehiclesAmount > 1) {
          let shortResult = `${vehiclesAmount} ${label}`
          return formatResult(mandatoryPolicyType, policyType, shortResult)
        } else if (vehiclesAmount === 1) {
          let vehicle = policy.properties[0]
          let shortResult = `${vehicle.vehicle_year} ${vehicle.vehicle_make} ${vehicle.vehicle_model}`
          return formatResult(mandatoryPolicyType, policyType, shortResult)
        } else {
          return policyType
        }
      }
      if (policy.properties && policy.properties.length > 0) {
        let propsAmount = policy.properties.length
        if (propsAmount > 1) {
          let shortResult = t('payments.properties_number', {number: propsAmount})
          
          return formatResult(mandatoryPolicyType, policyType, shortResult)
        }
        let shortResult = policy.properties[0].address_line1
        return formatResult(mandatoryPolicyType, policyType, shortResult)
      } else {
        return policyType
      }
    },
  }
}

export default policiesModule
