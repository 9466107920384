// @flow
/* eslint-disable */

const allowedPrefixes: Array<string> = [
  '/enrollment',
  '/password-reset',
  '/custom'
]
const allowedFullPaths: Array<string> = [
  '/password-update-required',
   '/maintenance'
]

export function isAllowedPage (path: string): boolean {
  // Given the full url, determine, whether not-authorized user has access to this page
  for (let _ of allowedPrefixes) {
    if (path.startsWith(_)) {
      return true
    }
  }

  for (let _ of allowedFullPaths) {
    if (path === _) {
      return true
    }
  }

  return false
}
