<template>
  <div v-if="policiesListByType">
    <div class="payments-card dashboard__outer-card_info" v-if="!useExternalPaymentSite">
      <div class="el-card__header"  v-loading="!computePoliciesByType">{{ t('dashboard.payments') }}</div>
      <h2 class="u-flex-auto u-mb5" v-if="!useExternalPaymentSite && computePoliciesByType">
        <!--this is first line. Overdue/due/nothing to pay could be here-->
        <div :class="paymentCard.headlineClass" class="u-flex-align-items">
          <fa-icon v-if="paymentCard.headlineClass==='u-danger'"
                   :icon="[ 'fal', 'exclamation-circle']" class="image-icon"
          />
          <div class="u-text-trim">{{ paymentCard.headline }}</div>
        </div>
        <div v-if="paymentCard.headline2" class="u-flex-align-items headline2">
          <div class="u-text-trim">{{ paymentCard.headline2 }}</div>
        </div>
        <router-link  v-if="paymentCard.description"  to="/payments" class="el-card__sub-link subheader u-mt2">
          {{ paymentCard.description }}
        </router-link>
      </h2>
      <div class="payments-card__links payment-card-links u-mt4" v-if="!useExternalPaymentSite && computePoliciesByType && numPoliciesThatAllowPayments > 0">
        <el-button @click="processPayments" class="u-text-capitalize u-width100" type="info">
          {{ paymentCard.actionText }}
        </el-button>
      </div>
    </div>

    <div class="payments-card dashboard__outer-card_info" v-else>
      <div class="el-card__header"  v-loading="!computePoliciesByType">{{ t('dashboard.payments') }}</div>
      <h2 class="u-mt0 u-mb5">{{ t('payments.use_external_payment_site_dashboard_label')}}</h2>
      <div class="payments-card__links payment-card-links u-mt4" >
        <el-button class="u-text-capitalize u-width100" type="info" @click="openExternalSite()">
          {{ t('dashboard.payments') }}
        </el-button>
      </div>
    </div>

    <!-- Payment Dialog-->
    <bc-payment-dialog
        @handleMakePaymentsDialog="handleMakePaymentsDialog"
        :openPaymentDialog="openPaymentDialog"
        :checkedPolicies="policiesForPay"
        :activePayType="activePayType"
    />

  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex'
import {getEarliestDate} from '@shared_src/utils/misc.util'
import BcPaymentDialog from '../payments-redesign/make-payments/payment-dialog'
import { isPaymentAllowed } from '@shared_src/utils/payments.utils'

export default {
  name: 'payment-card',
  components: {
    BcPaymentDialog,
  },
  watch: {
    computePoliciesByType: {
      handler (val) {
        this.policiesForSinglePayment()
      },
      immediate: true
    },
  },
  data () {
    return {
      openPaymentDialog: false,
      activePayType: '',
      maxPoliciesAmount: 1
    }
  },
  computed: {
    ...mapGetters('policies', ['policyById', 'policiesListByType']),
    ...mapState('payments', ['policiesForPay']),
    ...mapGetters('payments', ['extendedPolicyPaymentState']),
    activePolicies () {
      if (!this.policiesListByType) return []
      return this.policiesListByType.active
    },
    computePoliciesByType () {
      let policies = this.activePolicies

      let totalAmountOverdue = 0 // stores only overDue
      let totalAmountDue = 0 // stores only due

      let earliestPaymentDueDate = null

      let overduePolicies = new Set()
      let duePolicies = new Set()

      for (let index in policies) {
        let policy = policies[index]
        let payState = this.extendedPolicyPaymentState(policy.id)

        if (!payState) return null

        let newValues = payState.newSimpleValues
        if (newValues.hasDue) {
          totalAmountDue += newValues.dueValue
          earliestPaymentDueDate = getEarliestDate(earliestPaymentDueDate, newValues.dueDate)
          duePolicies.add(policy.id)
        }
        if (newValues.hasOverDue) {
          totalAmountOverdue += newValues.overDueValue
          overduePolicies.add(policy.id)
        }
      }

      let convertIdsSetToPolicyList = (idsSet) => {
        return [...idsSet].map((id) => this.policyById(id))
      }

      overduePolicies = convertIdsSetToPolicyList(overduePolicies)
      duePolicies = convertIdsSetToPolicyList(duePolicies)

      return {
        overduePolicies,
        duePolicies,
        totalAmountOverdue,
        totalAmountDue,
        earliestPaymentDueDate,
      }
    },
    numPoliciesThatAllowPayments () {
      let policyCount = 0
      for (let index in this.activePolicies) {
        let policy = this.activePolicies[index]
        if (isPaymentAllowed(policy)) {
          policyCount++
        }
      }
      return policyCount
    },
    paymentCard () {
      if (!this.computePoliciesByType) {
        return {
          headline: '',
          headlineClass: '',
          actionText: '',
          link: '',
          description: ''
        }
      }

      let {
        overduePolicies,
        duePolicies,
        totalAmountOverdue,
        totalAmountDue,
        earliestPaymentDueDate
      } = this.computePoliciesByType
      let headline = null
      let headlineClass = null
      let headline2 = null
      let actionText = ''
      let link = ''
      let description = ''
      // Overdue
      if (overduePolicies.length) {
        headline = this.t('payments.amount_overdue_title', {
          amount: this.asCurrency(totalAmountOverdue)
        })
        headlineClass = 'u-danger'
        actionText = this.t('payments.make_payment')
        link = '/payments'
      }
      if (duePolicies.length) {
        let dueHeadline
        if (earliestPaymentDueDate) {
          dueHeadline = this.t('payments.amount_due_by_date', {
            amount: this.asCurrency(totalAmountDue),
            date: this.dateLong(earliestPaymentDueDate),
          })
        } else {
          dueHeadline = this.t('dashboard.amount_due', {
            amount: this.asCurrency(totalAmountDue),
          })
        }
        if (!headline) {
          headline = dueHeadline
        } else {
          headline2 = dueHeadline
        }
      }

      if (duePolicies.length || overduePolicies.length) {
        // has something to pay
        actionText = this.t('payments.make_payment')
        link = '/payments'
      } else {
        headline = this.t('global.nothing_due')
        headlineClass = ''
        actionText = this.t('payments.payment_plans')
        link = '/payments/payment-plans'
        description = this.t('payments.you_may_have_future_payments_due')
      }
      return {
        headline,
        headlineClass,
        headline2,
        actionText,
        link,
        description
      }
    },
    useExternalPaymentSite () {
      return this.setting('payments.use_external_payment_site')
    }
  },
  methods: {
    ...mapMutations('payments', ['setCheckedPolicies']),
    openExternalSite () {
      window.open(this.setting('payments.url_to_external_payment_site'))
    },
    policiesForSinglePayment () {
      if (this.computePoliciesByType) {
        const { duePolicies, overduePolicies } = this.computePoliciesByType
        if (duePolicies.length === this.maxPoliciesAmount && !overduePolicies.length) {
          return this.setCheckedPolicies(duePolicies)
        } else if (overduePolicies.length === this.maxPoliciesAmount && !duePolicies.length) {
          return this.setCheckedPolicies(overduePolicies)
        }
      }
      return this.setCheckedPolicies([])
    },
    handleMakePaymentsDialog (val) {
      this.openPaymentDialog = val
    },
    processPayments () {
      if (this.policiesForPay.length === this.maxPoliciesAmount) {
        let firstPolicy = this.policiesForPay[0]
        let paymentState = this.extendedPolicyPaymentState(firstPolicy.id)
        this.activePayType = paymentState.dueValue ? 'currentDue' : 'nextTerm'
        this.handleMakePaymentsDialog(true)
      } else {
        this.$router.push(this.paymentCard.link)
      }
    }
  }
}
</script>

<style lang="css" scoped>
  .headline2 {
    padding-left: 25px;
  }
</style>
