<template>
  <div class="ba-intl-tel-input__wrapper" :class="disabled ? 'is-disabled' : ''">
    <div>
      <input :disabled="disabled" class="native-phone-input" :class="customClass"  type="tel" ref="telephoneInput"
             @blur="beforeInputLosesFocus">
    </div>
  </div>
</template>

<script>
import intlTelInput from 'intl-tel-input'
import utils from 'intl-tel-input/build/js/utils'

export default {
  name: 'ba-intl-tel-input',
  props: {
    value: String,
    disabled: Boolean,
    customClass: String,
    customContainerClass: String,
  },
  watch: {
    value: {
      handler (value) {
        if (!this.iti) {
          // intl-tel-input is not yet initialized
          return
        }
        if (this.iti.getNumber() !== value) {
          // external change via watcher, setting number manually into input
          this.iti.setNumber(value)
        }
      },
      immediate: true
    },
  },
  data () {
    return {
      isValid: false,
      nativePluginDidFinishLoading: false
    }
  },
  mounted () {
    const input = this.getRawInput()
    // This is a native plugin, and here is a way to adapt it to the Vue change-tracking loop

    this.iti = intlTelInput(input, {
      preferredCountries: ['US', 'CA'],
      utilsScript: utils,
      formatOnDisplay: true,
      nationalMode: true,
      customContainer: this.$props.customContainerClass,
    })
    input.addEventListener('change', this.afterValueChange)
    input.addEventListener('keyup', this.afterValueChange)
    input.addEventListener('countrychange', this.afterValueChange)
    this.iti.promise.then(() => {
      this.initialized()
    })
  },
  beforeDestroy () {
    if (this.iti && this.iti.destroy) {
      this.unsubscribeFromNativeEvents()
      this.iti.destroy()
    }
  },
  methods: {
    afterValueChange () {
      if (this.disabled) {
        // revert any changes
        this.$emit('input', this.value)
        return
      }
      let iti = this.iti

      let previousValidity = this.isValid
      this.isValid = iti.isValidNumber()
      this.$emit('input', iti.getNumber())
      if (!previousValidity && this.isValid) {
        // When phone number become valid, trigger setNumber to automatically format input
        iti.setNumber(iti.getNumber())
      }
    },
    getRawInput: function () {
      return this.$refs.telephoneInput
    },
    initialized () {
      this.nativePluginDidFinishLoading = true

      this.beforeInputLosesFocus()
    },
    beforeInputLosesFocus () {
      let iti = this.iti
      iti.setNumber(this.value)
      this.isValid = iti.isValidNumber()
      if (this.isValid) {
        iti.setNumber(this.value)
      }
    },
    unsubscribeFromNativeEvents () {
      let input = this.getRawInput()
      input.removeEventListener('change', this.afterValueChange)
      input.removeEventListener('keyup', this.afterValueChange)
      input.removeEventListener('countrychange', this.afterValueChange)
    },
  },
}
</script>

<style lang="scss">

.ba-intl-tel-input__wrapper .iti--allow-dropdown input[type=tel] {
  /*this style is duplication of .iti--allow-dropdown input[type=tel]. This way we fix overlapping styles issue*/
    padding-right: 6px!important;
    padding-left: 52px!important;
    margin-left: 0!important;
  }

.ba-intl-tel-input__wrapper.is-disabled {
  .iti--allow-dropdown .iti__flag-container,
  .iti--allow-dropdown,
  .iti {
    cursor: not-allowed !important;
  }

  .iti--allow-dropdown .iti__flag-container:hover {
    cursor: not-allowed;
    background-color: unset;
  }

  .iti__flag-container:hover .iti__selected-flag {
    background-color: unset;
    cursor: not-allowed !important;
  }

  .native-phone-input {
    cursor: not-allowed !important;
  }
}

</style>

