<template>
   <div>
     <div v-if="impossibleToCreatePaymentMethod">
       <h1 class="u-color-text" v-html="t('payments.unable_create_pm_no_billing_address')"></h1>
     </div>
     <div v-else v-loading="loading">
        <div class="u-mb4">
          <el-radio v-for="tab in availableTabs" v-model="selectedTab" :label="tab.id" :key="tab.id">{{tab.title}}</el-radio>
        </div>

         <h4 v-if="availableTabs.length === 0" class="media__title u-warning">
             <i class="material-icons md-text" aria-hidden="true">warning</i>
             <span>You cannot select a payment method here.</span>
         </h4>

        <el-form v-if="availableTabs.length && insuredContactData">
          <bc-bank-account-details v-if="selectedTab === 'ach'" ref="pmDetails"/>
          <bc-credit-card-details v-if="selectedTab === 'card'" ref="pmDetails"/>
          <billing-address v-if="askBillingAddress" :addresses="insuredContactData.addresses" class="u-mt3" ref="billingAddress"></billing-address>
          <div class="u-mt3">
            <el-button  type="primary" @click="saveButtonClick()">{{ t('payments.save_payment_method') }}</el-button>
          </div>
        </el-form>

      </div>
   </div>

</template>

<script>

import BankAccountDetails from './bank-account-details.component.vue'
import CreditCardDetails from './credit-card-details.component.vue'

import BillingAddress from './billing-address.component.vue'

import {mapActions, mapState} from 'vuex'

export default {
  name: 'r-ba-add-payment-method',
  props: ['insuredId'],
  data () {
    return {
      selectedTab: 'ach',
      loading: false
    }
  },
  computed: {
    ...mapState('common', ['dialogPayData']),
    ...mapState('common', ['contacts']),
    ...mapState('account', ['accountData']),
    insuredContactData () {
      let insured = this.accountData.insureds[this.insuredId]
      if (!insured) return
      return this.contacts.find(contact => contact.id === insured.contact_id)
    },
    impossibleToCreatePaymentMethod () {
      return !this.hasBillingAddress && !this.askBillingAddress
    },
    askBillingAddress () {
      return this.setting('payments.allow_add_billing_address') === true
    },
    hasBillingAddress () {
      return this.insuredContactData ? this.insuredContactData.hasBillingAddress : null
    },
    autoPayCreditCardEnabled () {
      let autoPayMethodSetting = this.setting('payments.autopay_payment_methods')
      return autoPayMethodSetting === 'ap_credit_cards' || autoPayMethodSetting === 'ap_ba_and_cc'
    },
    autoPayBankAccountEnabled () {
      let autoPayMethodSetting = this.setting('payments.autopay_payment_methods')
      return autoPayMethodSetting === 'ap_bank_accounts' || autoPayMethodSetting === 'ap_ba_and_cc'
    },
    creditCardEnabled () {
      let payMethodSetting = this.setting('payments.available_payment_methods')
      return payMethodSetting === 'credit_cards' || payMethodSetting === 'ba_and_cc'
    },
    bankAccountEnabled () {
      let payMethodSetting = this.setting('payments.available_payment_methods')
      return payMethodSetting === 'bank_accounts' || payMethodSetting === 'ba_and_cc'
    },
    availableTabs () {
      let tabs = []
      const autoPayActivated = this.dialogPayData.context ? this.dialogPayData.context.fromAutopay : false

      if (this.bankAccountEnabled && (!autoPayActivated || (autoPayActivated && this.autoPayBankAccountEnabled))) {
        tabs.push({
          id: 'ach',
          title: this.t('payments.bank_account'),
        })
      }
      if (this.creditCardEnabled && (!autoPayActivated || (autoPayActivated && this.autoPayCreditCardEnabled))) {
        tabs.push({
          id: 'card',
          title: this.t('payments.credit_card'),
        })
      }
      if (tabs.length === 1) {
        this.selectTab(tabs[0].id)
      }
      return tabs
    },
  },
  methods: {
    ...mapActions('payments', ['addPaymentMethod']),
    ...mapActions('common', ['loadContact']),
    clearFields () {
      try {
        if (this.askBillingAddress) this.$refs.billingAddress.clearFields()
      } catch (err) {
        console.error(err)
      }

      try {
        this.$refs.pmDetails.clearFields()
      } catch (err) {
        console.error(err)
      }
    },
    getAvailableTabsLength () {
      return this.availableTabs.length
    },
    buttonType (id) {
      return this.selectedTab === id ? 'primary' : 'default'
    },
    selectTab (id) {
      this.selectedTab = id
    },
    saveButtonClick () {
      if (this.impossibleToCreatePaymentMethod) {
        this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.notice'))
        return
      }

      let paymentMethodData = this.$refs.pmDetails.getPaymentMethodData()

      let billingAddressData
      if (this.askBillingAddress) {
        // if 'payments.allow_add_billing_address' enabled - user should provide address explicitly
        billingAddressData = this.$refs.billingAddress.getBillingAddressData()
      }

      if (!paymentMethodData || ((!this.hasBillingAddress || this.askBillingAddress) && !billingAddressData)) {
        // if no valid billingAddress or no valid PM data - throw error
        this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.notice'))
        return
      }

      this.post(this.selectedTab, paymentMethodData, billingAddressData)
    },
    async post (type, data, billingAddressData) {
      let payload = this.getEmptyPayload()
      this.loading = true
      if (type === 'card') {
        let card = data
        payload.card_cvv2 = card.cvv2
        payload.card_expires_mm = card.expires_mm
        payload.card_expires_yy = card.expires_yy
        payload.card_name_on = card.name_on
        payload.card_number = card.number
        payload.type = 'CC'
      } else if (type === 'ach') {
        let ach = data
        payload.ach_type = ach.type
        payload.ach_name_on = ach.name_on
        payload.ach_bank = ach.bank
        payload.ach_account = ach.account
        payload.ach_routing = ach.routing
        payload.type = 'ACH'
      }

      payload.insured_id = this.insuredId
      if (billingAddressData) {
        payload.billing_address = billingAddressData
      }

      this.loading = true
      try {
        await this.addPaymentMethod(payload)
        this.loading = false
        this.$emit('paymentMethodAdded')
      } catch (result) {
        this.loading = false
        if (!!result.data.action_result && result.data.action_result !== 'unknown') {
          this.$elAlertHTML(this.t('payments.action_result_' + result.data.action_result), this.t('global.error'))
        } else {
          this.$elAlertHTML(
            this.t('payments.error_occurred_while_adding_pm') + ': ' + result.data.message,
            this.t('global.error')
          )
        }
      }
    },

    getEmptyPayload: function () {
      return {
        type: 'ACH',
        card_expires_mm: '0',
        card_expires_yy: '0',
        card_name_on: '',
        card_number: '',
        card_cvv2: '',
        ach_type: '0',
        ach_name_on: '',
        ach_bank: '',
        ach_account: '',
        ach_routing: '',
      }
    },
  },
  components: {
    'bc-bank-account-details': BankAccountDetails,
    'bc-credit-card-details': CreditCardDetails,
    'billing-address': BillingAddress,
  },
}
</script>
