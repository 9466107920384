import parsePhoneNumber from 'libphonenumber-js'

export function deepClone (obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function b64toBlob (b64Data, contentType, sliceSize) {
  contentType = contentType || ''
  sliceSize = sliceSize || 512

  let byteCharacters = atob(b64Data)
  let byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize)

    let byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    let byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, {type: contentType})
}

export function isEmailValid (email) {
  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
  return emailRegex.test(email)
}

export function isMfaCodeValid (mfaCode) {
  let mfaRegex = /^[0-9]{6}$/
  return mfaRegex.test(mfaCode)
}

export function isPinValid (pin) {
  let pinRegex = /^\d{4}$/
  return pinRegex.test(pin)
}

export function convertToUSFormatPhoneNumber (num) {
  let cleaned = ('' + num).replace(/\D/g, '')
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    // Phone number format '(123) 456-7890'
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return num
}

export function parsePhoneNationalFormat (num) {
  if (!num) return null
  let formattedNumber = parsePhoneNumber(num)
  return formattedNumber ? formattedNumber.formatNational() : num
}

export async function axiosWrapper (promise) {
  let response
  try {
    response = await promise
  } catch (exc) {
    if (exc.response === undefined) {
      throw new RequestError({cause: 'global.connection_error'})  // no response at all
    }
    if ((exc.response.data === null) || (typeof exc.response.data !== 'object')) {
      throw new RequestError({cause: 'global.generic_server_error'})  // error & non-json response
    }
    throw new RequestError(exc.response.data)
  }
  let data = response.data
  if ((data === null) || (typeof data !== 'object')) {
    throw new RequestError({cause: 'global.generic_server_error'})  // non-json response
  }
  if (data.success !== true) {
    throw new RequestError(data)
  }
  return data
}

function RequestError (response, message) {
  message = message || 'Request error'
  const error = new Error(message)
  error.response = response
  return error
}

RequestError.prototype = Object.create(Error.prototype)

export {RequestError}

export function usDateToMomentJsDate (usDate) {
  if (!usDate) return
  // give Moment.js what it wants
  return usDate.substring(6, 11) + '-' + usDate.substring(0, 2) + '-' + usDate.substring(3, 5)
}

export async function sleep (ms) {
  return await new Promise((resolve, reject) => {
    setTimeout(resolve, ms)
  })
}

export function getEarliestDate (date1, date2) {
  if (!date1) return date2
  if (!date2) return date1
  return date2.isAfter(date1) ? date1 : date2
}

export function deepCompare (value1, value2) {
  if (typeof value1 !== typeof value2) return false
  if (value1 === undefined) return true // both values are undefined
  let isValue1Null = value1 === null
  let isValue2Null = value2 === null
  if (isValue1Null || isValue2Null) return isValue1Null && isValue2Null
  // array check
  let isValue1Array = Array.isArray(value1)
  let isValue2Array = Array.isArray(value2)
  if (isValue1Array !== isValue2Array) return false
  if (isValue1Array) {
    // 2 arrays comparison
    if (value1.length !== value2.length) return false
    for (let i = 0; i < value1.length; i++) {
      if (!deepCompare(value1[i], value2[i])) return false
    }
    return true
  }
  // dict check
  let isVaule1Dict = value1.constructor === Object
  let isValue2Dict = value2.constructor === Object
  if (isVaule1Dict !== isValue2Dict) return false
  if (isVaule1Dict) {
    for (let key in value1) {
      if (!value1.hasOwnProperty(key)) continue
      if (!value2.hasOwnProperty(key)) return false
      if (!deepCompare(value1[key], value2[key])) return false
    }
    for (let key in value2) {
      if (!value2.hasOwnProperty(key)) continue
      if (!value1.hasOwnProperty(key)) return false
      if (!deepCompare(value1[key], value2[key])) return false
    }
    return true
  }
  // if we reached here - do regular comparison
  return value1 === value2
}

export function getPlainPhoneNumber (phoneNumber) {
  // removes non-digits
  return phoneNumber.replace(/[^0-9]/g, '')
}

export function formCleanAddress (addr) {
  let addrChunks = [
    addr.address_line1,
    addr.address_line2,
    addr.address_city,
    addr.address_state,
    addr.address_zip]
  addrChunks = addrChunks.filter(chunk => (chunk !== null && chunk.trim().length))
  addrChunks = addrChunks.map(chunk => chunk.trim())
  let result = addrChunks.join(', ')
  return result || null // return null on empty string
}

export function isEmailInvalidWithCause (email) {
  // it will return slugs, which can be converted to content
  if (!email || (email.trim() === '')) {
    return 'account.email_should_not_be_empty'
  }
  if (email !== email.trim()) {
    return 'enrollment.no_lead_trail_space_in_email'
  }
  if (!isEmailValid(email)) {
    return 'account.email_is_incorrect'
  }
}
