import axios from 'axios'
import config from '../../config/britecore'
import qs from 'qs'
import {deepClone} from '@shared_src/utils/misc.util'

const commonModule = {
  namespaced: true,
  state: {
    linkedPolicies: null,
    linkedContact: null,
    authUsername: null,
    authPassword: null,
    authPin: null,
    edelivery: false,
    originalEmail: null,
    suggestedUsername: null,
    tos: null,
    enrollmentToken: null, // used to secure sign-up endpoint and avoid fraud
    confirmedTos: false,
    previousView: '',
    savedData: {
      view: '',
      type: '',
      policyNumber: '',
      contactId: '',
      insureds: [],
    },
    contactDataToAttach: null,
    fileIdToViewAfterEnroll: null,
    extradata: null,
  },
  mutations: {
    saveFilledData (state, payload) {
      state.savedData = Object.assign({}, state.savedData, payload)
    },
    savePreviousView (state, payload) {
      state.previousView = payload
    },
    setLinkedPolicies (state, payload) {
      state.linkedPolicies = payload
    },
    confirmTos (state, payload) {
      state.confirmedTos = payload
    },
    setLinkedContact (state, payload) {
      state.linkedContact = deepClone(payload)
    },
    setTos (state, payload) {
      state.tos = payload
    },
    setExtraData (state, payload) {
      state.extradata = payload
    },
    processSuccessfullEnrollmentRecognization (state, payload) {
      let contact = payload.contact
      let policies = payload.policies || []
      let suggestedUsername = payload.suggestedUsername
      if (payload.extra) {
        state.extradata = payload.extra
      }
      policies.forEach((policy) => {
        policy['current_due'] = Number(policy['current_due'])
      })
      state.linkedPolicies = policies

      let filteredAddresses = []
      if (contact.addresses) {
        filteredAddresses = contact.addresses.filter(data => data.type !== 'Other')
        let hasAddressMailingBillingType = filteredAddresses.some(data => data.type === 'Mailing/Billing')
        if (hasAddressMailingBillingType) {
          filteredAddresses = filteredAddresses.filter(data => data.type === 'Mailing/Billing')
        } else {
          filteredAddresses = filteredAddresses.filter(data => data.type === 'Mailing' || data.type === 'Billing')
        }
      }

      state.linkedContact = { ...contact, addresses: filteredAddresses }

      state.suggestedUsername = suggestedUsername
      state.enrollmentToken = payload.enrollmentToken
      state.tos = payload.tos
      if (contact.emails && contact.emails.length > 0) {
        state.originalEmail = contact.emails[0].email
      }
    },
    setContactDataToAttach (state, payload) {
      state.contactDataToAttach = deepClone(payload)
    },
  },
  actions: {
    getInsuredDetailsFromPolicyNumber: function (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(config.get_insured_details_from_policy_number_url, qs.stringify(payload)).then((result) => {
          resolve(result)
        }).catch((error) => {
          console.error('get_insured_details_from_policy_number failed', error)
          reject(error)
        })
      })
    },
    async addMissingPolicy (context, payload) {
      try {
        let result = await axios.post(config.add_missing_policy, payload)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    findInsuredByProvidedData: function (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(config.find_user_for_enrollment, payload).then((result) => {
          resolve(result)
        }).catch((error) => {
          console.error('find_insured_by_provided_data failed', error)
          reject(error)
        })
      })
    },
    answerChallengeAndFinishEnrollment: function (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(config.advance_enrollment_answering_challenge_url, qs.stringify(payload))
          .then((result) => {
            if (result.data.success) {
              let responseData = result.data.data
              context.commit('processSuccessfullEnrollmentRecognization', responseData)
            } else {
              context.commit('setLinkedPolicies', null)
              context.commit('setLinkedContact', null)
            }
            resolve(result)
          }).catch((error) => {
            console.error('advance_enrollment_answering_challenge failed', error)
            reject(error)
          })
      })
    },
    answerChallengeAndAttachContact: function (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(config.advance_answering_challenge_and_attach_contact_to_user, qs.stringify(payload))
          .then((result) => {
            if (result.data.success) {
              let responseData = result.data.data
              context.commit('setContactDataToAttach', responseData)
            } else {
              context.commit('setContactDataToAttach', null)
            }
            resolve(result)
          }).catch((error) => {
            console.error('advance_enrollment_answering_challenge failed', error)
            reject(error)
          })
      })
    },
    sendContactChallenge: function (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(config.send_contact_challenge_url, qs.stringify(payload))
          .then((result) => {
            resolve(result)
          }).catch((error) => {
            console.error('send_contact_challenge failed', error)
            reject(error)
          })
      })
    },
    approveTos: function (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(config.approve_tos_url, {token: localStorage.token, ...payload})
          .then((result) => {
            resolve(result)
          }).catch((error) => {
            console.error('approve_tos failed', error)
            reject(error)
          })
      })
    },
    isInsuredEnrolled: function (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(config.is_insured_enrolled_url, payload).then((result) => {
          resolve(result)
        }).catch((error) => {
          console.error('is_insured_enrolled failed', error)
          reject(error)
        })
      })
    },
  },
  getters: {
    linkedPolicies: state => {
      return state.linkedPolicies
    },
    linkedContact: state => {
      return deepClone(state.linkedContact)
      // return state.linkedContact
    },
    authUsername: state => {
      return state.authUsername
    },
    authPassword: state => {
      return state.authPassword
    },
    authPin: state => {
      return state.authPin
    },
    originalEmail: state => {
      return state.originalEmail
    },
    enrollmentToken: state => {
      return state.enrollmentToken
    },
    contactDataToAttach: state => {
      return state.contactDataToAttach
    }
  }
}

export default commonModule
