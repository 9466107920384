import axios from 'axios'
import store from '../store'
import {setCookie} from '../../shared_src/utils/api.utils'
import config from '@/../config/britecore'

axios.interceptors.request.use(
  function (config) {
    let version = store.getters['common/version']
    if (config.url.indexOf('ident.me') >= 0) {
      return config
    }
    if (version) {
      try {
        if (!version.startsWith('web_')) {
          version = 'web_' + version
        }
      } catch (err) {
        console.warn(err)
      }
      config.headers['X-UI-Version'] = JSON.stringify(version.replace(/\n/g, ''))
    }
    return config
  },
  function (error) {
    console.warn('axios.interceptors.request.use error', error)
    // Do something with request error
    return Promise.reject(error)
  })

axios.interceptors.response.use(
  function (response) {
    if (response.data.maintenance) {
      // this company is under maintenance. Redirect user to maintenance page
      let currentPath = window.__vue_router.currentRoute.path
      if (currentPath !== '/maintenance') window.__vue_router.replace('/maintenance')
      return
    }
    if (response.data.user_blocked) {
      // this user is blocked. Redirect user to "account-blocked" page
      let currentPath = window.__vue_router.currentRoute.path
      if (currentPath !== '/account-blocked') window.__vue_router.replace('/account-blocked')
      return
    }

    let token = response.data.token
    if (token) {
      // if we have token in response - update token we have in storage
      let url = response.request.responseURL
      let isLoginResponse = [config.ajax_login_url, config.auth_by_pin_url].includes(url)
      let isElapsedEnoughTimeAfterLogin
      try {
        isElapsedEnoughTimeAfterLogin = Boolean(
          localStorage.loginTime && ((Date.now() - localStorage.loginTime) > 180000))
      } catch (err) {
        isElapsedEnoughTimeAfterLogin = false
      }

      if (isLoginResponse || (localStorage.token && isElapsedEnoughTimeAfterLogin)) {
        // idea is - if we removed localStorage.token - only login urls response can "restore" it.
        // New token can be written over login token only 180 seconds later. This will prevent scenarios
        // when you open website - new calls with old token are launched (and last for a minute for example) but you made new login -
        // and new token for new user was overridden by old call response for old user
        // Login call should rewrite token without any restrictions (even if old session was not ended)
        store.commit('login/updateToken', token)
        setCookie('user_online', store.getters['common/contactName'], store.getters['common/sessionExpiration'])
        if (isLoginResponse) {
          localStorage.loginTime = Date.now()
        }
      }
    }
    return response
  },
  function (error) {
    if (!error.response || error.response.status !== 401) {
      // if it is regular error - rethrow error
      return Promise.reject(error)
    }
    // from here is only 401 cases

    let currentPath = window.__vue_router.currentRoute.path
    store.commit('login/updateToken', null)
    localStorage.setItem('login__token-expired', true)
    if (
      (currentPath === '/login') || // login page
      (currentPath === '/enrollment_initiation') || // enrollment-initiation
      (currentPath !== null && currentPath.indexOf('/enrollment/') === 0) // enrollment - no need to raise error
    ) {
      return Promise.reject(error)
    }

    localStorage.setItem('last_location', window.__vue_router.currentRoute.path)
    if (!window.non_autorized_request_ocurred) {
      window.non_autorized_request_ocurred = true
      window.__vue_router.replace('/login?redir=1')

      window.non_autorized_request_ocurred = undefined
    }
    return new Promise((resolve, reject) => {})
  }
)
