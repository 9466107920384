<template>
  <div class="pages">
    <div class="centered" v-loading="loading">
        <div class="u-text-center">{{ t('enrollment.initiate_enrollment_proceed_text') }}</div>
        <div class="wrapper">
          <el-button type="info" class="u-text-center" @click="process">
            {{ t('enrollment.initiate_enrollment_proceed_button') }}
          </el-button>
        </div>
    </div>
  </div>
</template>

<script>
  import {mapMutations} from 'vuex'
  import axios from 'axios'
  import settings from '@/../config/settings'
  import config from '@/../config/britecore'

  export default {
    name: 'enrollment-initiation',
    created () {
      this.removeTokenAndLoginTime()
    },
    mounted () {
      this.process()
    },
    methods: {
      ...mapMutations('login', ['removeTokenAndLoginTime']),
      ...mapMutations('enrollment', [
        'processSuccessfullEnrollmentRecognization', 'setLinkedPolicies', 'setLinkedContact', 'setExtraData']),
      clearEnrollmentData () {
        this.removeTokenAndLoginTime()
        this.setLinkedPolicies(null)
        this.setLinkedContact(null)
        this.setExtraData(null)
      },
      async process () {
        this.clearEnrollmentData()
        if (this.$route.query && this.$route.query.token) this.token = this.$route.query.token
        if (!this.token) {
          this.$elAlert(this.t('enrollment.some_error_happened_notification'), this.t('global.error'))
          return
        }
        this.loading = true
        let response = await axios.post(config.validate_initiate_enrollment_token, {
          token: this.token,
          company_id: settings.company_id
        })
        this.loading = false
        if (response.data.success) {
          let responseData = response.data.data
          this.loading = true
          this.processSuccessfullEnrollmentRecognization(responseData)
          this.$router.push('/enrollment/setup-account-step')
        } else {
          this.clearEnrollmentData()
          this.$elAlert(response.data.message, this.t('global.error'), () => {
            if (response.data.cause === 'error_code.insured_already_registered') {
              this.$router.push('/login')
            }
          })
        }
      }
    },
    data () {
      return {
        token: null,
        loading: false
      }
    },
    computed: {
    }
  }
</script>
<style scoped>
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .wrapper {
    display: flex;
    justify-content: center;
  }
</style>
