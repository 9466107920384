module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [(!_vm.setting('policies.read_only_property_photos')) ? _c('drag-n-drop-photo', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    on: {
      "onPhotoUpload": _vm.onPhotoUpload
    }
  }) : _c('div', {
    staticClass: "u-flex-vertical-center upload-forbidden"
  }, [_c('div', {
    staticClass: "u-text-center"
  }, [_vm._v(_vm._s(_vm.t('policies.property_photo_upload_is_not_allowed')))])])], 1)
},staticRenderFns: []}