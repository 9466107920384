module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: (_vm.loading),
      expression: "loading"
    }],
    attrs: {
      "element-loading-spinner": "no"
    }
  }, [_c('div', {
    staticClass: "u-mt1 u-mb2"
  }, [_vm._v(_vm._s(_vm.t('enrollment.choose_email_page_text')))]), _vm._v(" "), _c('el-form', [_vm._l((_vm.availableEmails), function(email) {
    return _c('el-form-item', {
      key: email,
      staticClass: "u-mb3 u-max-width"
    }, [_c('el-radio', {
      staticClass: "el-radio--wide el-radio--main-bg",
      attrs: {
        "label": email,
        "border": ""
      },
      model: {
        value: (_vm.internalValue),
        callback: function($$v) {
          _vm.internalValue = $$v
        },
        expression: "internalValue"
      }
    }, [_vm._v(_vm._s(email))])], 1)
  }), _vm._v(" "), _c('el-form-item', {
    staticClass: "custom-email u-mb3 u-max-width"
  }, [_c('div', {
    staticClass: "pos-rel"
  }, [_c('el-input', {
    staticClass: "custom-email__input",
    attrs: {
      "type": "text",
      "placeholder": _vm.t('enrollment.add_new_email_placeholder')
    },
    nativeOn: {
      "click": function($event) {
        return _vm.willToggleOnRadioButton.apply(null, arguments)
      }
    },
    model: {
      value: (_vm.customEmail),
      callback: function($$v) {
        _vm.customEmail = $$v
      },
      expression: "customEmail"
    }
  }), _vm._v(" "), _c('el-radio', {
    staticClass: "custom-email__radio el-radio--main-bg",
    attrs: {
      "label": _vm.customEmail
    },
    model: {
      value: (_vm.internalValue),
      callback: function($$v) {
        _vm.internalValue = $$v
      },
      expression: "internalValue"
    }
  })], 1)])], 2)], 1)
},staticRenderFns: []}